import React, { useState } from 'react';
import {
  Grid, TextField, Box, Button, IconButton, Container, Snackbar, Paper
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';

const LayoutContainer = styled(Container)`
   position: absolute;
  display: flex;
  flex-direction: column;
  justify-content:start;
  max-width: 600px;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  adding: 16px;
  top: 12%;
  left:18%;
 
  backgroundColor:'#ccffff';
`;

const token = localStorage.getItem('token');
const API_URL = 'https://tecslkoupe.com';

const RRVoucher = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Form Validation Schema
  const validationSchema = Yup.object({
    Amount: Yup.number().typeError('Amount must be a number').required('Amount is required'),
    Remarks: Yup.string().required('Remarks are required'),
  });

  // Snackbar Close Handler
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Submit Handler
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios.post(
        `${API_URL}/Admin/Journal/RRVoucher`,
        {
          Action: 'SaveRRVoucher',
          Amount: values.Amount,
          Remarks: values.Remarks,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Check if response is successful
      if (response.data.status && response.data.code === 200) {
        setSnackbarMessage(response.data.message || 'Voucher saved successfully!');
        setSnackbarSeverity('success');
      } else {
        setSnackbarMessage(response.data.message || 'Failed to save voucher. Please try again.');
        setSnackbarSeverity('error');
      }
    } catch (error) {
      setSnackbarMessage(error.response?.data?.message || 'Failed to save voucher. Please try again.');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
      setSubmitting(false);
    }
  };

  return (
    <LayoutContainer>
      <Paper elevation={5} style={{ padding: '16px', marginBottom: '16px' }}>
        <Formik
          initialValues={{
            Amount: '',
            Remarks: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, values, isSubmitting }) => (
            <Form>
              <Grid container spacing={2}>

                {/* <Grid item xs={4}>
                  <Field
                    as={TextField}
                    fullWidth
                    id="voucherDate"
                    name="voucherDate"
                    label="Voucher Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={values.voucherDate}
                    error={touched.voucherDate && Boolean(errors.voucherDate)}
                    helperText={touched.voucherDate && errors.voucherDate}
                    size="small"
                  />
                </Grid> */}

                {/* Amount Field */}
                <Grid item xs={4}>
                  <Field
                    as={TextField}
                    fullWidth
                    id="Amount"
                    name="Amount"
                    label="Amount"
                    value={values.Amount}
                    error={touched.Amount && Boolean(errors.Amount)}
                    helperText={touched.Amount && errors.Amount}
                    size="small"
                  />
                </Grid>

                {/* Remarks Field */}
                <Grid item xs={4}>
                  <Field
                    as={TextField}
                    fullWidth
                    id="Remarks"
                    name="Remarks"
                    label="Remarks"
                    value={values.Remarks}
                    error={touched.Remarks && Boolean(errors.Remarks)}
                    helperText={touched.Remarks && errors.Remarks}
                    size="small"
                  />
                </Grid>
              </Grid>

              {/* Save Button */}
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  size='medium'
                  sx={{ marginTop: '16px' }}
                >
                  {isSubmitting ? 'Saving...' : 'Save'}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Paper>

      {/* Snackbar for Messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton size="small" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        ContentProps={{
          style: { backgroundColor: snackbarSeverity === 'success' ? '#4caf50' : '#f44336' },
        }}
      />
    </LayoutContainer>
  );
};

export default RRVoucher;





