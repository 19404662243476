import React from 'react';
import {
    TextField, Button, Typography, Container, Box, IconButton, CircularProgress, Checkbox
  , Paper, Dialog, DialogActions, Grid,DialogContent, DialogContentText, DialogTitle, Snackbar, FormControlLabel
  } from '@mui/material';
  import { Formik, Form, Field, ErrorMessage } from 'formik';
  import styled from '@emotion/styled';
import MemberDetailsCompo from './../MemberDetailsComp';
import * as Yup from 'yup';
import DIFSection from './../DIFSection';
import DIFLedger from './../DIFLedger';
import OtherDepositsTable from './OtherDepositsTable';
import RecoveryList from './RecoveryList';

const validationSchema = Yup.object().shape({
    memberId: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
    unit: Yup.string().required('Required'),
    subUnit: Yup.string().required('Required'),
    lastRecoveryList: Yup.string().required('Required'),
    lastUploadedList: Yup.string().required('Required'),
    OSamount: Yup.string().required('Required'),
    RefundAmount: Yup.string().required('Required'),
    TransactionDate: Yup.string().required('Required'),
    Amount:Yup.string().required('Required'),
  });
  

const LayoutContainer = styled(Container)`  
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content:start;
  max-width: 600px;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  adding: 16px;
  top: 12%;
  left:18%;
  marginTop: theme.spacing(2);
  marginBottom: theme.spacing(2);
  
`;

const OtherDepositeComp = () => {

    const handleSubmit = (values)=>{
        console.log(values)
    }


  return (
    <LayoutContainer>
        <Formik
             initialValues={{
                memberId: '',
                name: '',
                unit: '',
                subUnit: '',
                lastRecoveryList: '',
                lastUploadedList: '',
                difOutstanding: '',
                difOpening: '',
                difBalance: '',
                RefundAmount:'',
                OSamount:'',
                transactionDate: '',
                transferTo: {
                    normalLoan: false,
                    normalIntt: false,
                    EmergencyLoan:false,
                    DIF:false,
                    ThriftFund:false,
                    Surety:false  
                },
                Amount:'',
              }}
            validationSchema={validationSchema}
            onSubmit={onsubmit}    
        
        >{
            ({values, handleChange, errors, touched})=>(
                <Form>
                     <Paper elevation={5} style={{ padding: '16px', borderRadius: '10px',marginBottom:'16px' }}>
                                <MemberDetailsCompo 
                                        values={values}
                                        handleChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                    />
                                
                                
                     </Paper>
                     <Paper elevation={5} style={{ padding: '16px', borderRadius: '10px', marginBottom:'16px' }}>

                     <RecoveryList 
                                        values={values}
                                        handleChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                    />
                     </Paper>
                     <Paper elevation={5} style={{ padding: '16px', borderRadius: '10px', marginBottom:'16px' }}>

                     <OtherDepositsTable 
                                        values={values}
                                        handleChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                    />
                     </Paper>
                </Form>           
            )
        }
        </Formik>
    </LayoutContainer>
  )
}

export default OtherDepositeComp
