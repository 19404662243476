import styled from '@emotion/styled';
import { Grid, Box, TextField,Select, MenuItem, FormControl,Button, Typography ,InputLabel,Container,Snackbar,CircularProgress ,Paper} from '@mui/material';

export const LayoutContainer = styled(Container)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: start;
//   max-width: 1600px;
  width: 100%;
//   min-height: 100vh;
//   height: 100vh;
  padding: 16px;
 top: 12%;
  left:18%;
//   background-color: #ccffff;
`;

export const DropDownContainer = styled(Grid)`
  display: flex;
  flex:1;
  justify-content:start;
  width:100%;
  margin:16px 8px;
 `
 
export const LoadingStyle = styled(Box)`
    display:flex ;
    justify-content:center; 
    align-items:center;
     height: 100vh;
`;

export const TableStyle = styled(Box)`
display:flex;
// height: 100vh;
flex-direction:column;
justify-content:start; 
margin-bottom:0px;
padding:8px; 
background-color:'black'
`
export const PageingStyle = styled(Box)`
    display:flex;
    flex-direction:row;
    justify-content:end;  
    align-items:center;
    margin:16px 0;
`