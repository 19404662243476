import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './auth/Login';
import Signup from './auth/SignUp';
import Home from './pages/Home';
import ForgotPassword from './auth/ForgotPassword';
import MainPage from './pages/MainPage';

import PrivateRoute from './routes/PrivateRoute';
import MemberDataGrid from './componets/home/OpenMember/MemberDataGrid';
import NotFound from './pages/NotFound';



const appStyle = {
  // backgroundColor: '#85F4FF', // Replace with your desired color
  minHeight: '200vh',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#E3F2FD', 
};


function App() {
  return (
    <div style={appStyle}>
        <Routes>
          <Route path="/login" element={<Login />}/>
          <Route path="/register" element={<Signup/>} />
          <Route path="/forgotPassword" element={<ForgotPassword/>}/>
          <Route path="/" exact element={<Login />}/>

          <Route path="/" element={<PrivateRoute />}>
              {/* <Route path="/home/*" element={<MainPage/>}/>
              <Route path="login/home/*" element={<MainPage/>}/>  */}

              <Route path="/home/*" element={<Home/>}/>
              <Route path="login/home/*" element={<Home/>}/>  
              <Route path="*" element={<NotFound />} />
          </Route>
        </Routes> 
    </div>
  );
}

export default App;
