import React, { useEffect, useState, useCallback } from 'react';
import { Grid, Box, TextField,Button, Typography ,IconButton,Snackbar,CircularProgress ,Paper, RadioGroup, FormControlLabel} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import ReusableTable from './../../reuseable/ReusableTable';
import { LayoutContainer, LoadingStyle,TableStyle, PageingStyle } from '../../styles/globalStyle';
import PaymentDetailsSection from '../payments/PaymentDetailsSection';
import PrintIcon from '@mui/icons-material/Print';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import Radio from '@mui/material/Radio';
import Pagenation from '../../reuseable/pagenation';
import SelectDropdown from '../../reuseable/CustomDropDown';
import * as XLSX from 'xlsx';

const DividentLedger = [
    { field: 'RowNumber', headerName: 'Row No', width: '150px' },
    { field: 'TDate', headerName: 'Tran Date', width: '150px' },
    { field: 'Remark', headerName: 'Remark', width: '150px' },
    { field: 'MemberID', headerName: 'MemberID', width: '200px' },
    { field: 'Name', headerName: 'Name', width: '200px' },
    { field: 'Dividend Due', headerName: 'Dividend Due', width: '150px' },
    { field: 'TF Int Due', headerName: 'TF Int Due', width: '150px' },
    { field: 'Div Pmt', headerName: 'Div Payment', width: '150px' },
    { field: 'Div Rec', headerName: 'Divident Receipt', width: '150px' },
    { field: 'TF Int Pmt', headerName: 'TF Interest Payment', width: '150px' },
    { field: 'TF Int Rec', headerName: 'TF Interest Receipt', width: '200px' },
  ];

  const CashBookReport = [
    { field: 'RowNumber', headerName: 'Row No', width: '150px' },
    { field: 'MemberID', headerName: 'MemberID', width: '200px' },
    { field: 'Cash', headerName: 'Cash', width: '150px' },
    { field: 'Cheque', headerName: 'Cheque', width: '150px' },
    { field: 'CA', headerName: 'CA', width: '200px' },
    { field: 'MemberID1', headerName: 'MemberID1', width: '150px' },
    { field: 'Name', headerName: 'Name', width: '200px' },
    { field: 'Cash1', headerName: 'Cash', width: '150px' },
    { field: 'Cheque1', headerName: 'Cheque', width: '150px' },
    { field: 'Dividend', headerName: 'Dividend', width: '150px' },
    { field: 'TFInterest', headerName: 'TF Interest', width: '150px' },    
  ];

const token = localStorage.getItem('token')
const API_URL ='https://tecslkoupe.com';

const initialValues = {
    reportType: ['GetDividendLedger', 'GetIndividualDividendLedger', 'GetCashBookReport'],
    FromDate: '',
    ToDate: '', 
    MemberId:'',
    SelectChequeDate:''
}

const validationSchema = Yup.object().shape({
    FromDate: Yup.date().required('Required'),
    ToDate: Yup.date().required('Required'),
    MemberID: Yup.number().when('reportType', {
      is: 'GetIndividualDividendLedger',
      then: Yup.number().required('Member ID is required for Individual Dividend Ledger'),
    }),
    SelectChequeDate:Yup.date().when('reportType', {
      is: 'GetCashBookReport',
      then: Yup.number().required('Member ID is required for Individual Dividend Ledger'),
    }),
})


const DividentReports = () => {
    const [dailyExpend, setDailyExpend] = useState([])
    const [actionType, setActionType] = useState('')

    const [memberList, setMemberList] = useState([]);
    const [selectMemberId, setSelectMemberId] = useState('');

    const [selectedDate, setSelectedDate] = useState('');
    const [SelectDate, setSelectD] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
   
    const [membersId, setMembersId] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);

    const [pageSize] = useState(10); 

    // const fetchData = async (values, page = 1) => {
    //   try {
    //     setLoading(true);
    //     const response = await axios.post(
    //       `${API_URL}/Admin/Report/DividendReports`,
    //       {
    //         Action: actionType,
    //         FromDate: values.FromDate,
    //         ToDate: values.ToDate,
    //         PageIndex: page,
    //         pageSize,
    //       },
    //       {
    //         headers: {
    //           Authorization: `Bearer ${token}`,
    //           'Content-Type': 'application/json',
    //         },
    //       }
    //     );
    //     setDailyExpend(response.data.List);
    //     setTotalRecords(response.data.TotalCount || 0);
    //   } catch (error) {
    //     setSnackbarMessage('Failed to fetch data. Please try again.');
    //     setSnackbarOpen(true);
    //   } finally {
    //     setLoading(false);
    //   }
    // };


    const fetchData = async (values ) => {
      try {
        setLoading(true);
        const payload = {
          Action: actionType,
          FromDate: values.FromDate,
          ToDate: values.ToDate,
          PageIndex: currentPage,
          pageSize,
        };
  
        // Include MemberID only for Individual Dividend Ledger action
        if (actionType === 'GetIndividualDividendLedger') {
          payload.MemberID = values.MemberID;
        }

        if (actionType === 'GetCashBookReport') {
          payload.SelectChequeDate = values.SelectChequeDate;
        }
  


        const response = await axios.post(`${API_URL}/Admin/Report/DividendReports`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.data) {
          setDailyExpend(response.data.List || []);
          setTotalRecords(response.data.Footer[0]?.TotalRecord || 0);
        } else {
          throw new Error('No data in response');
        }
      } catch (error) {
        setSnackbarMessage('Failed to fetch data. Please try again.');
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };


    useEffect(()=>{
        if(actionType) fetchData(initialValues)
    },[currentPage,actionType])

    const exportToExcel = () => {
      const dataToExport = dailyExpend.map(item => ({
        RowNumber: item.RowNumber,
        TDate: item.TDate,
        Remark: item.Remark,
        MemberID: item.MemberID,
        Name: item.Name,
        DividendDue: item['Dividend Due'],
        TFIntDue: item['TF Int Due'],
        DivPmt: item['Div Pmt'],
        DivRec: item['Div Rec'],
        TFIntPmt: item['TF Int Pmt'],
        TFIntRec: item['TF Int Rec'],
      }));
  
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Report Data');
      XLSX.writeFile(workbook, 'ReportData.xlsx');
    };


    // const exportToExcel = () => {
    //   const columns = actionType === 'GetDividendLedger' || actionType === 'GetIndividualDividendLedger'
    //     ? DividentLedgerColumns
    //     : CashBookReportColumns;
    //   const exportData = data.map(item => {
    //     return columns.reduce((obj, col) => ({ ...obj, [col.headerName]: item[col.field] }), {});
    //   });
    //   // Implement your Excel export function here, using the exportData and columns
    // };


    useEffect(()=>{
      if(actionType === 'GetIndividualDividendLedger'){
        fetchInitialData()
      }
    }, [actionType])


    const fetchInitialData = useCallback(async () => {
      setLoading(true);
      setSnackbarOpen(true)
      try {
        const [memberIdResponse ] = await Promise.all([
          axios.post(`${API_URL}/Admin/Payments/GroupDividendAndTFInt`, { Action: 'GetMemberID' }, { headers: { Authorization: `Bearer ${token}` } }),         
        ]);
        setMemberList(memberIdResponse.data.List || []);
        setTotalRecords(memberIdResponse.data.Footer[0]?.TotalRecord || 0);
      } catch (error) {
        setSnackbarMessage('Failed to fetch members.');
        setSnackbarOpen(true);
      }
      setLoading(false);
    
     }, [ ]);

      
      const handleSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);
        await fetchData(values); // Fetch data when the form is submitted
        setCurrentPage(1)
        setSubmitting(false);
      };
    

      const handleRadioChange = (e) => {
        setActionType(e.target.value);
      };
    
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
      };

  return (
    <LayoutContainer>
        <Paper elevation={5} style={{padding:'16px', marginBottom:'16px',}} >
            <Formik
                     initialValues={initialValues}
                     validationSchema={validationSchema}
                     onSubmit={handleSubmit}
                >
                   {({errors, touched, handleChange,values,onChange, isSubmitting})=>(
                    <Form >
                            <Grid item  xs={3}>
                            <RadioGroup
                                name="reportType"
                                value={actionType}
                                onChange={handleRadioChange}
                                row
                            >
                                <FormControlLabel 
                                    value='GetDividendLedger'
                                    control={<Radio />} 
                                    label="Dividend Ledger" 
                                />
                                <FormControlLabel 
                                    value='GetIndividualDividendLedger' 
                                    control={<Radio />} 
                                    label="Individual Dividend Ledger" />
                                <FormControlLabel 
                                    value='GetCashBookReport'
                                    control={<Radio />} 
                                    label="Cash Book Register" 
                                />
                            </RadioGroup>
                            </Grid>
                         <Grid container spacing={2}>
                            {[
                                    { name: "FromDate", label: "From Date", type:"date" },
                                    { name: "ToDate", label: "To Date", type:"date" },
                                    
                                   ].map(({ name, label, type = "text", component }) => (
                                      <Grid item key={name} xs={3}  style={{display:'flex', flexDirection:'column'}} >
                                          {component ? component : (
                                              <Field
                                                  as={TextField}
                                                  name={name}
                                                  label={label}
                                                  type={type}
                                                  value={values[name]}
                                                  size="small"
                                                  fullWidth
                                                  onChange={handleChange}
                                                  margin="normal"
                                                  error={touched.name && Boolean(errors.name)}
                                                  helperText={touched.name && errors.name}
                                                  InputLabelProps={type === "date" ? { shrink: true } : {}}
                                              />
                            )}
                            </Grid>
                        ))} 
                        {actionType === 'GetIndividualDividendLedger' && (
                                        <Grid item xs={12} md={4}>
                                             <Field
                                                  as={TextField}
                                                  name="MemberID"
                                                  label="Member ID"
                                                  type="text"
                                                  size="small"
                                                  fullWidth
                                                  margin="normal"
                                                  error={touched.MemberId && Boolean(errors.MemberId)}
                                                  helperText={touched.MemberId && errors.MemberId}
                                                  onChange={handleChange}
                                                />
                                        </Grid> 
                            )}
                            {actionType === 'GetCashBookReport' && (
                                        <Grid item xs={12} md={4}>
                                             <Field
                                                  as={TextField}
                                                  name="SelectChequeDate"
                                                  label="SelectChequeDate"
                                                  type="date"
                                                  size="small"
                                                  fullWidth
                                                  margin="normal"
                                                  InputLabelProps={{ shrink: true }}
                                                  error={touched.SelectChequeDate && Boolean(errors.SelectChequeDate)}
                                                  helperText={touched.SelectChequeDate && errors.SelectChequeDate}
                                                  onChange={handleChange}
                                                />
                                        </Grid> 
                            )}


                         </Grid>
                         <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                              Fetch Data
                      </Button>
                        </Form>)} 
         </Formik>    
        </Paper>
        <Paper elevation={5} style={{padding:'16px', marginBottom:'16px'}} >
            {
                loading ? (
                    <LoadingStyle >
                      <CircularProgress />
                    </LoadingStyle>):(
                    <TableStyle>
                    <Box>
                        <ReusableTable 
                            data={dailyExpend} 
                            columns={actionType === 'GetDividendLedger' || actionType === 'GetIndividualDividendLedger' ? DividentLedger : CashBookReport}
                        />
                    </Box>
                    <Pagenation 
                        currentPage={currentPage} 
                        setCurrentPage={setCurrentPage} 
                        totalRecords={totalRecords} 
                        pageSize={pageSize}
                        />
                    </TableStyle>  )}
                    <Button variant="contained" color="primary" onClick={exportToExcel} style={{ marginLeft: '8px' }}>
                        Export to Excel
                      </Button>
    </Paper>
    {error && (
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={()=>setError(null)}
        message={error}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => setError(null)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    )}
    <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton size="small" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
    />
      
    </LayoutContainer>
  )
}

export default DividentReports
