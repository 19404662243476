import React, { useState, useEffect } from 'react';
import {
  TextField, Button, Typography, Container, Box, IconButton, CircularProgress, Paper, Snackbar,} from '@mui/material';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';


import ReusableTable from './../../../reuseable/ReusableTable';
// import MemberForm from './MemberForm';
import { useNavigate } from 'react-router-dom';
import RenderFormComponent from '../MemberForm/renderFormComponent';

const LayoutContainer = styled(Container)`  
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content:start;
  max-width: 600px;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  adding: 16px;
  top: 12%;
  left:18%;
  marginTop: theme.spacing(2);
  marginBottom: theme.spacing(2);
  backgroundColor:'#ccffff';
`;

const CenterStyle = styled(Box)`
    display: flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    width:100%;
    margin: 8px;
    // padding:8px; 

`;
const Left = styled(Box)`
    display: flex;
    flex-direction:row;
    align-items:center;
    justify-content:start;
    // margin:8px;
`;

const Right = styled(Box)`
  display: flex; 
  justify-content:flex-end;
  margin-bottom: theme.space(2);
`;

const LoadingStyle = styled(Box)`
    display:flex ;
    justify-content:center; 
    align-items:center;
     height: 100vh;
`;

const TableStyle = styled(Box)`
  display:flex;
  // height: 100vh;
  flex-direction:column;
  justify-content:start; 
  margin-bottom:0px;
  padding:8px; 
  background-color:'black'
`

const PageingStyle = styled(Box)`
    display:flex;
    flex-direction:row;
    justify-content:end;  
     align-items:center;
    margin:16px 0;
`

const validationSchema = Yup.object().shape({
  MemberID: Yup.number().required('Member ID is required'),
  SNO:Yup.number().required('SNO ID is required'),
  PayStaffNo: Yup.string().required('Pay Staff No is required'),
  HRMSNo: Yup.string().required('HRMS No is required'),
  AppFormNo: Yup.string().required('App Form No is required'),
  Title:Yup.string().required('Title Form No is required'),
  FirstName: Yup.string().required('First Name is required'),
  LastName: Yup.string(),
  FatherName:Yup.string().required('Father Name is required'),
  Unit:Yup.string().required('Unit Name is required'),
  SubUnit:Yup.string().required('SubUnit Name is required'),
  Designation: Yup.string().required('Designation is required'),
  BankName: Yup.string().required('Bank Name is required'),
  CertifiedByBSNL:Yup.boolean,
  BankAcNo: Yup.string().required('Bank Account Number is required'),
  Thriftfund: Yup.number().required('Thrift Fund is required'),
  EntranceDate:Yup.date().required('Date of Entrance is required'),
  AppointmentDate: Yup.date().required('Appointment Date is required'),
  ResignationDate: Yup.date().required('Resignation Date is required'),
  RetirementDate: Yup.date().required('Retirement Date is required'),
  dateOfBirth: Yup.date().required('Date of Birth is required'),
  EntryDate:Yup.date().required('Entry of Birth is required'),
  UserEntry:Yup.string().required('User Entry  is required'),
  UserCancel:Yup.string().required('User Entry  is required'),
  CancelDate:Yup.date().required('Entry of Birth is required'),  
  UserEdit: Yup.string().required('User Edit Number is required'),
  EditDate: Yup.date(),
  Remarks: Yup.string(),
  CircleName:Yup.string().required('Circle Name  is required'),
  C_add1:Yup.string(),
  C_add2:Yup.string(),
  C_add3:Yup.string(),
  C_City:Yup.string(),
  C_State:Yup.string().required('C state   is required'),
  C_Pin:Yup.string(),
  O_add1:Yup.string().required('office Add  is required'),
  O_add2:Yup.string(),
  O_add3:Yup.string(),
  O_City:Yup.string(),
  O_State:Yup.string().required('officestate   is required'),
  O_Pin:Yup.string(),
  P_add1:Yup.string(),
  P_add2:Yup.string(),
  P_add3:Yup.string(),
  P_City:Yup.string(),
  P_State:Yup.string().required('Parmement state   is required'),
  P_Pin:Yup.string(),
  HomePhone:Yup.number(),
  OfficePhone:Yup.number(),
  MobileNo:Yup.number(),
  EmailID:Yup.string(),
  EmergencyPhone:Yup.number(),
  AddressUserEdit:Yup.string().required('Parmement   Add  is required'),
  NomineeName:Yup.string(),
  Add1:Yup.string(),
  Add2:Yup.string(),
  Add3:Yup.string(),
  City:Yup.string(),
  State:Yup.string(),
  Pin:Yup.string(),
  PhoneNo: Yup.string(),
  Relation:Yup.string(),
  Age: Yup.number(), 
  IsMinor: Yup.boolean().required(),
  DOB:Yup.date().when('isMinor',{
    is: true,
    then: Yup.number().required('Date of Birth is required')
  }),
  Percentage:Yup.number().when('isMinor',{
    is: true,
    then: Yup.number().required('Percentage is required')
  }), 
  G_Name:Yup.string().when('isMinor', {
    is: true,
    then: Yup.string().required('Guardian Relation is required'),
    // otherwise: Yup.string().notRequired(),
  }),
  G_Relation:Yup.string().when('isMinor', {
    is: true,
    then: Yup.string().required('Guardian Name is required'),
    // otherwise: Yup.string().notRequired(),
  }),
  NomineeRemarks:Yup.string(),
  NomineeUserEdit:Yup.number().required('Nominee Remarks is required'),   
});


const token = localStorage.getItem('token')
const API_BASE_URL ='https://tecslkoupe.com'

const MemberMaster = () => {

  const navigate = useNavigate()
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [MemberID, setMemberID] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [editingMember, setEditingMember] = useState(null);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  
  const [pageSize] = useState(10); 

 
  useEffect(() => { 

    fetchMembers(currentPage);

  }, [currentPage]);

  const fetchMembers = async (page) => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/Admin/Member/Member`,{
        Action: 'GetAll',
        PageIndex: page,
        pageSize
      },{
        headers: {
          Authorization: `Bearer ${token}`,
         'Content-Type': 'application/json'
        }
      });
      
      setTotalRecords(response.data.Footer[0].TotalRecord);
      setMembers(response.data.List|| []);
    } catch (error) {
      setError(`Failed to fetch users  ${error.message}`);
    }
    setLoading(false);
  };
  

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/Admin/Member/Member`,{
        Action: 'GetById',
        MemberID: MemberID
      },{
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
    
      setMembers(response.data.List);
      setTotalRecords(response.data.Footer[0].TotalRecord);
    } catch (error) {
      setError(`Error fetching member: ${error.message}`);
    }
    setLoading(false);
  };

  const handleShowAll = () => {
    fetchMembers(currentPage);
  };

  const handleEditClick = (member) => {
    setEditingMember(member);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditingMember(null);
  };


  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };



  const handleSubmit = async (values, setSubmitting, resetForm) => {
    try {
      if (editingMember) {
        await axios.post(`${API_BASE_URL}/Admin/Member/Member/`,{
          ...values,
          Action: 'Update'
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        });
        setSnackbarMessage('member updated successfully');
      } else {
        await axios.post(`${API_BASE_URL}/Admin/Member/Member`,{
          ...values,
          Action: 'Insert'
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        });
        setSnackbarMessage('User added successfully');
      }
      fetchMembers(currentPage);
      setSnackbarOpen(true);
      setSubmitting(false);
      handleCloseModal();
      resetForm();
    } catch (error) {
      setError('Failed to add/update user');
      setSubmitting(false);
    }
    };
 
    const handleAdd = ()=>{
      navigate('/home/members/*')
    }
    
  const columns = [
    { field: 'RowNumber', headerName: 'RowNumber', width: 130 },
    { field: 'SNO', headerName: 'SNO', width: 130 },
    { field: 'MemberID', headerName: 'Member ID', width: 180 },
    { field: 'PayStaffNo', headerName: 'Pay Staff No', width: 150 },
    { field: 'HRMSNo', headerName: 'HRMS No', width: 150 },
    { field: 'AppFormNo', headerName: 'App Form No', width: 150 },
    { field: 'Title', headerName: 'App Form No', width: 150 },
    { field: 'FirstName', headerName: 'First Name', width: 250 },
    { field: 'LastName', headerName: 'Last Name', width: 250 },
    { field: 'FatherName', headerName: 'Father Name', width: 150 },
    { field: 'Unit', headerName: 'Unit', width: 150 },
    { field: 'SubUnit', headerName: 'Sub Unit', width: 150 },
    { field: 'Designation', headerName: 'Designation', width: 130 },
    { field: 'BankName', headerName: 'Bank Name', width: 130 },
    { field: 'CertifiedByBSNL', headerName: 'Certified By BSNL', width: 200 },
    { field: 'ThriftFund', headerName: 'Thrift Fund', width: 200 },
    { field: 'EntranceDate', headerName: 'Entrance Date', width: 200 },
    { field: 'AppointmentDate', headerName: 'Appointment Date', width: 200 },
    { field: 'RetirementDate', headerName: 'Retirement Date', width: 200 },
    { field: 'ResignationDate', headerName: 'Bank Account Number', width: 200 },
    { field: 'DateOfBirth', headerName: 'Date Of Birth', width: 100 },
    { field: 'EntryDate', headerName: 'Entry Date', width: 150 },
    { field: 'UserEntry', headerName: 'User Entry', width: 150 },
    { field: 'UserCancel', headerName: 'User Cancel', width: 150 },
    { field: 'CancelDate', headerName: 'Cancel Date', width: 150 },
    { field: 'UserEdit', headerName: 'User Edit', width: 150 },
    { field: 'EditDate', headerName: 'Edit Date', width: 150 },
    { field: 'Remarks', headerName: 'Remarks', width: 150 },
    { field: 'CircleName', headerName: 'Circle Name', width: 150 },
    { field: 'C_add1', headerName: 'Circle add1', width: 150 },
    { field: 'C_add2', headerName: 'Circle add2', width: 150 },
    { field: 'C_add3', headerName: 'Circle add3', width: 150 },
    { field: 'C_City', headerName: 'Circle City', width: 150 },
    { field: 'C_State', headerName: 'Circle State', width: 150 },
    { field: 'C_Pin', headerName: 'Circle Pin', width: 150 },
    { field: 'O_add1', headerName: 'Office add1', width: 150 },
    { field: 'O_add2', headerName: 'Office add2', width: 150 },
    { field: 'O_add3', headerName: 'Office add3', width: 150 },
    { field: 'O_City', headerName: 'Office City', width: 150 },
    { field: 'O_State', headerName: 'Office State', width: 150 },
    { field: 'O_Pin', headerName: 'Office Pin', width: 150 },
    { field: 'P_add1', headerName: 'P add1', width: 150 },
    { field: 'P_add2', headerName: 'P add2', width: 150 },
    { field: 'P_add3', headerName: 'P add3', width: 150 },
    { field: 'P_City', headerName: 'P City', width: 150 },
    { field: 'P_State', headerName: 'P State', width: 150 },
    { field: 'P_Pin', headerName: 'P Pin', width: 150 },
    { field: 'HomePhone', headerName: 'Home Phone', width: 150 },
    { field: 'OfficePhone', headerName: 'Office Phone', width: 150 },
    { field: 'MobileNo', headerName: 'Mobile No', width: 150 },
    { field: 'EmailID', headerName: 'Email ID', width: 150 },
    { field: 'EmergencyPhone', headerName: 'Emergency Phone', width: 150 },
    { field: 'AddressUserEdit', headerName: 'Address User Edit', width: 150 },
    { field: 'NomineeName', headerName: 'Nominee Name', width: 150 },
    { field: 'Add1', headerName: 'Add1', width: 150 },
    { field: 'Add2', headerName: 'Add2', width: 150 },
    { field: 'Add3', headerName: 'Add3', width: 150 },
    { field: 'City', headerName: 'City', width: 150 },
    { field: 'State', headerName: 'State', width: 150 },
    { field: 'Pin', headerName: 'Pin', width: 150 },
    { field: 'PhoneNo', headerName: 'PhoneNo', width: 150 },
    { field: 'Relation', headerName: 'Relation', width: 150 },
    { field: 'Age', headerName: 'Age', width: 150 },
    { field: 'IsMinor', headerName: 'IsMinor', width: 150 },
    { field: 'DOB', headerName: 'DOB', width: 150 },
    { field: 'Percentage', headerName: 'Percentage', width: 150 },
    { field: 'G_Name', headerName: 'Gurdian Name', width: 150 },
    { field: 'G_Relation', headerName: 'Gurdian Relation', width: 150 },
    { field: 'NomineeRemarks', headerName: 'NomineeRemarks', width: 150 },
    { field: 'NomineeRemarks', headerName: 'NomineeRemarks', width: 150 },
   
  ];

  return(
    <LayoutContainer>
       <Paper elevation={3} style={{padding:'16px', marginBottom:'16px'}} >
       <CenterStyle >
        <Left  >
          <TextField
            headerName="Enter Member ID"
            value={MemberID}
            ml={2}
            onChange={(e) => setMemberID(e.target.value)}
            sx={{margin:'8px'}}
          />
          <Box  > 
            <Button variant="contained" color="primary" onClick={handleSearch} ml={2} disabled={!MemberID}  sx={{margin:'8px'}} >
              Search
            </Button>      
          </Box>
          <Box >   
            <Button variant="contained" color="secondary"  ml={2} onClick={handleShowAll}  sx={{margin:'8px'}}>
              Show All
            </Button>
          </Box>
        </Left>
      <Right mb={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            // onClick={() => handleOpen()}
            // onClick={() => setOpenModal(true)}
            onClick={handleAdd}
          >
            Add User
          </Button>
      </Right>
      </CenterStyle>
       </Paper>
       <Paper elevation={3} style={{padding:'16px'}}>
        {
          loading ? (
            <LoadingStyle >
              <CircularProgress />
            </LoadingStyle>
          ) :(
               openModal === false  ? ( 
                <TableStyle>
                <Box>
                  <ReusableTable data={members} columns={columns} onEdit={handleEditClick}/>
                </Box>  
              <PageingStyle  >
                <Button
                  variant="contained"
                  onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
                  disabled={currentPage === 1}
                >
                  Previous
                </Button>
              <Typography variant="h6" style={{ margin: '0 16px' }}>
                Page {currentPage} of {Math.ceil(totalRecords / pageSize)}
              </Typography>
              <Button
                variant="contained"
                onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                disabled={currentPage === Math.ceil(totalRecords / pageSize)}
              >
                Next
              </Button>
              </PageingStyle>
              </TableStyle>

               ) :( 
                     
                <Formik
                initialValues={editingMember || {
                  MemberID:'',
                  SNO:'',
                  PayStaffNo:'',
                  HRMSNo: '',
                  AppFormNo: '',
                  Title:'',
                  FirstName: '',
                  LastName: '',
                  FatherName:'',
                  Unit:'',
                  SubUnit:'',
                  Designation: '',
                  BankName: '',
                  CertifiedByBSNL: '',
                  BankAcNo:'',
                  Thriftfund: '',
                  EntranceDate:'',
                  AppointmentDate: '',
                  ResignationDate:'',
                  RetirementDate: '',
                  dateOfBirth: '',
                  EntryDate:'',
                  UserEntry:'',
                  UserCancel:'',
                  CancelDate:'',  
                  UserEdit: '',
                  EditDate: '',
                  Remarks: '',
                  CircleName:'',
                  C_add1:'',
                  C_add2:'',
                  C_add3:'',
                  C_City:'',
                  C_State:'',
                  C_Pin:'',
                  O_add1:'',
                  O_add2:'',
                  O_add3:'',
                  O_City:'',
                  O_State:'',
                  O_Pin:'',
                  P_add1:'',
                  P_add2:'',
                  P_add3:'',
                  P_City:'',
                  P_State:'',
                  P_Pin:'',
                  HomePhone:'',
                  OfficePhone:'',
                  MobileNo:'',
                  EmailID:'',
                  EmergencyPhone:'',
                  AddressUserEdit:'',
                  NomineeName:'',
                  Add1:'',
                  Add2:'',
                  Add3:'',
                  City:'',
                  State:'',
                  Pin:'',
                  PhoneNo:'',
                  Relation:'',
                  Age:'', 
                  IsMinor: '',
                  DOB:'',
                  Percentage:'', 
                  G_Name:'',  
                  G_Relation:'',
                  NomineeRemarks:'',
                  NomineeUserEdit:'', 
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, values, handleChange, setFieldValue,  handleBlur }) => 
                  <RenderFormComponent isSubmitting={isSubmitting} values={values} handleChange={handleChange}  handleBlur={handleBlur} setFieldValue={setFieldValue} /> 
                  }
              </Formik>
               ) 
            
          )
        }
      </Paper>
        

    {error && (

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={()=>setError(null)}
        message={error}
        action={
          <IconButton size="small" aria-headerName="close" color="inherit" onClick={() => setError(null)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    )}

    <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton size="small" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />

    </LayoutContainer>
  )
}

export default MemberMaster;
