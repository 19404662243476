import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField, Button, Typography, Container, Box, IconButton, CircularProgress, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, Snackbar, 
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';


const LayoutContainer = styled(Container)`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  padding: 16px;
  top: 12%;
  left:18%;

`;

const ADDBtn = styled(Button)`
  display:flex; 
  justify-content: center; 
  margin-bottom: 16px; 
  width: 15%;
  align-items:center; 
  padding:8px;
`

const TableRowHeadStyle  = styled(TableRow)`
  display:flex;
  flex-direction:row;
  background-color:#1565c0;
  color:white; 
  min-width:200px; 
  justify-content:start;
`;

const TableCellHeadStyle = styled(TableCell)`
  display:flex;
  flex-direction:column;
  color:white; 
  min-width:200px; 
  justify-content:start;
`;

const TableRowStyle  = styled(TableRow)`
  display:flex;
  flex-direction:row;
  min-width:200px; 
  justify-content:start;
`;

const TableCellStyle = styled(TableCell)`
  display:flex;
  flex-direction:row;
  min-width:200px; 
  justify-content:start;
  alignItems:'center'
`;

const API_URL = 'https://tecslkoupe.com'
const token = localStorage.getItem('token')

const Bank = () => {
  const [bank, setBank] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [bankToDelete, setBankToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize] = useState(10); 

 
  const fetchBanks = async (pageIndex) => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/Admin/Master/BankMaster`, {
        "Action": "GetAll",
        pageIndex,
        pageSize
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
      setBank(response.data.List);
      setTotalRecords(response.data.Footer[0].TotalRecord);
      setLoading(false);
    } catch (error) {
      setError(`Failed to fetch users ${error} `);
      setSnackbarMessage(error.message)
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBanks(currentPage);
  }, [currentPage]);

  const handleOpen = (bank = null) => {
    setSelectedBank(bank);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedBank(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleAddUpdateUser = async (values, { setSubmitting, resetForm }) => {
    try {
      if (selectedBank) {
        await axios.post(`${API_URL}/Admin/Master/BankMaster`, 
            { ...values,"Action": "Update" , UserID: selectedBank.UserID },{
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
        );
        setSnackbarMessage('Bank updated successfully');
      } else {
        await axios.post(`${API_URL}/Admin/Master/BankMaster`, {...values, "Action": "Insert"},{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        setSnackbarMessage('User added successfully');
      }
      fetchBanks(currentPage);
      setSnackbarOpen(true);
      setSubmitting(false);
      handleClose();
      resetForm();
    } catch (error) {
      setError(`Failed to add/update user `);
      setSubmitting(false);
      snackbarMessage(error.message)
    }
  };


  const handleDeleteDialogOpen = (bankID) => {
    setBankToDelete(bankID);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setBankToDelete(null);
  };

  const handleDeleteUser = async (bankID) => {
    try {
      await axios.post(`${API_URL}/Admin/Master/BankMaster`, 
        { bankID: bankID, "Action": "Delete" },
        {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          } 
    );
      setSnackbarMessage(`Bank  with ID ${bankToDelete}  deleted successfully`);
      fetchBanks(currentPage);
      setSnackbarOpen(true);
      handleDeleteDialogClose();
    } catch (error) {
      setError(`Failed to delete Bank: ${error}`);
      setSnackbarMessage(error.message)
      handleDeleteDialogClose();
    }
  };

  const validationSchema = Yup.object({
    ShortName: Yup.string().required('User Name is required'),
    BankName: Yup.string().required('Name is required'),
    BankCode: Yup.string().nullable(),
    Address: Yup.string().nullable(),
    Category: Yup.string().required('Category is required'),
    AccountNo: Yup.string().nullable(),
  });

  const renderForm = (isSubmitting, values, handleChange) => (
    <Form>
      <Field 
        as={TextField} 
        name="ShortName" 
        label="ShortName"
        value={values.ShortName} 
        fullWidth 
        margin="normal" 
    />
      <ErrorMessage name="UserName" component="div" className="error" />
      <Field 
        as={TextField} 
        name="BankName" 
        label="Bank Name"
        value={values.BankName} 
        fullWidth
         margin="normal" />
      <ErrorMessage name="Name" component="div" className="error" />
      <Field 
        as={TextField} 
        name="BankCode" 
        label="Bank Code" 
        value={values.BankCode}
        fullWidth 
        margin="normal" 
    />
      <ErrorMessage name="Phone" component="div" className="error" />
      <Field 
        as={TextField} 
        name="Address" 
        label="Address" 
        value={values.Address}
        fullWidth 
        margin="normal" 
        />
      <ErrorMessage name="Address" component="div" className="error" />
      <Field 
        as={TextField} 
        name="Category" 
        label="Category" 
        value={values.Category}
        fullWidth 
        margin="normal" 
    />
      <ErrorMessage 
        name="Category" 
        component="div" 
        className="error" 
    />
      <Field 
        as={TextField} 
        name="AccountNo" 
        label="AccountNo" 
        value={values.AccountNo}
        fullWidth 
        margin="normal" />
      <ErrorMessage name="SSACode" component="div" className="error" />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={isSubmitting}
        style={{ marginTop: '16px' }}
      >
        {isSubmitting ? 'Submitting...' : 'Submit'}
      </Button>
    </Form>
  );

  return (
    <LayoutContainer >
      <Typography variant="h4" sx={{display:'flex', justifyContent:'flex-start'}} gutterBottom>
        BANK
      </Typography>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <ADDBtn
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => handleOpen()}
            style={{ marginBottom: '16px' }}
            sx={{display:'flex', justifyContent:'flex-end'}}
          >
            Add BANK
          </ADDBtn>    
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRowHeadStyle>
                  <TableCellHeadStyle  >Short Name</TableCellHeadStyle>
                  <TableCellHeadStyle >Bank Name</TableCellHeadStyle>
                  <TableCellHeadStyle  >Bank Code</TableCellHeadStyle>
                  <TableCellHeadStyle  >Address</TableCellHeadStyle>
                  <TableCellHeadStyle  >Category</TableCellHeadStyle>
                  <TableCellHeadStyle  >AccountNo</TableCellHeadStyle>
                  <TableCellHeadStyle  >Actions</TableCellHeadStyle>
                </TableRowHeadStyle>
              </TableHead>
              <TableBody>
                {bank.map((bank) => (
                  <TableRowStyle  >
                    <TableCellStyle  >{bank.ShortName}</TableCellStyle>
                    <TableCellStyle >{bank.BankName}</TableCellStyle>
                    <TableCellStyle >{bank.BankCode}</TableCellStyle>
                    <TableCellStyle >{bank.Address || 'N/A'}</TableCellStyle>
                    <TableCellStyle >{bank.Category}</TableCellStyle>
                    <TableCellStyle >{bank.AccountNo || 'N/A'}</TableCellStyle>
                    <TableCellStyle>
                      <IconButton onClick={() => handleOpen(bank)} color="primary">
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() =>  handleDeleteDialogOpen(bank.bankID)} color="warning">
                        <DeleteIcon />
                      </IconButton>
                    </TableCellStyle>
                  </TableRowStyle>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="center" alignItems="center" margin="16px 0">
            <Button
              variant="contained"
              onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <Typography variant="h6" style={{ margin: '0 16px' }}>
              Page {currentPage} of {Math.ceil(totalRecords / pageSize)}
            </Typography>
            <Button
              variant="contained"
              onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
              disabled={currentPage === Math.ceil(totalRecords / pageSize)}
            >
              Next
            </Button>
          </Box>
        </>
      )}

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{selectedBank ? 'Update User' : 'Add User'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedBank ? 'Update the user information below' : 'Fill in the information below to add a new user'}
          </DialogContentText>
          <Formik
            initialValues={selectedBank || {
              UserName: '',
              Name: '',
              Phone: '',
              Address: '',
              Category: '',
              SSACode: '',
              CSCCode: ''
            }}
            validationSchema={validationSchema}
            onSubmit={handleAddUpdateUser}
          >
            {({ isSubmitting, values, handleChange }) => renderForm(isSubmitting, values, handleChange)}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the Bank with ID {bankToDelete}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteUser} color="warning">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </LayoutContainer>
  );
};

export default Bank;
