import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField, Button, Typography, Container, Box, IconButton, CircularProgress, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, Snackbar, 
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import { Email } from '@mui/icons-material';
import ReusableTable from '../../reuseable/ReusableTable';


const LayoutContainer = styled(Container)`
 position: absolute;
  display: flex;
  flex-direction: column;
  justify-content:start;
  max-width: 600px;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  adding: 16px;
  top: 12%;
  left:18%;
  marginTop: theme.spacing(2);
  marginBottom: theme.spacing(2);
`;

const ADDBtn = styled(Button)`
  display:flex; 
  justify-content: center; 
  margin-bottom: 16px; 
  width: 15%;
  align-items:center; 
  padding:8px;
`
const CenterStyle = styled(Box)`
    display: flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    width:100%;
    // margin: 8px;
    // padding:8px; 

`;
const Left = styled(Box)`
    display: flex;
    flex-direction:row;
    align-items:center;
    justify-content:start;
    // margin:8px;
`;

const Right = styled(Box)`
  display: flex; 
  justify-content:flex-end;
  align-items:center;
  margin-bottom:0px; 
`;

const LoadingStyle = styled(Box)`
    display:flex ;
    justify-content:center; 
    align-items:center;
     height: 100vh;
`;

const TableStyle = styled(Box)`
display:flex;
// height: 100vh;
flex-direction:column;
justify-content:start; 
margin-bottom:0px;
padding:8px; 
background-color:'black'
`

const PageingStyle = styled(Box)`
    display:flex;
    flex-direction:row;
    justify-content:end;  
    align-items:center;
    margin:16px 0;
`

const  columns = [
    { field: 'RowNumber', headerName: 'RowNumber', width: '150px' },
    // { field: 'SNO', headerName: 'SNO', width: '150px' },
    { field: 'UnitCode', headerName: 'Unit Code', width: '150px' },
    { field: 'UnitName', headerName: 'Unit Name', width: '350px' },
    { field: 'CircleName', headerName: 'Circle Name', width: '100px' },
    { field: 'Email', headerName: 'Email', width: '250px' },
  
]

const API_URL = 'https://tecslkoupe.com'
const token = localStorage.getItem('token')

const Unit = () => {
  const [Unit, setUnit] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [UnitCode, setUnitCode] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize] = useState(10); 

 
  const fetchUnit = async (pageIndex) => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/Admin/Setup/Unit`, {
        "Action": "GetAll",
        pageIndex,
        pageSize
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
      setUnit(response.data.List);
      setTotalRecords(response.data.Footer[0].TotalRecord);
      setLoading(false);
    } catch (error) {
      setError(`Failed to fetch users ${error} `);
      setSnackbarMessage(error.message)
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUnit(currentPage);
  }, [currentPage]);

  const handleOpen = (bank = null) => {
    setSelectedUnit(bank);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUnit(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleAddUpdateUser = async (values, { setSubmitting, resetForm }) => {
    try {
      if (selectedUnit) {
        await axios.post(`${API_URL}/Admin/Setup/Unit`, 
            { ...values,"Action": "Update" , UserID: selectedUnit.UserID },{
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
        );
        setSnackbarMessage('Bank updated successfully');
      } else {
        await axios.post(`${API_URL}/Admin/Setup/Unit`, {...values, "Action": "Insert"},{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        setSnackbarMessage('User added successfully');
      }
      fetchUnit(currentPage);
      setSnackbarOpen(true);
      setSubmitting(false);
      handleClose();
      resetForm();
    } catch (error) {
      setError(`Failed to add/update user `);
      setSubmitting(false);
      snackbarMessage(error.message)
    }
  };


  const handleDeleteDialogOpen = (bankID) => {
    setUnitCode(bankID);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setUnitCode(null);
  };

  const handleDeleteUser = async (bankID) => {
    try {
      await axios.post(`${API_URL}/Admin/Setup/Unit`, 
        { bankID: bankID, "Action": "Delete" },
        {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          } 
    );
      setSnackbarMessage(`Bank  with ID ${UnitCode}  deleted successfully`);
      fetchUnit(currentPage);
      setSnackbarOpen(true);
      handleDeleteDialogClose();
    } catch (error) {
      setError(`Failed to delete Bank: ${error}`);
      setSnackbarMessage(error.message)
      handleDeleteDialogClose();
    }
  };

  const validationSchema = Yup.object({
    // SName: Yup.string().required('Unit Code is required'),
    UnitName: Yup.string().required('Unit Name is required'),
    UnitCode: Yup.string().nullable(),
    CircleName: Yup.string().nullable(),
    Email: Yup.string().required('Category is required'),
  });

  const renderForm = (isSubmitting, values, handleChange) => (
    <Form>
      <Field 
        as={TextField} 
        name="UnitCode" 
        label="Unit Code"
        value={values.UnitCode} 
        fullWidth 
        margin="normal" 
    />
      <ErrorMessage name="UserName" component="div" className="error" />
      <Field 
        as={TextField} 
        name="UnitName" 
        label="Unit Name"
        value={values.UnitName} 
        fullWidth
         margin="normal" />
      <ErrorMessage name="Name" component="div" className="error" />
      <Field 
        as={TextField} 
        name="CircleCode" 
        label="Circle Code" 
        value={values.CircleCode}
        fullWidth 
        margin="normal" 
    />
      <ErrorMessage name="Phone" component="div" className="error" />
      <Field 
        as={TextField} 
        name="Email" 
        label="Email" 
        value={values.Email}
        fullWidth 
        margin="normal" 
        />
      <ErrorMessage name="Address" component="div" className="error" />
      
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={isSubmitting}
        style={{ marginTop: '16px' }}
      >
        {isSubmitting ? 'Submitting...' : 'Submit'}
      </Button>
    </Form>
  );

  return (
    <LayoutContainer >
      <Typography variant="h4" sx={{display:'flex', justifyContent:'flex-start'}} gutterBottom>
        Unit 
      </Typography>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <ADDBtn
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => handleOpen()}
            style={{ marginBottom: '16px' }}
            sx={{display:'flex', justifyContent:'flex-end'}}
          >
            Add Unit
          </ADDBtn>    
        <Paper elevation={3} style={{padding:'16px'}}>
        {
          loading ? (
            <LoadingStyle >
              <CircularProgress />
            </LoadingStyle>
          ) :(
            <TableStyle>
              <Box>
                <ReusableTable  
                  data={Unit} 
                  columns={columns} 
                  onEdit={handleOpen} 
                  onDelete={handleDeleteDialogOpen}
                />
              </Box>
            <PageingStyle  >
              <Button
                variant="contained"
                onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
            <Typography variant="h6" style={{ margin: '0 16px' }}>
              Page {currentPage} of {Math.ceil(totalRecords / pageSize)}
            </Typography>
            <Button
              variant="contained"
              onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
              disabled={currentPage === Math.ceil(totalRecords / pageSize)}
            >
              Next
            </Button>
            </PageingStyle>
            </TableStyle>
          )
        }
      </Paper> 
        </>
      )}

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{selectedUnit ? 'Update User' : 'Add User'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedUnit ? 'Update the user information below' : 'Fill in the information below to add a new user'}
          </DialogContentText>
          <Formik
            initialValues={selectedUnit || {
              UnitCode: '',
              UnitName: '',
              CircleName: '',
              Email: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleAddUpdateUser}
          >
            {({ isSubmitting, values, handleChange }) => renderForm(isSubmitting, values, handleChange)}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the Bank with ID {Unit.RowNumber}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteUser} color="warning">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </LayoutContainer>
  );
};

export default Unit;
