import React, { useEffect, useState } from 'react';
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup';
import {
    TextField, Button, Typography, Container, Box, IconButton, CircularProgress, Checkbox, CardContent
  , Paper, Dialog, DialogActions, Grid,DialogContent, DialogContentText, DialogTitle, Snackbar, FormControlLabel
  } from '@mui/material';
import styled from '@emotion/styled';
import axios from 'axios';

//icon
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ReusableTable from './../../reuseable/ReusableTable';
import Bank from './Bank';

const LayoutContainer = styled(Container)`  
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content:start;
  max-width: 600px;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  adding: 16px;
  top: 12%;
  left:18%;
  marginTop: theme.spacing(2);
  marginBottom: theme.spacing(2);
  
`;

const CenterStyle = styled(Box)`
    display: flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    width:100%;
    // margin: 8px;
    // padding:8px; 
`;
const Left = styled(Box)`
    display: flex;
    flex-direction:row;
    align-items:center;
    justify-content:start;
    // margin:8px;
`;

const Right = styled(Box)`
  display: flex; 
  justify-content:flex-end;
  align-items:center;
  margin-bottom:0px; 
`;

const LoadingStyle = styled(Box)`
    display:flex ;
    justify-content:center; 
    align-items:center;
     height: 100vh;
`;

const TableStyle = styled(Box)`
  display:flex;
  // height: 100vh;
  flex-direction:column;
  justify-content:start; 
  margin-bottom:0px;
  padding:8px; 
  background-color:'black'
`


const PageingStyle = styled(Box)`
    display:flex;
    flex-direction:row;
    justify-content:end;  
    align-items:center;
    margin:16px 0;
`

const validationSchema = Yup.object({
    category:Yup.string().required('Category Must be required'),
    shortName:Yup.string().required('Short Name must be required'),
    bankName:Yup.string().required('Bank name must be required'),
    opening:Yup.number().required('Opening must be greater then 0').positive('Must be positive'),
    micrCode:Yup.string().required('micrCode must be required'),
    accountNo:Yup.string().required('Account number must be required'),
    address:Yup.string().required('Must be required')
});

const initialValues ={
    category:'',
    shortName:'',
    bankName:'',
    opening:'',
    micrCode:'',
    accountNo:'',
    address:''
}


const ADDBtn = styled(Button)`
  display:flex; 
  justify-content: center; 
  margin-bottom: 16px; 
  width: 15%;
  align-items:center; 
  padding:8px;
`



const columns = [
    { field: 'RowNumber', headerName: 'RowNumber', width: '120px' },
    { field: 'ShortName', headerName: 'Short Name', width: '150px' },
    { field: 'BankName', headerName: 'Bank Name', width: '250px' },
    { field: 'BankCode', headerName: 'Bank Code', width: '150px' },
    { field: 'Address', headerName: 'Address', width: '150px' },
    { field: 'AccountNo', headerName: 'Account No', width: '150px' },
    { field: 'Category', headerName: 'Category', width: '150px' },
]

const token = localStorage.getItem('token')
const API_BASE_URL ='https://tecslkoupe.com'

const CreateBank = () => {
    const [openModal, setOpenModal] = useState(false);
    const [editingBank, setEditingBank] = useState(null);
    const [RowNumber, setRowNumber] = useState('')
    const [loading, setLoading]= useState(false);
    const [Banks, setBanks] = useState([]);
    const [error, setError] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageSize] = useState(10); 


    useEffect(() => { 

        fetchBanks(currentPage);
      }, [currentPage]);

   

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        try {
          if (editingBank) {
            await axios.post(`${API_BASE_URL}/Admin/Setup/Bank`,{
              ...values,
              Action: 'Update'
            }, {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              }
            });
            setSnackbarMessage('Bank updated successfully');
          } else {
            await axios.post(`${API_BASE_URL}/Admin/Setup/Bank`,{
              ...values,
              Action: 'Insert'
            }, {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              }
            });
            setSnackbarMessage('Bank added successfully');
          }
          fetchBanks(currentPage);
          setSnackbarOpen(true);
          setSubmitting(false);
          handleCloseModal();
          resetForm();
        } catch (error) {
          setError('Failed to add/update Bank');
          setSubmitting(false);
        }
        };

        const fetchBanks = async (page) => {
            setLoading(true);
            try {
              const response = await axios.post(`${API_BASE_URL}/Admin/Setup/Bank`,{
                Action: 'GetAll',
                PageIndex: page,
                pageSize
              },{
                headers: {
                  Authorization: `Bearer ${token}`,
                 'Content-Type': 'application/json'
                }
              });
              setTotalRecords(response.data.Footer[0].TotalRecord);
              setBanks(response.data.List|| []);
              console.log(response.data.List)
            } catch (error) {
              setError(`Failed to fetch users  ${error.message}`);
            }
            setLoading(false);
          };

          const handleCloseModal = () => {
            setOpenModal(false);
            setEditingBank(null);
          };

    const handleEditClick = (RowNumber) => {
        setEditingBank(RowNumber);
        setOpenModal(true);
      };
    

  return (
    <LayoutContainer  >
         <Paper elevation={3} style={{padding:'16px', marginBottom:'16px'}} >
            <Typography variant='h5' sx={{marginBottom:'16px',  display:'flex', justifyContent:'start', color:'#0b0e30',}} >
                Create Bank
            </Typography>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={()=>handleSubmit()}
                sx={{ position: 'absolute',top: '10%',
                    left: '15%',
                    padding: '5px',
                    margin:'10px',
                    width:'80%',
                    marginTop: '16px',
                    marginBottom: '16px',
                    backgroundColor:'#ccffff'}}
            >
                {({errors, touched, values})=>

                <Form>
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                        <Field
                            name="category"
                            as={TextField}
                            label="Category"
                            variant="outlined"
                            value={values.category}
                            type="text"
                            fullWidth
                            required
                            size="small"
                            error={touched.category && Boolean(errors.category)}
                            helperText={touched.category && errors.category}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Field
                            name="shortName"
                            as={TextField}
                            label="Short Name"
                            variant="outlined"
                            value={values.shortName}
                            type="text"
                            fullWidth
                            required
                            size="small"
                            error={touched.shortName && Boolean(errors.shortName)}
                            helperText={touched.shortName && errors.shortName}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Field
                            name="bankName"
                            as={ TextField}
                            label="Bank Name"
                            variant="outlined"
                            value={values.bankName}
                            type="text"
                            fullWidth
                            required
                            size="small"
                            error={touched.bankName && Boolean(errors.bankName)}
                            helperText={touched.bankName && errors.bankName}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        
                        <Field
                            name="opening"
                            as={ TextField}
                            label="Opening"
                            variant="outlined"
                            value={values.opening}
                            type="text"
                            fullWidth
                            required
                            size="small"
                            error={touched.opening && Boolean(errors.opening)}
                            helperText={touched.opening && errors.opening}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Field
                            name="micrCode"
                            as={ TextField}
                            label="MICR CODE"
                            variant="outlined"
                            value={values.micrCode}
                            type="text"
                            fullWidth
                            required
                            size="small"
                            error={touched.micrCode && Boolean(errors.micrCode)}
                            helperText={touched.micrCode && errors.micrCode}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Field
                            name="accountNo"
                            as={ TextField}
                            label="Accoount NO"
                            variant="outlined"
                            value={values.accountNo}
                            type="text"
                            fullWidth
                            required
                            size="small"
                            error={touched.accountNo && Boolean(errors.accountNo)}
                            helperText={touched.accountNo && errors.accountNo}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Field
                            name="address"
                            as={ TextField}
                            label="Address"
                            variant="outlined"
                            value={values.address}
                            type="text"
                            fullWidth
                            required
                            size="small"
                            error={touched.address && Boolean(errors.address)}
                            helperText={touched.address && errors.address}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{margin:'16px'}}>
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            startIcon={<SaveOutlinedIcon/>}
                            sx={{ color:'white', fontSize:'16px', fontWeight:600, marginRight:'8px'}}
                            size="small"
                        
                        >
                            Save
                        </Button>
                        <Button
                            type='submit'
                            variant='contained'
                            color='secondary'
                            startIcon={<EditOutlinedIcon/>}
                            sx={{ color:'white', fontSize:'16px', fontWeight:600, marginRight:'8px'}}
                            size="small"
                            // onClick={handleEditClick}
                        >
                            Edit
                        </Button>
                        <Button
                            type='button'
                            variant='contained'
                            color='warning'
                            startIcon={<DeleteOutlineOutlinedIcon/>}
                            sx={{ color:'white', fontSize:'16px', fontWeight:600, marginRight:'8px'}}
                            size="small"
                        
                        >
                            Delete
                        </Button>
                        {/* <Button
                            type='button'
                            variant='contained'
                            color='success'
                            startIcon={<CancelOutlinedIcon/>}
                            sx={{ color:'white', fontSize:'16px', fontWeight:600, marginRight:'8px'}}
                            size="small"
                        
                        >
                            Close
                        </Button> */}
                    </Grid>
                </Grid>
            
            </Form>

                }
            </Formik>
         </Paper>               
         <Paper elevation={3} style={{padding:'16px'}}>
        {
          loading ? (
            <LoadingStyle >
              <CircularProgress />
            </LoadingStyle>
          ) :(
            <TableStyle>
              <Box>
                <ReusableTable data={Banks} columns={columns} onEdit={handleEditClick}/>
              </Box>
      
            <PageingStyle  >
              <Button
                variant="contained"
                onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
            <Typography variant="h6" style={{ margin: '0 16px' }}>
              Page {currentPage} of {Math.ceil(totalRecords / pageSize)}
            </Typography>
            <Button
              variant="contained"
              onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
              disabled={currentPage === Math.ceil(totalRecords / pageSize)}
            >
              Next
            </Button>
            </PageingStyle>
            </TableStyle>
          )
        }
         </Paper>
         <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{editingBank ? 'Edit Bank' : 'Add Bank'}</DialogTitle>
        <DialogContent  
        >
          <DialogContentText>
            {editingBank ? 'Update the Bank information below' : 'Fill in the information below to add a new Bank'}
          </DialogContentText>
          <Formik
            initialValues={editingBank || {
                category:'',
                shortName:'',
                bankName:'',
                opening:'',
                micrCode:'',
                accountNo:'',
                address:''
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, values, handleChange, setFieldValue, handleBlur, errors, touched }) => 
            //   <Bank 
            //       isSubmitting={isSubmitting} 
            //       values={values} 
            //       handleChange={handleChange}  
            //       handleBlur={handleBlur} 
            //       errors={errors}
            //       touched={touched}
            //       setFieldValue={setFieldValue} /> 
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                        <Field
                            name="category"
                            as={TextField}
                            label="Category"
                            variant="outlined"
                            value={values.category}
                            type="text"
                            fullWidth
                            required
                            size="small"
                            error={touched.category && Boolean(errors.category)}
                            helperText={touched.category && errors.category}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Field
                            name="shortName"
                            as={TextField}
                            label="Short Name"
                            variant="outlined"
                            value={values.shortName}
                            type="text"
                            fullWidth
                            required
                            size="small"
                            error={touched.shortName && Boolean(errors.shortName)}
                            helperText={touched.shortName && errors.shortName}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Field
                            name="bankName"
                            as={ TextField}
                            label="Bank Name"
                            variant="outlined"
                            value={values.bankName}
                            type="text"
                            fullWidth
                            required
                            size="small"
                            error={touched.bankName && Boolean(errors.bankName)}
                            helperText={touched.bankName && errors.bankName}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        
                        <Field
                            name="opening"
                            as={ TextField}
                            label="Opening"
                            variant="outlined"
                            value={values.opening}
                            type="text"
                            fullWidth
                            required
                            size="small"
                            error={touched.opening && Boolean(errors.opening)}
                            helperText={touched.opening && errors.opening}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Field
                            name="micrCode"
                            as={ TextField}
                            label="MICR CODE"
                            variant="outlined"
                            value={values.micrCode}
                            type="text"
                            fullWidth
                            required
                            size="small"
                            error={touched.micrCode && Boolean(errors.micrCode)}
                            helperText={touched.micrCode && errors.micrCode}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Field
                            name="accountNo"
                            as={ TextField}
                            label="Accoount NO"
                            variant="outlined"
                            value={values.accountNo}
                            type="text"
                            fullWidth
                            required
                            size="small"
                            error={touched.accountNo && Boolean(errors.accountNo)}
                            helperText={touched.accountNo && errors.accountNo}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Field
                            name="address"
                            as={ TextField}
                            label="Address"
                            variant="outlined"
                            value={values.address}
                            type="text"
                            fullWidth
                            required
                            size="small"
                            error={touched.address && Boolean(errors.address)}
                            helperText={touched.address && errors.address}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{margin:'16px'}}>
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            startIcon={<SaveOutlinedIcon/>}
                            sx={{ color:'white', fontSize:'16px', fontWeight:600, marginRight:'8px'}}
                            size="small"
                        
                        >
                            Save
                        </Button>
                        <Button
                            type='submit'
                            variant='contained'
                            color='secondary'
                            startIcon={<EditOutlinedIcon/>}
                            sx={{ color:'white', fontSize:'16px', fontWeight:600, marginRight:'8px'}}
                            size="small"
                            // onClick={handleEditClick}
                        >
                            Edit
                        </Button>
                        <Button
                            type='button'
                            variant='contained'
                            color='warning'
                            startIcon={<DeleteOutlineOutlinedIcon/>}
                            sx={{ color:'white', fontSize:'16px', fontWeight:600, marginRight:'8px'}}
                            size="small"
                        
                        >
                            Delete
                        </Button>
                        {/* <Button
                            type='button'
                            variant='contained'
                            color='success'
                            startIcon={<CancelOutlinedIcon/>}
                            sx={{ color:'white', fontSize:'16px', fontWeight:600, marginRight:'8px'}}
                            size="small"
                        
                        >
                            Close
                        </Button> */}
                    </Grid>
                </Grid>

            </Form>
            }
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseModal}
            color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
        
    </LayoutContainer>
  )
}

export default CreateBank
