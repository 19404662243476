import React, { useState } from 'react'
import { Box, RadioGroup, FormControlLabel, Radio, TextField, Button, Paper, Container, Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';
import SearchCompinent from './SearchComponent';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ReusableTable from '../../../reuseable/ReusableTable';
import ChequeReversal from './ChequeReversal';

const LayoutContainer = styled(Container)`  
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content:start;
  max-width: 600px;
  width: 100%;
  // min-height: 120vh;
  // height: 120vh;
  adding: 16px;
  top: 12%;
  left:18%;
  // marginTop: theme.spacing(2);
  // marginBottom: theme.spacing(2);
  gap: 8px;
  
`;

const TableConatiner = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content:start;
  gap: 8px;
  max-width: 600px;
  width:600px;
  width: 100%;
`

const TableGrid = styled(Grid)`
  display: flex;
  flex:1;
  flex-direction: column;
  justify-content:start;
  gap: 8px;
  max-width: 570px;
  width: 100%;
`
const TableName = styled(Typography)`
  margin: 1px;
  padding: 1px;
  display: flex;
  justify-content: start;
`;




const initialValues = {
  searchType: 'onDate',
  onDate: new Date().toISOString().slice(0, 10),
  searchValue: '',
  ChequeNo: '',
  ReceiptTrancID:'',
  SelectActtion:'',
};

const validationSchema = Yup.object().shape({
  searchType: Yup.string().required('Required'),
  onDate: Yup.date().when('searchType', {
    is: 'onDate',
    then: Yup.date().required('Required')
  }),
  searchValue: Yup.string().when('searchType', {
    is: (val) => val !== 'onDate',
    then: Yup.string().required('Required')
  }),
});


const columns = [
  { field: 'sno', headerName: 'SNO' },
  { field: 'bankName', headerName: 'Bank Name' },
  { field: 'chequeNo', headerName: 'Cheque No' },
];

const columns1 = [
  { field: 'sno', headerName: 'SNO' },
  { field: 'trans_id', headerName: 'Trans_ID' },
  { field: 'Type', headerName: 'Type' },
  { field: 'Code', headerName: 'Code' },
  { field: 'Amount', headerName: 'Amount' },
];



const DepositedChequeReversal = () => {
  
  const [data, setData] = useState([]);

  const handleSubmit = (values) => {
    console.log('Form data', values);
    // Implement search functionality
  };

  const handleEditClick =()=>{

  }
  
  return (
    <LayoutContainer >
        <Grid p={2} component={Paper} elevation={5} xs={12} md={3} lg={8}  >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}    
          >
            {
              ({values, setFieldValue, handleChange, handleSubmit })=>(
               
                  <SearchCompinent values={values} setFieldValue={setFieldValue} handleChange={handleChange}/>
              )
            }
          </Formik>
        </Grid>

        <TableConatiner item xs={12} >
        <TableGrid p={2}  item  component={Paper} elevation={5} xs={6}>
            <TableName component="h4">Cheques Deposited with this voucher </TableName>
            <ReusableTable  data={data} columns={columns} onEdit={handleEditClick}/>
        </TableGrid>
        <TableGrid p={2}   item component={Paper} elevation={5} xs={6}>
             <TableName component="h4">Selected Cheque No Recived from</TableName>
            <ReusableTable  data={data} columns={columns1} onEdit={handleEditClick}/>
        </TableGrid>
        </TableConatiner>
        <Grid>
        {/* <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}    
          >
            {
              ({values, setFieldValue, handleChange, handleSubmit })=>(
               
                  <ChequeReversal values={values} setFieldValue={setFieldValue} handleChange={handleChange}/> 

              )
            }
          </Formik> */}
        </Grid>

    </LayoutContainer>
  )
}

export default DepositedChequeReversal;
