import { Grid, Paper, TextField, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';


const MemberDetailsCompo = ({ values, handleChange, errors, touched }) => (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Field
          as={TextField}
          name="memberId"
          label="Member ID"
          variant="outlined"
          fullWidth
          size="small"
          error={touched.memberId && Boolean(errors.memberId)}
          helperText={touched.memberId && errors.memberId}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Field
          as={TextField}
          name="name"
          label="Name / Designation"
          variant="outlined"
           size="small"
          fullWidth
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Field
          as={TextField}
          name="unit"
          label="Unit"
          variant="outlined"
          fullWidth
          size="small"
          error={touched.unit && Boolean(errors.unit)}
          helperText={touched.unit && errors.unit}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Field
          as={TextField}
          name="subUnit"
          label="Sub Unit"
          variant="outlined"
           size="small"
          fullWidth
          error={touched.subUnit && Boolean(errors.subUnit)}
          helperText={touched.subUnit && errors.subUnit}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Field
          as={TextField}
          name="lastRecoveryList"
          label="Last Recovery List"
          variant="outlined"
           size="small"
          fullWidth
          error={touched.lastRecoveryList && Boolean(errors.lastRecoveryList)}
          helperText={touched.lastRecoveryList && errors.lastRecoveryList}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Field
          as={TextField}
          name="lastUploadedList"
          label="Last Uploaded List"
          variant="outlined"
           size="small"
          fullWidth
          error={touched.lastUploadedList && Boolean(errors.lastUploadedList)}
          helperText={touched.lastUploadedList && errors.lastUploadedList}
        />
      </Grid>
    </Grid>
  );
  
  export default MemberDetailsCompo