import React from 'react'
import {
    TextField, Button, Typography, Container, Box, Checkbox, Paper,
    Grid, FormControlLabel, MenuItem, Select, InputLabel, FormControl
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage} from 'formik';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import RecoveryList from './RecoveryList';
import MemberIdComponent from '../home/MemberId';


const LayoutContainer = styled(Container)`  
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content:start;
  max-width: 600px;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  adding: 16px;
  top: 12%;
  left:18%;
 
  backgroundColor:'#ccffff';
`;



const validationSchema = Yup.object().shape({
    memberId: Yup.string().required('Member ID is required'),
    interestOutstanding: Yup.number().required('Interest Outstanding is required'),
})



// const initalValues = {
//       memberId: '',
//       unit: '',
//       subUnit: '',
//       interestOutstanding: '',
//       LastRecoveryList:'',
//       LastUploadedList:''

// }



const RaiseInterestOutStaingForm =({ isSubmitting, values, handleChange, handleBlur, setFieldValue })=>{
   
    return(
        <Form>
            <Grid container spacing={2}>
                <Grid item xs={6}>      
                 <Grid item xs={12} style={{margin:'16px'}}>
                    <MemberIdComponent   />
                </Grid>   
                {[
                    //  { name: "MemberId", label: "Member ID" },
                     { name: "Designation", label: "Name / Designation" },
                     { name: "Unit", label: "Unit" },
                ].map(({ name, label, type = "text", component }) => (
                    <Grid item key={name} xs={12} style={{margin:'16px'}}>
                        {component ? component : (
                            <Field
                                as={TextField}
                                name={name}
                                label={label}
                                type={type}
                                value={values[name]}
                                fullWidth
                                // margin="normal"
                                size="small"
                                InputLabelProps={type === "date" ? { shrink: true } : {}}
                            />
                        )}
                        <ErrorMessage name={name} component="div" className="error" />
                    </Grid>
                ))}
                </Grid>
                <Grid item xs={6}>
                {[
                     { name: "InterestOutstanding", label: "Interest Outstanding" },
                     { name: "LastRecoveryList", label: "Last Recovery List" },
                     { name: "LastUploadedList", label: "LastUploadedList" },
                ].map(({name, label, type="text"})=>(
                    <Grid item key={name} xs={12} style={{margin:'16px'}}>
                        <Field
                                        as={TextField}
                                        name={name}
                                        label={label}
                                        type={type}
                                        value={values[name]}
                                        fullWidth
                                        // margin="normal"
                                        size="small"
                                        InputLabelProps={type === "date" ? { shrink: true } : {}}
                        />
                    <ErrorMessage name={name} component="div" className="error" />
                    </Grid>
                ))
                } 
                </Grid>
               
            </Grid>
        </Form>
    )

}





const RaiseIntersetOutStanding = () => {

    const  handleSubmit =()=>{
        console.log('value')   
    }


  return (
        <LayoutContainer component={Paper} Component={Paper} elevation={5} >
            <Grid item sx={6}>
            <Formik
            initialValues={{
                memberId: '',
                unit: '',
                subUnit: '',
                interestOutstanding: '',
                LastRecoveryList:'',
                LastUploadedList:''
          
          }
          }
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, values, handleChange, setFieldValue }) => 
                    <RaiseInterestOutStaingForm isSubmitting={isSubmitting} values={values} handleChange={handleChange} setFieldValue={setFieldValue}/>
                  }
        </Formik>
            </Grid>
            <Grid item sx={6}>
                <RecoveryList/>
            </Grid>
        </LayoutContainer>

  )
}

export default RaiseIntersetOutStanding
