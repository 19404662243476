import React, { useEffect, useState } from 'react';
import { Grid, Box, TextField,Button, Typography ,IconButton,Snackbar,CircularProgress ,Paper} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import ReusableTable from './../../reuseable/ReusableTable';
import { LayoutContainer, LoadingStyle,TableStyle, PageingStyle } from '../../styles/globalStyle';
import PaymentDetailsSection from '../payments/PaymentDetailsSection';
import PrintIcon from '@mui/icons-material/Print';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import { jsPDF } from 'jspdf';
import Pagenation from '../../reuseable/pagenation';


 const columns = [
    { field: 'RowNumber', headerName: 'Row Number', width: '150px' },
    { field: 'SNO', headerName: 'S Number', width: '150px' },
    { field: 'Trans_ID', headerName: 'Transation ID', width: '150px' },
    { field: 'Receipt_No', headerName: 'Receipt NO', width: '150px' },
    { field: 'Voucher No', headerName: 'Voucher_No', width: '200px' },
    { field: 'PaidTo', headerName: 'Paid To', width: '150px' },
    { field: 'Type', headerName: 'Type ', width: '200px' },
    { field: 'ID', headerName: 'ID', width: '200px' },
    { field: 'Name', headerName: 'Name', width: '150px' },
    { field: 'Amount', headerName: 'Amount', width: '150px' },
    { field: 'Cheque_No', headerName: 'Cheque No', width: '200px' },
    { field: 'Cash_Cheque_Amount', headerName: 'Cheque Date', width: '200px' },
    // { field: 'Year', headerName: 'Year', width: '150px' },
  ];

const token = localStorage.getItem('token')
const API_URL ='https://tecslkoupe.com';


const initialValues = {
    Trans_ID: '',
    Payment_Mode: '',
    Voucher_No: '',
    Mode:'',
    Cheque_NO: '',
    Bank_Name: '',      
}

const validationSchema = Yup.object().shape({
    SelectDate: Yup.date().required('Required'),
    Trans_ID: Yup.number().required('Required'),
    Payment_Mode: Yup.date().required('Required'),
    Voucher_No: Yup.number().required('Required'),
    Mode: Yup.number().required('Required'),
    Cheque_NO: Yup.number().required('Required'),
    Bank_Name: Yup.string().required('Required'),
    
})

const DialyExpenditures = () => {
    const [dailyExpend, setDailyExpend] = useState([])
    const [selectedDate, setSelectedDate] = useState('');
    // const [SelectDate, setSelectD] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedYear, setSelectedYear] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [FinancialYear, setFinancialYear] = useState('')
    const [chequesList, setChequesList] = useState([])

    const [pageSize] = useState(10); 


    useEffect(()=>{
        const fetchDailyExpenditures = async() =>{
            setLoading(true);
            try{
                const response = await axios.post(`${API_URL}/Admin/Report/DailyExpenditures`,{
                    "Action": "GetDailyExpenditures",
                    // pageIndex,
                    // pageSize
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  });
                  setDailyExpend(response.data.List);
                  setTotalRecords(response.data.Footer[0].TotalRecord);
                  setLoading(false);
                } catch (error) {
                  setError(`Failed to fetch users ${error.message} `);
                  setSnackbarMessage(error.message)
                  setLoading(false);
                }
    }
    fetchDailyExpenditures();
},[])

// useEffect(()=>{
//     if(selectedDate){
//         fetchDividendData(selectedDate, currentPage);
//     }
// },[selectedDate, currentPage]);


const fetchFinalizeData = async (year, page , values) => {
    setLoading(true);
    try {
      const response = await axios.post(`{{url}}/Admin/Report/DailyExpenditures`,{
        Action: 'GetDividendAndTFInterest',
        SelectDate: values.selectedDate,
        Trans_ID: 54070,
        VoucherNo: 0,
        PageIndex: page,
        pageSize
      },{
        headers: {
          Authorization: `Bearer ${token}`,
         'Content-Type': 'application/json'
        }
      });
      if(response.data.code === 201 && response.data.status === true){
        setSnackbarMessage(response.data.message);
    } else {
        setSnackbarMessage('Unexpected response format or failure'); 
    } 
      setSnackbarMessage(response.message)
    } catch (error) {
      setError(`Failed to fetch dividend data  ${error.message}`);
      setSnackbarMessage(`Failed to fetch dividend data  ${error.message}`)
    }
    setLoading(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


//   const handleDateChange =(e) =>{
//     const selectedYear = e.target.value;
//     setSelectedYear(selectedYear)
//     setCurrentPage(1);
//   }


const handleGeneratedPDF = () => {
  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'mm',
    format: [250,92] // Approximate dimensions of a cheque
  });


  const notoSansFont = 'data:font/truetype;charset=utf-8;base64,<base64-font-data>';

  // Add the custom font
  doc.addFileToVFS("NotoSans.ttf", notoSansFont);
  doc.addFont("NotoSans.ttf", "NotoSans", "normal");
  doc.setFont("NotoSans"); // Use the custom font for text



  // doc.setFontSize(20);
  // doc.text(`Cheque Details`, 12, 12); // Title of PDF
  // let startY = 80;

  chequesList.forEach((cheque, index) => {
    // const yOffset = 80;
    // const yPosition = startY + index * yOffset;

    doc.setFontSize(12);
    doc.text(`A/C Payee only`,44,10, null, 10);
    doc.text(`${cheque.ChequeDate}`,194, 10);   // Date Position
    doc.text(`${cheque.MemberName}`,67, 22);   // Payee Name
    doc.text(`${cheque.AmountInWords} Only`,77,28);   // Amount in Words
    doc.text(`${cheque.TotalAmount}/-`,178,38);  // Amount in Digits

    if (index !== chequesList.length - 1) {
      doc.addPage();
    }
  });

  doc.save(`Cheque_List_${new Date().toLocaleDateString()}.pdf`);
};

const handleSubmit = (values) =>{
  setSelectedDate(values.SelectDate)
}

  return (
    <LayoutContainer  > 
         <Paper elevation={5} style={{padding:'16px', marginBottom:'16px',}} >    
         <Formik
                    initialValues={{SelectDate: ''}}
                    validationSchema={Yup.object().shape({ SelectDate: Yup.date().required('Required'), })}
                    onSubmit={handleSubmit}
                >{
                    ({errors, touched, handleChange, values, onChange, submitForm}) =>(
                        <Form>
                            <Grid container spacing={2} sx={{display:'flex', flexDirection:'row', alignItems:'center', margin:'8px 0px'}}>
                                <Grid item xs={12} md={3}>
                                    <Field 
                                        as={TextField}
                                        name="SelectDate"
                                        label="SelectDate"
                                        type="Date"
                                        value={values.SelectDate}
                                        size="small"
                                        onChange={handleChange}
                                        onBlur ={submitForm}
                                        fullWidth
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                        InputLabelProps= {{shrink: true }}
                                    />   
                                </Grid>       
                            </Grid>
                    </Form>
                    )
                }
                </Formik>
        </Paper>   

    <Paper elevation={5} style={{padding:'16px', marginBottom:'16px'}} >
            {
                loading ? (
                    <LoadingStyle >
                      <CircularProgress />
                    </LoadingStyle>
                ):(
                    <TableStyle>
                    <Box>
                        <ReusableTable data={dailyExpend} columns={columns} />
                    </Box>
                    <Pagenation 
                        currentPage={currentPage} 
                        setCurrentPage={setCurrentPage} 
                        totalRecords={totalRecords} 
                        pageSize={pageSize}
                      />
                    </TableStyle>
                )
            }
    </Paper>
    <Paper elevation={5} style={{padding:'16px', marginBottom:'16px'}}>
         <Formik
                     initialValues={initialValues}
                     validationSchema={validationSchema}
                     onSubmit={(values)=>{console.log(values)}}
                >
                   {({errors, touched, handleChange,values,onChange})=>(
                    <Form>
                         <Grid container spacing={2}>
                            {[
                                    { name: "TransationID", label: "Transaction ID" },
                                    { name: "PaymentMode", label: "PaymentMode" },
                                    { name: "VoucherNo", label: "VoucherNo",  },
                                   ].map(({ name, label, type = "text", component }) => (
                                      <Grid item key={name} xs={3}  style={{display:'flex', flexDirection:'column'}} >
                                          {component ? component : (
                                              <Field
                                                  as={TextField}
                                                  name={name}
                                                  label={label}
                                                  type={type}
                                                  value={values[name]}
                                                  size="small"
                                                  fullWidth
                                                  margin="normal"
                                                  error={touched.name && Boolean(errors.name)}
                                                  helperText={touched.name && errors.name}
                                                  InputLabelProps={type === "date" ? { shrink: true } : {}}
                                              />
                            )}
                            </Grid>
                        ))}
                            <Grid item xs={12} md={12} >
                                <PaymentDetailsSection  errors={errors} touched={touched} values={values} handleChange={onChange} />
                            </Grid>
                            <Grid item xs={12} md={4} >
                            
                            <Button 
                                type="submit" 
                                variant="contained" 
                                color="primary"
                                margin="normal"
                                sx={{marginRight:'8px'}}
                                // onClick={handleGeneratedPDF}
                                >
                                 Finalize 
                                 <DoneIcon margin='normal'/>
                            </Button>

                            <Button 
                                type="submit" 
                                variant="contained" 
                                color='secondary'
                                sx={{marginRight:'8px'}}
                                onClick={handleGeneratedPDF}
                                >
                                 Print
                                 <PrintIcon margin='normal' />
                            </Button>


                            <Button 
                                type="submit" 
                                variant="contained" 
                                color='warning'
                                margin="normal"
                                sx={{marginRight:'8px'}}
                                // onClick={handleGeneratedPDF}
                                >
                                 Cancel  
                                 <CancelIcon margin='normal' />
                            </Button>

                            </Grid>
                            
                         </Grid>
                        </Form>
              )} 
         </Formik>       
        </Paper>
    {error && (
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={()=>setError(null)}
        message={error}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => setError(null)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    )}
    <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton size="small" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
    />
</LayoutContainer>
  )
}

export default DialyExpenditures


