import React, { useState, useEffect } from 'react';
import {
  TextField, Button, Typography, Container, Box, IconButton, CircularProgress, Checkbox
, Paper, Dialog, DialogActions, Grid,DialogContent, DialogContentText, DialogTitle, Snackbar, FormControlLabel
} from '@mui/material';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';

import ReusableTable from './../../../reuseable/ReusableTable';
import RenderFormComponent from '../MemberForm/renderFormComponent';

const LayoutContainer = styled(Container)`  
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content:start;
  max-width: 600px;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  adding: 16px;
  top: 12%;
  left:18%;
  marginTop: theme.spacing(2);
  marginBottom: theme.spacing(2);
  
`;

const CenterStyle = styled(Box)`
    display: flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    width:100%;
    // margin: 8px;
    // padding:8px; 

`;
const Left = styled(Box)`
    display: flex;
    flex-direction:row;
    align-items:center;
    justify-content:start;
    // margin:8px;
`;

const Right = styled(Box)`
  display: flex; 
  justify-content:flex-end;
  align-items:center;
  margin-bottom:0px; 
`;

const LoadingStyle = styled(Box)`
    display:flex ;
    justify-content:center; 
    align-items:center;
     height: 100vh;
`;

const TableStyle = styled(Box)`
  display:flex;
  // height: 100vh;
  flex-direction:column;
  justify-content:start; 
  margin-bottom:0px;
  padding:8px; 
  background-color:'black'
`


const PageingStyle = styled(Box)`
    display:flex;
    flex-direction:row;
    justify-content:end;  
    align-items:center;
    margin:16px 0;
`

const validationSchema = Yup.object().shape({
  MemberID: Yup.number().required('Member ID is required'),
  SNO:Yup.number().required('SNO ID is required'),
  // MemberID: Yup.number().required('Member ID is required'),
  PayStaffNo: Yup.string().required('Pay Staff No is required'),
  HRMSNo: Yup.string().required('HRMS No is required'),
  AppFormNo: Yup.string().required('App Form No is required'),
  Title:Yup.string().required('Title Form No is required'),
  FirstName: Yup.string().required('First Name is required'),
  LastName: Yup.string(),
  FatherName:Yup.string().required('Father Name is required'),
  Unit:Yup.string().required('Unit Name is required'),
  SubUnit:Yup.string().required('SubUnit Name is required'),
  Designation: Yup.string().required('Designation is required'),
  BankName: Yup.string().required('Bank Name is required'),
  CertifiedByBSNL:Yup.boolean(),
  BankAcNo: Yup.string().required('Bank Account Number is required'),
  Thriftfund: Yup.number().required('Thrift Fund is required'),
  EntranceDate:Yup.date().required('Date of Entrance is required'),
  AppointmentDate: Yup.date().required('Appointment Date is required'),
  ResignationDate: Yup.date().required('Resignation Date is required'),
  RetirementDate: Yup.date().required('Retirement Date is required'),
  dateOfBirth: Yup.date().required('Date of Birth is required'),
  EntryDate:Yup.date().required('Entry of Birth is required'),
  UserEntry:Yup.string().required('User Entry  is required'),
  UserCancel:Yup.string().required('User Entry  is required'),
  CancelDate:Yup.date().required('Entry of Birth is required'),  
  UserEdit: Yup.string().required('User Edit Number is required'),
  EditDate: Yup.date(),
  Remarks: Yup.string(),
  CircleName:Yup.string().required('Circle Name  is required'),
  C_add1:Yup.string(),
  C_add2:Yup.string(),
  C_add3:Yup.string(),
  C_City:Yup.string(),
  C_State:Yup.string().required('C state   is required'),
  C_Pin:Yup.string(),
  O_add1:Yup.string().required('office Add  is required'),
  O_add2:Yup.string(),
  O_add3:Yup.string(),
  O_City:Yup.string(),
  O_State:Yup.string().required('officestate   is required'),
  O_Pin:Yup.string(),
  P_add1:Yup.string(),
  P_add2:Yup.string(),
  P_add3:Yup.string(),
  P_City:Yup.string(),
  P_State:Yup.string().required('Parmement state   is required'),
  P_Pin:Yup.string(),
  HomePhone:Yup.number(),
  OfficePhone:Yup.number(),
  MobileNo:Yup.number(),
  EmailID:Yup.string(),
  EmergencyPhone:Yup.number(),
  AddressUserEdit:Yup.string().required('Parmement   Add  is required'),
  NomineeName:Yup.string(),
  Add1:Yup.string(),
  Add2:Yup.string(),
  Add3:Yup.string(),
  City:Yup.string(),
  State:Yup.string(),
  Pin:Yup.string(),
  PhoneNo: Yup.string(),
  Relation:Yup.string(),
  Age: Yup.number(), 
  IsMinor: Yup.boolean().required(),
  DOB:Yup.date().required('Date of Birth is required'),
  Percentage:Yup.number(), 
  G_Name:Yup.string().when('isMinor', {
    is: true,
    then: Yup.string().required('Guardian Relation is required'),
    // otherwise: Yup.string().notRequired(),
  }),
  G_Relation:Yup.string().when('isMinor', {
    is: true,
    then: Yup.string().required('Guardian Name is required'),
    // otherwise: Yup.string().notRequired(),
  }),
  NomineeRemarks:Yup.string(),
  NomineeUserEdit:Yup.number().required('Nominee Remarks is required'), 
  Image: Yup.mixed().test('fileSize', 'File size is too large', value => value && value.size <= 100000), // 100KB limit
 Signature: Yup.mixed().test('fileSize', 'File size is too large', value => value && value.size <= 100000), // 100KB limit
});


const token = localStorage.getItem('token')
const API_BASE_URL ='https://tecslkoupe.com'

const MemberDataGrid = () => {
 
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [MemberID, setMemberID] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [editingMember, setEditingMember] = useState(null);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  
  const [pageSize] = useState(10); 

  

  useEffect(() => { 

    fetchMembers(currentPage);

  }, [currentPage]);

  const fetchMembers = async (page) => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/Admin/Member/Member`,{
        Action: 'GetAll',
        PageIndex: page,
        pageSize
      },{
        headers: {
          Authorization: `Bearer ${token}`,
         'Content-Type': 'application/json'
        }
      });
      // console.log(response.data.List)
      setTotalRecords(response.data.Footer[0].TotalRecord);
      // setPageSize(response.data.Footer[0].pageSize)
      setMembers(response.data.List|| []);

    } catch (error) {
      setError(`Failed to fetch users  ${error.message}`);
    }
    setLoading(false);
  };
  
 
  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/Admin/Member/Member`,{
        Action: 'GetById',
        MemberID: MemberID
      },{
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
    
      setMembers(response.data.List);
      setTotalRecords(response.data.Footer[0].TotalRecord);
    } catch (error) {
      setError(`Error fetching member: ${error.message}`);
    }
    setLoading(false);
  };

  const handleShowAll = () => {
    fetchMembers(currentPage);
  };

  const handleEditClick = (member) => {
    setEditingMember(member);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditingMember(null);
  };


  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };



  const handleSubmit = async (values, setSubmitting, resetForm) => {
    try {
      if (editingMember) {
        await axios.post(`${API_BASE_URL}/Admin/Member/Member/`,{
          
          ...values,
          Action: 'Update'
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        });
        setSnackbarMessage('member updated successfully');
      } else {
        await axios.post(`${API_BASE_URL}/Admin/Member/Member`,{
          ...values,
          Action: 'Insert'
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        });
        setSnackbarMessage('User added successfully');
      }
      fetchMembers(currentPage);
      setSnackbarOpen(true);
      setSubmitting(false);
      handleCloseModal();
      resetForm();
    } catch (error) {
      setError('Failed to add/update user');
      setSubmitting(false);
    }
    };
 

  const columns = [
    { field: 'RowNumber', headerName: 'RowNumber', width: '200px' },
    { field: 'SNO', headerName: 'SNO', width: '200px' },
    { field: 'MemberID', headerName: 'Member ID', width: '200px' },
    { field: 'PayStaffNo', headerName: 'Pay Staff No', width: '200px' },
    { field: 'HRMSNo', headerName: 'HRMS No', width: '200px' },
    { field: 'AppFormNo', headerName: 'App Form No', width: '200px' },
    { field: 'Title', headerName: 'Title', width: '200px' },
    { field: 'FirstName', headerName: 'First Name', width: '200px' },
    { field: 'LastName', headerName: 'Last Name', width: '200px' },
    { field: 'FatherName', headerName: 'Father Name', width: '200px' },
    { field: 'Unit', headerName: 'Unit', width: '200px' },
    { field: 'SubUnit', headerName: 'Sub Unit', width: '200px' },
    { field: 'Designation', headerName: 'Designation', width: '200px' },
    { field: 'BankName', headerName: 'Bank Name', width: '200px' },
    { field: 'CertifiedByBSNL', headerName: 'Certified By BSNL', width: '200px' },
    { field: 'ThriftFund', headerName: 'Thrift Fund', width: '200px' },
    { field: 'EntranceDate', headerName: 'Entrance Date', width: '200px' },
    { field: 'AppointmentDate', headerName: 'Appointment Date', width: '200px' },
    { field: 'RetirementDate', headerName: 'Retirement Date', width: '200px' },
    { field: 'ResignationDate', headerName: 'Bank Account Number', width: '200px' },
    { field: 'DateOfBirth', headerName: 'Date Of Birth', width: '200px' },
    { field: 'EntryDate', headerName: 'Entry Date', width: '200px' },
    { field: 'UserEntry', headerName: 'User Entry', width: '200px' },
    { field: 'UserCancel', headerName: 'User Cancel', width: '200px' },
    { field: 'CancelDate', headerName: 'Cancel Date', width: '200px' },
    { field: 'UserEdit', headerName: 'User Edit', width: '200px' },
    { field: 'EditDate', headerName: 'Edit Date', width: '200px' },
    { field: 'Remarks', headerName: 'Remarks', width: '200px' },
    { field: 'CircleName', headerName: 'Circle Name', width: '200px' },
    { field: 'C_add1', headerName: 'Correspondence Address1', width: '200px' },
    { field: 'C_add2', headerName: 'Correspondence Address2', width: '200px' },
    { field: 'C_add3', headerName: 'Correspondence Address3', width: '200px' },
    { field: 'C_City', headerName: 'Correspondence City', width: '200px' },
    { field: 'C_State', headerName: 'Correspondence State', width: '200px' },
    { field: 'C_Pin', headerName: 'Correspondence Pin', width: '200px' },
    { field: 'O_add1', headerName: 'Office Address1', width: '200px' },
    { field: 'O_add2', headerName: 'Office Address2', width: '200px' },
    { field: 'O_add3', headerName: 'Office Address3', width: '200px' },
    { field: 'O_City', headerName: 'Office City', width: '200px' },
    { field: 'O_State', headerName: 'Office State', width: '200px' },
    { field: 'O_Pin', headerName: 'Office Pin', width: '200px' },
    { field: 'P_add1', headerName: 'Permanent Address1', width: '200px' },
    { field: 'P_add2', headerName: 'Permanent Address2', width: '200px' },
    { field: 'P_add3', headerName: 'Permanent Address3', width: '200px' },
    { field: 'P_City', headerName: 'Permanent City', width: '200px' },
    { field: 'P_State', headerName: 'Permanent State', width: '200px' },
    { field: 'P_Pin', headerName: 'Permanent Pin', width: '200px' },
    { field: 'HomePhone', headerName: 'Home Phone', width: '200px' },
    { field: 'OfficePhone', headerName: 'Office Phone', width: '200px' },
    { field: 'MobileNo', headerName: 'Mobile No', width: '200px' },
    { field: 'EmailID', headerName: 'Email ID', width: '200px' },
    { field: 'EmergencyPhone', headerName: 'Emergency Phone', width: '200px' },
    { field: 'AddressUserEdit', headerName: 'Address User Edit', width: '200px' },
    { field: 'NomineeName', headerName: 'Nominee Name', width: '200px' },
    { field: 'Add1', headerName: 'Address1', width: '200px' },
    { field: 'Add2', headerName: 'Address1', width: '200px' },
    { field: 'Add3', headerName: 'Address1', width: '200px' },
    { field: 'City', headerName: 'City', width: '200px' },
    { field: 'State', headerName: 'State', width: '200px' },
    { field: 'Pin', headerName: 'Pin', width: '200px' },
    { field: 'PhoneNo', headerName: 'PhoneNo', width: '200px' },
    { field: 'Relation', headerName: 'Relation', width: '200px' },
    { field: 'Age', headerName: 'Age', width: '200px' },
    { field: 'IsMinor', headerName: 'IsMinor', width: '200px' },
    { field: 'DOB', headerName: 'DOB', width: '200px' },
    { field: 'Percentage', headerName: 'Percentage', width: '200px' },
    { field: 'G_Name', headerName: 'Gurdian Name', width: '200px' },
    { field: 'G_Relation', headerName: 'Gurdian Relation', width: '200px' },
    { field: 'NomineeRemarks', headerName: 'NomineeRemarks', width: '200px' },
    { field: 'NomineeRemarks', headerName: 'NomineeRemarks', width: '200px' },
    { field: 'Image', headerName: 'Image', width: '200px' },
    { field: 'Signature', headerName: 'Signature', width: '200px' },
    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   width: '200px',
    //   renderCell: (params) => (
    //     <Button variant="contained" color="primary" onClick={() => handleEditClick(params.row)}>
    //       Edit
    //     </Button>
    //   )
    // }
  ];


  

  return(
    <LayoutContainer>
       <Paper elevation={3} style={{padding:'16px', marginBottom:'16px'}} >
       <CenterStyle >
        <Left >
          <TextField
            label="Enter Member ID"
            value={MemberID}
            size="small"
            ml={2}
            onChange={(e) => setMemberID(e.target.value)}
            sx={{margin:'8px'}}
          />
          <Box  > 
            <Button variant="contained" color="primary" onClick={handleSearch} ml={2} disabled={!MemberID}  sx={{margin:'8px'}} >
              Search 
            </Button>      
          </Box>
          <Box >   
            <Button variant="contained" color="secondary"  ml={2} onClick={handleShowAll}  sx={{margin:'8px'}}>
              Show All
            </Button>
          </Box>
        </Left>
        <Right mb={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenModal(true)}
            >
              <AddIcon />
              Add User
            </Button>
        </Right>
      </CenterStyle>
       </Paper>
       <Paper elevation={3} style={{padding:'16px'}}>
        {
          loading ? (
            <LoadingStyle >
              <CircularProgress />
            </LoadingStyle>
          ) :(
            <TableStyle>
              <Box>
                <ReusableTable data={members} columns={columns} onEdit={handleEditClick}/>
              </Box>
      
            <PageingStyle  >
              <Button
                variant="contained"
                onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
            <Typography variant="h6" style={{ margin: '0 16px' }}>
              Page {currentPage} of {Math.ceil(totalRecords / pageSize)}
            </Typography>
            <Button
              variant="contained"
              onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
              disabled={currentPage === Math.ceil(totalRecords / pageSize)}
            >
              Next
            </Button>
            </PageingStyle>
            </TableStyle>

          )
        }
      </Paper>
      <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{editingMember ? 'Edit Member' : 'Add Member'}</DialogTitle>
        <DialogContent  
        >
          <DialogContentText>
            {editingMember ? 'Update the user information below' : 'Fill in the information below to add a new user'}
          </DialogContentText>
          <Formik
            initialValues={editingMember || {
              MemberID:'',
              SNO:'',
              PayStaffNo:'',
              HRMSNo: '',
              AppFormNo: '',
              Title:'',
              FirstName: '',
              LastName: '',
              FatherName:'',
              Unit:'',
              SubUnit:'',
              Designation: '',
              BankName: '',
              CertifiedByBSNL:false,
              BankAcNo:'',
              Thriftfund: '',
              EntranceDate:'',
              AppointmentDate: '',
              ResignationDate:'',
              RetirementDate: '',
              dateOfBirth: '',
              EntryDate:'',
              UserEntry:'',
              UserCancel:'',
              CancelDate:'',  
              UserEdit: '',
              EditDate: '',
              Remarks: '',
              CircleName:'',
              C_add1:'',
              C_add2:'',
              C_add3:'',
              C_City:'',
              C_State:'',
              C_Pin:'',
              O_add1:'',
              O_add2:'',
              O_add3:'',
              O_City:'',
              O_State:'',
              O_Pin:'',
              P_add1:'',
              P_add2:'',
              P_add3:'',
              P_City:'',
              P_State:'',
              P_Pin:'',
              HomePhone:'',
              OfficePhone:'',
              MobileNo:'',
              EmailID:'',
              EmergencyPhone:'',
              AddressUserEdit:'',
              NomineeName:'',
              Add1:'',
              Add2:'',
              Add3:'',
              City:'',
              State:'',
              Pin:'',
              PhoneNo:'',
              Relation:'',
              Age:'', 
              IsMinor: false,
              DOB:'',
              Percentage:'', 
              G_Name:'',  
              G_Relation:'',
              NomineeRemarks:'',
              NomineeUserEdit:'', 
              Image: null,
              Signature: null,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, values, handleChange, setFieldValue, handleBlur, errors, touched }) => 
              <RenderFormComponent 
                  isSubmitting={isSubmitting} 
                  values={values} 
                  handleChange={handleChange}  
                  handleBlur={handleBlur} 
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue} /> 
            }
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseModal}
            color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

    {error && (

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={()=>setError(null)}
        message={error}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => setError(null)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    )}

    <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton size="small" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />

    </LayoutContainer>
  )

}

export default MemberDataGrid;
