import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const token = localStorage.getItem('token')
const API_URL ='https://tecslkoupe.com';

export const fetchUnits = createAsyncThunk('cheque/fetchUnit', async() =>{
    const response = await axios.post(
        `${API_URL}/Admin/Setup/Unit`,
        {
            Action:'GetAll', 
            pageIndex:1,
            pageSize:10
        },{
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
    );
    return response.data.List
})

const chequeSlice = createSlice({
    name:'cheque',
    initialState:{
        units:[],
        chequeDeetails:{},
        loading:false,
        error:null,
    }, 
    reducers:{
        setChequeDetails:(state, action) =>{
            state.chequeDeetails = action.payload;
        },
    },
    extraReducers:(builder) =>{
        builder.addCase(fetchUnits.pending, (state) =>{
            state.loading = true;
        })
        .addCase(fetchUnits.fulfilled, (state, action) =>{
            state.loading = false;
            state.units = action.payload;
        })
        .addCase(fetchUnits.rejected, (state, action) =>{
            state.loading = false;
            state.error = action.error.message
        });
    },
});

export const {setChequeDetails }= chequeSlice.actions;
export default chequeSlice.reducer;