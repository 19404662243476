import React from 'react';
import {
    TextField, Paper, Grid,Button, Typography
  } from '@mui/material';
  
import { Formik, Form, Field, ErrorMessage } from 'formik';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import ReusableTable from '../../reuseable/ReusableTable';
import { useState } from 'react';


const columns = [
    { field: 'SNO', headerName: 'SNO', width: '130px' },
    { field: 'Transaction Type', headerName: 'Transaction Type', width: '210px' },
    { field: 'Date', headerName: 'Date', width: '210px'},
    { field: 'Received', headerName: 'Received', width: '150px' },
    { field: 'Payments', headerName: 'Payments', width: '150px' },
    { field: 'ListNo', headerName: 'List No', width:'150px'},
    { field: 'Month', headerName: 'Month', width: '130px' },
    { field: 'Year', headerName: 'Year', width: '150px' },
    { field: 'VoucherNo', headerName: 'Voucher No', width: '150px' },
]

const LoanLedger = ({ values, handleChange, errors, touched }) => {
    const [data, setData] = useState([])

    return(
        <>
        <Typography variant='h6' >Loan Ledger</Typography>
        <ReusableTable
                columns={columns}
                data ={data}
        />
        <Grid container spacing={2}  alignItems="center">
        <Grid item xs={12} md={3}>
            <Field
                as={TextField}
                name="amountToTransfer"
                label="Amount to Transfer"
                variant="outlined"
                fullWidth
                size="small"
                sx={{margin:'8px'}}
                error={touched.AmountToTransfer && Boolean(errors.AmountToTransfer)}
                helperText={touched.AmountToTransfer && errors.AmountToTransfer}
            />
        </Grid>
        <Grid item xs={12} md={3}>
            <Field
            as={TextField}
            name="transferDate"
            label="Transfer Date"
            type="date"
            variant="outlined"
            fullWidth
            size="small"
            sx={{margin:'8px'}}
            InputLabelProps={{
                shrink: true,
              }}
            error={touched.TransferDate && Boolean(errors.TransferDate)}
            helperText={touched.TransferDate && errors.TransferDate}
            />
        </Grid>
        <Grid item xs={12} md={3}>
            <Button
            variant="contained"
            color="primary"
            fullWidth
            size="small"
            style={{ height: '100%', margin:'8px' }}
            >
                Transfer To Principal
            </Button>
        </Grid>
        </Grid>
       
        </>
    )
}
  ;

export default LoanLedger