import React,{useEffect, useState,  useMemo, useCallback } from 'react';
import { Grid, Box, TextField,Select, MenuItem, FormControl,Button, Typography, Stack ,InputLabel,IconButton ,Container,Snackbar,CircularProgress ,Paper} from '@mui/material';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import ReusableTable from './../../reuseable/ReusableTable';
import SelectDropdown from '../../reuseable/CustomDropDown';
import axios from 'axios';
import { LayoutContainer, LoadingStyle, PageingStyle, TableStyle } from '../../styles/globalStyle';
import Pagenation from '../../reuseable/pagenation';
import jsPDF from 'jspdf';

const validationSchema = Yup.object().shape({
  MinmumAmount:Yup.number().required('Required'),
  SelectPayMode: Yup.number().required('Required'),
  SelectAccount: Yup.number().required('Required'),
  EnterStartingMemberID: Yup.date().required('Required'),
  EnterEndMemberID: Yup.number().required('Required'),
  EnterStartingChequeNo: Yup.number().required('Required'),
  EnterEndChequeNo: Yup.number().required('Required'),
  SelectPaymentDate: Yup.date().required('Required'),
  SelectChequeDate :Yup.date().required('Required'),
})

const initialValues = {
  // MinmumAmount:'',
  SelectPayMode: '',
  SelectAccount: '',
  EnterStartingMemberID: '',
  EnterEndMemberID:'',
  EnterStartingChequeNo: '',
  EnterEndChequeNo: '',
  SelectPaymentDate: '',     
  SelectChequeDate: ''  
}

  const API_URL = 'https://tecslkoupe.com';
  const token = localStorage.getItem('token');

const GroupDividentTfIntersetPaymentAndChequePayment = () => {
  
  const columns =useMemo(() =>
    [
      { field: 'RowNumber', headerName: 'R No', width: '100px' },
      { field: 'SNO', headerName: 'S No', width: '100px' },
      { field: 'AppFormNo', headerName: 'App Form No', width: '100px' },
      { field: 'FirstName', headerName: 'FirstName', width: '150px' },
      { field: 'LastName', headerName: 'LastName', width: '150px' },
      { field: 'HRMSNo', headerName: 'HRMS No', width: '100px' },
      { field: 'MemberID', headerName: 'Member ID', width: '100px' },
      { field: 'Unit', headerName: 'Unit', width: '100px' },
      { field: 'SubUnit', headerName: 'Sub Unit', width: '100px' },
      { field: 'Total', headerName: 'Total', width: '100px' },
      { field: 'ChequeNo', headerName: 'Cheque/DD No', width: '100px' },  
    ],[]) 
  
  const [actionType, setActionType] = useState('GetActiveMembers')
  const [membersData, setMembersData] = useState([]);
  const [membersId, setMembersId] = useState([]);
  const [selectMemberId, setSelectMemberId] = useState(null);
  const [FinacYear, setFinacYear] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [members] = useState(['CurrentMember', 'ResignedMembers']);
  const [selectedMember, setSelectedMember] = useState('');
  const [unit, setUnit] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [appFormNo, setAppFormNo] = useState([]);
  const [selectedAppFormNo, setSelectedAppFormNo] = useState(null);
  const [account, setAccount] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [payMode, setPayMode] = useState([]);
  const [selectedPayMode, setSelectedPaymode] = useState('');
  const [minAmount, setMinAmount] = useState(null);
  const [chequesList, setChequesList] = useState([])
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [FinancialYear, setFinancialYear] = useState('')
  // const [selectChequeDate, setSelectChequeDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectChequeDate, setSelectChequeDate] = useState('');
  const [pageSize] = useState(10); 

  

 const fetchInitialData = useCallback(async () => {
  setLoading(true);
  setSnackbarOpen(true)
  try {
    const [unitResponse, appFormResponse, accountResponse, payModeResponse, memberIdResponse, yearResponse ] = await Promise.all([
      axios.post(`${API_URL}/Admin/Payments/GroupDividendAndTFInt`, { Action: 'GetUnit' }, { headers: { Authorization: `Bearer ${token}` } }),
      axios.post(`${API_URL}/Admin/Payments/GroupDividendAndTFInt`, { Action: 'GetAppFormNo' }, { headers: { Authorization: `Bearer ${token}` } }),
      axios.post(`${API_URL}/Admin/Payments/GroupDividendAndTFInt`, { Action: 'GetAccount' }, { headers: { Authorization: `Bearer ${token}` } }),
      axios.post(`${API_URL}/Admin/Payments/GroupDividendAndTFInt`, { Action: 'GetPayMode' }, { headers: { Authorization: `Bearer ${token}` } }),
      axios.post(`${API_URL}/Admin/Payments/GroupDividendAndTFInt`, { Action: 'GetMemberID' }, { headers: { Authorization: `Bearer ${token}` } }),
      axios.post(`${API_URL}/Admin/Account/FinalizeDividen`, { Action: 'GetFinancialYear' }, { headers: { Authorization: `Bearer ${token}` } }),
    ]);
    setUnit(unitResponse.data.List || []);
    setAppFormNo(appFormResponse.data.List || []);
    setAccount(accountResponse.data.List || []);
    setPayMode(payModeResponse.data.List || []);
    setMembersId(memberIdResponse.data.List || []);
    setFinacYear(yearResponse.data.List || []);
    setTotalRecords(unitResponse.data.Footer[0]?.TotalRecord || 0);
  } catch (error) {
    console.error('Failed to fetch initial data', error);
  }
  setLoading(false);

 }, [ ]);

 const fetchMembers = useCallback(async (action, page = 1, filters = {}) => {
  setLoading(true);
  try {
    const response = await axios.post(
      `${API_URL}/Admin/Payments/GroupDividendAndTFInt`,
      {
        Action: action,
        Year: filters.selectedYear || 2223,
        Unit: filters.selectedUnit,
        AppFormNo: filters.selectedAppFormNo || 0,
        MinimumAmount: filters.MinmumAmount || 100,
        PageIndex: page,
        pageSize,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setMembersData(response.data.List || []);
    setTotalRecords(response.data.Footer[0]?.TotalRecord || 0);
  } catch (error) {
    console.error('Failed to fetch members', error);
  }
  setLoading(false);
}, [pageSize]);

useEffect(() => {
  fetchInitialData();
}, [fetchInitialData]);

useEffect(() => {
  if (selectedUnit ) {
    fetchMembers(actionType, currentPage, { selectedUnit, selectedAppFormNo, minAmount, selectedYear });
  }
}, [selectedUnit, selectedAppFormNo, actionType, currentPage, selectedYear, fetchMembers, minAmount]);


const fetchGenerateCheque = async (values) => {
  try {
    const response = await axios.post(
      `${API_URL}/Admin/Payments/GroupDividendAndTFInt`,
      {
        Action: 'GenerateCheque',
        Year: values.selectedYear,
        Unit: values.selectedUnit,
        AppFormNo: values.selectedAppFormNo || 0,
        SelectMembers: values.selectedMember,
        MinimumAmount: values.minAmount || 100,
        ChequeDate: selectChequeDate || selectChequeDate,
        MemberID: values.MemberID || 0,  // Ensure this is passed correctly
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    setChequesList(response.data.List || []);
    setSnackbarMessage('Cheques generated successfully.');
  } catch (error) {
    setError(`Failed to fetch cheques: ${error.message}`);
  }
};


const handleSubmitGenerateCheque = (values) => {
  console.log("Form Submitted with values: ", values);
  fetchGenerateCheque({
    selectedYear: values.selectedYear || selectedYear,
    selectedUnit: values.selectedUnit || selectedUnit,
    selectedAppFormNo: values.selectedAppFormNo || selectedAppFormNo,
    selectedMember: values.selectedMember || actionType,
    minAmount: values.MinmumAmount || minAmount,
    // selectChequeDate: selectChequeDate || selectChequeDate,
      SelectChequeDate: values.SelectChequeDate, 
    MemberID: values.MembersId || 0, // Ensure MemberID is passed
  });
};


// Handle form submission and API call
const fetchMarkPaid = async (values) => {
  console.log('call MarkPaid Api');
  setLoading(true);
  setSnackbarOpen(true);
  try {
      const response = await axios.post(
          `${API_URL}/Admin/Payments/GroupDividendAndTFInt`,
          {
              Action: 'MarkPaid',
              Year: values.selectedYear,             // Year
              Unit: values.selectedUnit,             // Unit
              AppFormNo: values.selectedAppFormNo,   // AppFormNo
              SelectMembers: values.selectedMember,  // Members Type: CurrentMembers or ResignedMembers
              MinimumAmount: values.minAmount,       // Minimum Amount
              PaymentDate: values.selectChequeDate,  // Payment Date
              StartMemberID: values.StartMemberID,   // Starting Member ID
              EndMemberID: values.EndMemberID,       // End Member ID
              StartChequeNo: values.StartChequeNo,   // Starting Cheque No
              EndChequeNo: values.EndChequeNo,       // End Cheque No
              PayMode: values.SelectPayMode,         // Payment Mode
              Account: values.SelectAccount,         // Account
          },
          { headers: { Authorization: `Bearer ${token}` } }
      );
      setSnackbarMessage(response.data.message || 'Successfully Updated');
  } catch (error) {
      setSnackbarMessage(`Error: ${error.message}`);
  }
  setLoading(false);
  setSnackbarOpen(true);
};


const handleSubmitMarkPaid = (values) => {
  fetchMarkPaid({
      selectedYear: values.SelectYear || selectedYear,
      selectedUnit: values.SelectUnit || selectedUnit,
      selectedAppFormNo: values.SelectAppFormNo || selectedAppFormNo,
      selectedMember: actionType,  // CurrentMembers or ResignedMembers based on button clicked
      minAmount: values.MinmumAmount || minAmount,
      selectChequeDate: values.SelectPaymentDate,
      StartMemberID: values.EnterStartingMemberID,
      EndMemberID: values.EnterEndMemberID,
      StartChequeNo: values.EnterStartingChequeNo,
      EndChequeNo: values.EnterEndChequeNo,
      SelectPayMode: values.SelectPayMode,
      SelectAccount: values.SelectAccount,
  });
  console.log('handle markPaid call');
};


// Handle form submission
const handleSubmit = (values) => {
  setMinAmount(values.MinmumAmount); // Set the submitted value to the state
};

const handleActionTypeChange = (type) => {
  setActionType(type);
  
};


    const handleSnackbarClose = () => {
      setSnackbarOpen(false);
    };
  
 
  const memberhandler =(e)=>{
    const selectedMemberID = e.target.value;
    setSelectMemberId(selectedMemberID );
    setCurrentPage(1);   
}

  const finYearhandler =(e)=>{
    const selectedYear = e.target.value;
    setSelectedYear(selectedYear)
    
}

  const accounthandler =(e)=>{
      const selectedAccount = e.target.value;
      setSelectedAccount(selectedAccount)
      setCurrentPage(1);
  }

  const payModehandler =(e)=>{
      const selectedPaymode = e.target.value;
      setSelectedPaymode(selectedPaymode)
      setCurrentPage(1);
  }  

  const handleGeneratedPDF = () => {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: [216, 89] // Approximate dimensions of a cheque
    });

    doc.setFontSize(20);
    doc.text(`Cheque Details`, 12, 12); // Title of PDF
    // let startY = 80;

    chequesList.forEach((cheque, index) => {
      // const yOffset = 40;
      // const yPosition = startY + index * yOffset;

      doc.setFontSize(12);
      doc.text(`${cheque.ChequeDate}`, 180, 15);   // Date Position
      doc.text(`${cheque.MemberName}`, 20, 40);   // Payee Name
      doc.text(`${cheque.AmountInWords}`, 30, 50);   // Amount in Words
      doc.text(`${cheque.TotalAmount}`, 170, 60);  // Amount in Digits
      if (index !== chequesList.length - 1) {
        doc.addPage();
      }
    });
    doc.save(`Cheque_List_${new Date().toLocaleDateString()}.doc`);
  };

  const handleSubmitDate = (values) => {
    // Access the form value and update the state
    setSelectChequeDate(values.SelectChequeDate);
    console.log("Submitted date: ", values.SelectChequeDate); // Log the selected date
  };


  
  return (
    <LayoutContainer>
      {/* Active and Resigned Buttons */}
        <Paper elevation={5}  style={{padding:'16px', marginBottom:'16px'}}>  
        <Grid container spacing={2}>
           <Grid item xs={12} md={4}>
                    <SelectDropdown 
                            label="Select Year"
                            name="SelectYear"
                            value={selectedYear}
                            options={FinacYear.map(f => f.Year)}
                            onChange={finYearhandler}          
                     />
           </Grid>       
           <Grid  item xs={12} md={8} >
              <Button
                    variant='outlined'
                    color={actionType === 'GetActiveMembers' ? 'success' : 'primary'}
                    margin="normal"
                    onClick={() => handleActionTypeChange('GetActiveMembers')}
                    sx={{marginRight:'16px'}}
                >Active Members</Button>

                <Button
                    variant='outlined'
                    color={actionType === 'GetResignMembers' ? 'success' : 'primary'}
                    margin="normal"
                    sx={{marginRight:'16px'}}
                    onClick={() => handleActionTypeChange('GetResignMembers')}
                >Resigned Members</Button>

           </Grid>
       </Grid>
            
     
        </Paper>
             {/* Filters */}
        <Paper elevation={5}  style={{padding:'16px', marginBottom:'16px'}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <SelectDropdown 
                            label="Select Unit"
                            name="SelectUnit"
                            value={selectedUnit}
                            options={unit.map(u => u.Unit)}
                            onChange={(e) => setSelectedUnit(e.target.value)}      
                    />
                 </Grid>
                <Grid item xs={12} md={3}>
                <SelectDropdown 
                           label="Select App Form No"
                           name="SelectAppFormNo"
                           value={selectedAppFormNo}
                           options={appFormNo.map(a => a.AppFormNo)}
                           onChange={(e) => setSelectedAppFormNo(e.target.value)}       
                    />
                </Grid>           
                <Grid item xs={12} md={3}>
                    <Formik
                        initialValues={{MinmumAmount:''}}
                        validationSchema={Yup.object().shape({ MinmumAmount: Yup.number().min(0) })}
                        onSubmit={handleSubmit}
                    >
                        {({errors, touched, handleChange,values,submitForm})=>(
                            <Form>
                             <Field
                             as={TextField}
                             name='MinmumAmount'
                             label="Minmum Amount"
                             type='number'
                             value={values.MinmumAmount}
                             size="small"
                             onChange={handleChange}
                             onBlur={submitForm}
                             fullWidth
                             error={touched.MinmumAmount&& Boolean(errors.MinmumAmount)}
                             helperText={touched.MinmumAmount && errors.MinmumAmount} 
                            />
                        </Form>
                        )}
                    </Formik>
                </Grid>          
            </Grid>   
        </Paper>
        
        <Paper elevation={5} style={{padding:'16px', marginBottom:'16px'}} >
          
          <Box>         
            {
                loading ? (
                    <LoadingStyle >
                      <CircularProgress />
                    </LoadingStyle>
                ):(
                    <TableStyle>
                    <Box>
                        <ReusableTable data={membersData} columns={columns} />
                    </Box>
                   
                       <Pagenation 
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalRecords={totalRecords}
                                pageSize={pageSize} 
                         />
                    </TableStyle>
                )
            }
            </Box>
            <Box>
              <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    // initialValues={{ SelectChequeDate:selectChequeDate, MembersId: '' }}
                    // validationSchema={Yup.object().shape({  SelectChequeDate :Yup.date().required('Required'), MembersId :Yup.number().required('Required'), })}
                    onSubmit={handleSubmitGenerateCheque}
                >{
                    ({errors, touched, handleChange,handleSubmit,values,onChange,submitForm, setFieldValue}) =>(
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={2} sx={{display:'flex', flexDirection:'row', alignItems:'center', margin:'8px 0px'}}>
                            {[
                                    { name: "SelectChequeDate", label: "Select Cheque Date", type:'date' },
                                    { name: "MembersId", label: "Members Id" },
                                   ].map(({ name, label, type = "text", component }) => (
                                      <Grid item key={name} xs={2}  style={{display:'flex', flexDirection:'column'}} >
                                          {component ? component : (
                                              <Field
                                                  as={TextField}
                                                  name={name}
                                                  label={label}
                                                  type={type}
                                                  value={values[name]}
                                                  size="small"
                                                  fullWidth
                                                  // handleChange={onChange}
                                                  onChange={(e) => {
                                                    setFieldValue(name, e.target.value); // Set the selected value
                                                  }}
                                                  // onBlur={submitForm}
                                                  margin="normal"
                                                  error={touched.name && Boolean(errors.name)}
                                                  helperText={touched.name && errors.name}
                                                  InputLabelProps={type === "date" ? { shrink: true } : {}}
                                              />
                                       )}
                                       </Grid>
                                    ))}
                                {/* <Grid item xs={12} md={3}>
                                    <Field 
                                        as={TextField}
                                        name="SelectChequeDate"
                                        label="Select Cheque Date"
                                        type="Date"
                                        value={values.SelectChequeDate}
                                        size="small"
                                        fullWidth
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                        InputLabelProps= {{shrink: true }}
                                    />
                                </Grid> */}
                                {/* <Grid item xs={12} md={4}>
                                  <SelectDropdown 
                                          label={`Member Id `}
                                          name={`MemberId`}
                                          value={selectMemberId}
                                          options={membersId.map(m =>m.MemberID)}
                                          onChange={memberhandler}           
                                    />
                                </Grid> */}
                                <Grid item xs={12} md={3}>
                                    <Button 
                                        type="submit" 
                                        variant="contained" 
                                        color="primary"
                                        // onClick={handleGeneratedPDF }
                                        >
                                        Generate Cheques 
                                    </Button>
                                </Grid>
                            </Grid>
                      </Form>
                    )
                }
              </Formik>
            </Box>
            {chequesList.length > 0 && (
        <Button
          onClick={handleGeneratedPDF}
          variant="contained"
          color="secondary"
        >
          Generate PDF
        </Button>
      )}
    </Paper>
    <Paper elevation={5}  style={{padding:'16px', marginBottom:'16px'}}>   
            <Typography variant='h6' sx={{marginBottom:'8px'}}>Payment</Typography>
            <Formik
                initialValues={{ SelectPayMode: '',SelectAccount: '', EnterStartingMemberID: '', EnterEndMemberID:'',  EnterStartingChequeNo: '',EnterEndChequeNo: '',SelectPaymentDate: '',}}
                validationSchema={Yup.object().shape({  SelectChequeDate :Yup.date().required('Required'), SelectAccount :Yup.number().required('Required'), EnterStartingMemberID: Yup.date().required('Required'),EnterEndMemberID: Yup.number().required('Required'), EnterStartingChequeNo: Yup.number().required('Required'),  EnterEndChequeNo: Yup.number().required('Required'),SelectPayMode: Yup.number().required('Required'), })}
                onSubmit={handleSubmitMarkPaid}
            >
                {
                   ({errors, touched, handleChange,values,onChange})=>(
                    <Form >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                        
                            <SelectDropdown 
                                    label={`Select Pay Mode`}
                                    name={`SelectPayMode`}
                                    value={selectedPayMode}
                                    options={payMode.map(a =>a.PayMode)}
                                    onChange={payModehandler}           
                                />
                        </Grid>
                        <Grid item xs={12} md={4}>
                                <SelectDropdown 
                                label={`Select Account`}
                                name={`SelectAccount`}
                                value={selectedAccount}
                                options={account.map(a =>a.Account)}
                                onChange={accounthandler}           
                              />
                        </Grid>                        
                        </Grid>
                        <Grid container spacing={2}  >
                        {[
                                    { name: "EnterStartingMemberID", label: "Enter Starting MemberID" },
                                    { name: "EnterEndMemberID", label: "Enter End MemberID" },
                                    { name: "EnterStartingChequeNo", label: "Enter Starting ChequeNo",  },
                                    { name: "EnterEndChequeNo", label: "Amount Sanctioned" },
                                    { name: "SelectPaymemntDate", label: "Select Paymemnt Date", type:"date" },
                                   ].map(({ name, label, type = "text", component }) => (
                                      <Grid item key={name} xs={2}  style={{display:'flex', flexDirection:'column'}} >
                                          {component ? component : (
                                              <Field
                                                  as={TextField}
                                                  name={name}
                                                  label={label}
                                                  type={type}
                                                  value={values[name]}
                                                  size="small"
                                                  fullWidth
                                                  margin="normal"
                                                  error={touched.name && Boolean(errors.name)}
                                                  helperText={touched.name && errors.name}
                                                  InputLabelProps={type === "date" ? { shrink: true } : {}}
                                              />
                                )}
                       </Grid>
                   ))}
                    </Grid>
                        <Grid item xs={12}>
                            <Button 
                                type="submit" 
                                variant="contained" 
                                color="primary"
                                // onClick={handleSubmitMarkPaid}
                                >
                               {loading ? 'Submitting...' : 'Mark Paid'}
                            </Button>
                        </Grid>
                    
                    </Form>
                   ) }
            </Formik>
        </Paper>
    {error && (
      <Snackbar
        open={!!error}
        autoHideDuration={2000}
        onClose={()=>setError(null)}
        message={error}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => setError(null)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    )}
    <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton size="small" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
    />
    </LayoutContainer>
  )
}

export default GroupDividentTfIntersetPaymentAndChequePayment;