import React from 'react';
import { Box, Grid, Paper, TextField, Select, MenuItem, FormControlLabel, Checkbox, FormControl, InputLabel, Typography } from '@mui/material';
import { Formik, Field , Form } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import DebitCreditTable from '../../../reuseable/DebitCreditTable';


const DropDownContainer = styled(Grid)`
  display: flex;
  flex-direction:row;
  align-items:center;

 `
 const TableName = styled(Typography)`
  margin: 1px;
  padding: 1px;
  display: flex;
  justify-content: start;
`;

const FormContainer = styled(Paper)`
  padding: 16px;
  border-radius: 10px;
  margin-bottom: 16px;
`;

const columns = [
    { id: 'sno', label: 'SNO' ,name: 'SNO' },
    { id: 'id', label: 'ID', name: 'ID' },
    { id: 'account', label: 'Account', name: 'Account' },
    { id: 'amount', label: 'Amount',  name: 'Amount' },
    { id: 'id', label: 'ID', name:'ID' },
  ];
  


const MemberSection = ({ values, handleChange, touched, errors}) => {
  return (
    <Form>
   <FormContainer elevation={3}>
        <DropDownContainer container spacing={2} direction="row" >
        <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel field="Debit">Debit</InputLabel>
                    <Field
                        as={Select}
                        name="Debit"
                        label="Debit"
                        value={values.Debit}
                        onChange={handleChange}
                    >
                <   MenuItem value={values.Debit}>{values.Debit}</MenuItem>
              </Field>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <Field
              as={TextField}
              name="AccountName"
              label="Account Name"
              variant="outlined"
              value={values.AccountName}
              onChange={handleChange}
              fullWidth
            //   margin="normal"
              error={touched.AccountName && Boolean(errors.AccountName)}
              helperText={touched.AccountName && errors.AccountName}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Id/Balance</InputLabel>
              <Field
                as={Select}
                name="MemberId"
                label="MemberId"
                value={values.MemberId}
                onChange={handleChange}
              >
                <MenuItem value={values.MemberId}>{values.MemberId}</MenuItem>
              </Field>
            </FormControl>
          </Grid>
        </DropDownContainer>
        <DropDownContainer container spacing={2}>

        <Grid item xs={12} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  name="chequeReversal"
                  onChange={handleChange}
                  checked={values.chequeReversal}
                />
              }
              label="Cheque Reversal"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Field
                    as={TextField}
                    id=""
                    type="text"
                    value={values.AccountName}
                    label="Account Name"
                    variant="outlined"
                    onChange={handleChange}
                    required
                    margin="normal"
                    fullWidth  
                    error={touched.AccountName && Boolean(errors.AccountName)}
                    helperText={touched.AccountName && errors.AccountName}
                          
                />
        </Grid>
        </DropDownContainer>
        <Grid item   xs={12} md={4}>
            {/* <TableName component="h4">Credit Heads</TableName>
            <DebitCreditTable name="creditHeads" title="Credit Heads" columns={columns} /> */}
        </Grid>
    </FormContainer >
    </Form>
  )
}

export default MemberSection
