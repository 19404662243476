import { Card, Grid,CardContent, FormControl, InputLabel, MenuItem, Select,TextField,Box,Button,IconButton ,Typography ,Snackbar,CircularProgress ,Paper } from '@mui/material'
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import React, {useState} from 'react'
import { DropDownContainer } from '../../styles/globalStyle'
import SelectDropdown from '../../reuseable/CustomDropDown'

const PaymentDetailsSection = () => {
  const [paymentMode, setPayMentMode] = useState([]);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState('');

  const [bankName, setBankName] = useState([]);
  const [selectedBankName, setSelectedBankName] = useState('');

    // Handlers for dropdowns
    const handlerBank =(e)=>{
      const selectedBank = e.target.value;
      setSelectedBankName(selectedBank)
     
  }
  const handlerPayment =(e)=>{
      const selectedPaymet = e.target.value;
      setSelectedPaymentMode(selectedPaymet)
  }

const handleSubmit =()=>{

}



  return (
    <Grid sx={{ border:'2px solid lightgray', padding:'8px'}} Paper elevation={5}>
      <CardContent>
        <Typography variant='h6' >Payment Details</Typography>
        </CardContent>
          <Grid container spacing={2} direction="row" >
                    <Grid item xs={12} md={4}>      
                            <SelectDropdown 
                                    label={`Payment Mode`}
                                    name={`paymentMode`}
                                    value={selectedPaymentMode}
                                    options={paymentMode.map(a =>a.PayMode)}
                                    onChange={paymentMode}           
                                />
                        </Grid>
                        <Grid item xs={12} md={3}>
                    <Formik
                        initialValues={{MinmumAmount:''}}
                        validationSchema={Yup.object().shape({ MinmumAmount: Yup.number().min(0) })}
                        onSubmit={handleSubmit}
                    >
                        {({errors, touched, handleChange,values})=>(
                            <Form>
                             <Field
                             as={TextField}
                             name='MinmumAmount'
                             label="Minmum Amount"
                             type='number'
                             value={values.MinmumAmount}
                             size="small"
                             onChange={handleChange}
                            //  onBlur={submitForm}
                             fullWidth
                             error={touched.MinmumAmount&& Boolean(errors.MinmumAmount)}
                             helperText={touched.MinmumAmount && errors.MinmumAmount} 
                            />
                        </Form>
                        )}
                    </Formik>
                </Grid> 
          <Grid item xs={12} md={4}>
                    <SelectDropdown 
                                    label={`Payment Mode`}
                                    name={`paymentMode`}
                                    value={selectedPaymentMode}
                                    options={paymentMode.map(a =>a.PayMode)}
                                    onChange={paymentMode}           
                      />
          </Grid>
          </Grid>
    </Grid>
  )
}

export default PaymentDetailsSection
