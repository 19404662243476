import React from 'react';
import {
    TextField, Paper, Grid,Button, Typography
  } from '@mui/material';
  
import { Formik, Form, Field, ErrorMessage } from 'formik';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import ReusableTable from '../../../reuseable/ReusableTable';
import { useState } from 'react';


const columns = [
    { field: 'SNO', headerName: 'SNO', width: '130px' },
    { field: 'Transaction Type', headerName: 'Transaction Type', width: '210px' },
    { field: 'Month', headerName: 'Month', width: '130px' },
    { field: 'Year', headerName: 'Year', width: '150px' },
    { field: 'Payment', headerName: 'Payment', width: '150px' },
    { field: 'Received', headerName: 'Received', width: '150px' },
    { field: 'Balance', headerName: 'Balance', width: '150px' },
    { field: 'TDate', headerName: 'TDate', width: '150px' },
    { field: 'ListNo', headerName: 'List No', width:'150px'},
    { field: 'ListName', headerName: 'ListName', width:'150px' },
]

const OtherDepositsTable = ({ values, handleChange, errors, touched }) => {
    const [data, setData] = useState([])

    return(
        <>
        <Typography variant='h6' >Other Deposits </Typography>
        <Grid container spacing={2}  alignItems="center">
        {/* <Grid item xs={12} md={3}>
            <Field
            as={TextField}
            name="difOpening"
            label="DIF Opening"
            variant="outlined"
            fullWidth
            size="small"
            sx={{margin:'8px'}}
            error={touched.difOpening && Boolean(errors.difOpening)}
            helperText={touched.difOpening && errors.difOpening}
            />
        </Grid> */}
        {/* <Grid item xs={12} md={3}>
            <Field
            as={TextField}
            name="difBalance"
            label="DIF Balance"
            variant="outlined"
            fullWidth
            size="small"
            sx={{margin:'8px'}}
            error={touched.difBalance && Boolean(errors.difBalance)}
            helperText={touched.difBalance && errors.difBalance}
            />
        </Grid> */}
        <Grid item xs={12} md={3}>
            <Button
            variant="contained"
            color="primary"
            fullWidth
            size="small"
            style={{ height: '100%', margin:'8px' }}
            >
            Change List No
            </Button>
        </Grid>
        </Grid>
        <ReusableTable
                columns={columns}
                data ={data}
        />
        </>
    )
}
  ;

export default OtherDepositsTable