import React from 'react';
import {
    TextField, Paper, Grid,Button, Typography
  } from '@mui/material';
  
import { Formik, Form, Field, ErrorMessage } from 'formik';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import ReusableTable from '../../reuseable/ReusableTable';
import { useState } from 'react';


const columns = [
    { field: 'SNO', headerName: 'SNO', width: '130px' },
    { field: 'Transaction Type', headerName: 'Transaction Type', width: '210px' },
    { field: 'Date', headerName: 'Date', width: '150px' },
    { field: 'Amount', headerName: 'Amount', width: '130px' },
    { field: 'VoucherNo', headerName: 'Voucher No', width: '150px' },
    { field: 'Adjusted', headerName: 'Adjuested', width: '150px' },
    { field: 'Date', headerName: 'Date', width: '150px' },
]

const ExcessInterest = ({ values, handleChange, errors, touched }) => {
    const [data, setData] = useState([])

    return(
        <>
        <Typography variant='h6' >Excess Interest</Typography>
        <Grid container spacing={2}  alignItems="center">
        <Grid item xs={12} md={3}>
                <Button
                variant="contained"
                color="primary"
                fullWidth
                size="small"
                style={{ height: '100%', margin:'8px' }}
                >
                     Adjust OS 
                </Button>
        </Grid>

        <Grid item xs={12} md={3}>
            <Field
            as={TextField}
            name="TotalExcess"
            label="Total Excess"
            variant="outlined"
            fullWidth
            size="small"
            sx={{margin:'8px'}}
            error={touched.difOpening && Boolean(errors.difOpening)}
            helperText={touched.difOpening && errors.difOpening}
            />
        </Grid>
        </Grid>
        <ReusableTable
                columns={columns}
                data ={data}
        />
        </>
    )
}
  ;

export default ExcessInterest