import React, { useEffect, useState } from 'react';
import { Grid, Box, TextField,Select, MenuItem, FormControl,Button, Typography ,InputLabel,IconButton ,Container,Snackbar,CircularProgress ,Paper} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import ReusableTable from './../../../reuseable/ReusableTable';
import Pagenation from '../../../reuseable/pagenation';
import { LayoutContainer, DropDownContainer, LoadingStyle, TableStyle, PageingStyle } from '../../../styles/globalStyle';

 const columns = [
    { field: 'RowNumber', headerName: 'Row Number', width: '150px' },
    { field: 'MemberID', headerName: 'Member ID', width: '150px' },
    { field: 'Unit', headerName: 'Unit', width: '150px' },
    { field: 'NameDesig', headerName: 'Name Designation', width: '200px' },
    { field: 'Dividend', headerName: 'Dividend', width: '150px' },
    { field: 'TFInterest', headerName: 'TF Interest', width: '200px' },
    { field: 'Total', headerName: 'Total', width: '200px' },
    { field: 'Paid', headerName: 'Paid', width: '150px' },
    { field: 'ChequeNo', headerName: 'Cheque No', width: '200px' },
    { field: 'ChequeDate', headerName: 'Cheque Date', width: '200px' },
    { field: 'Year', headerName: 'Year', width: '150px' },
  ];

const token = localStorage.getItem('token')
const API_URL ='https://tecslkoupe.com';

const FinalizeDividend = () => {
    const [data, setData] = useState([])
    const [FinacYear, setFinacYear] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedYear, setSelectedYear] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    // const [FinancialYear, setFinancialYear] = useState('')
    const [pageSize] = useState(10); 

    useEffect(()=>{
        const fetchFinancialYears = async() =>{
            setLoading(true);
            try{
                const response = await axios.post(`${API_URL}/Admin/Account/FinalizeDividen`,{
                    "Action": "GetFinancialYear",       
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  });
                  setFinacYear(response.data.List);
                  setTotalRecords(response.data.Footer[0].TotalRecord);
                  setLoading(false);
                } catch (error) {
                setError(`Failed to fetch financial years: ${error.message}`);
                setSnackbarMessage(`Failed to fetch financial years: ${error.message}`);
                setSnackbarOpen(true);
                setLoading(false);
                }
    }
    fetchFinancialYears();
},[])

useEffect(()=>{
    if(selectedYear){
        fetchDividendData(selectedYear, currentPage);
    }
},[selectedYear,currentPage]);


const fetchDividendData = async (year, page) => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/Admin/Account/FinalizeDividen`,{
        Action: 'GetDividendAndTFInterest',
        Year:year,
        PageIndex: page,
        pageSize
      },{
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      
      setTotalRecords(response.data.Footer[0].TotalRecord);
      // setPageSize(response.data.Footer[0].pageSize)
      setData(response.data.List|| []);
      setSnackbarMessage(`Suucess to fetch Data`)
    } catch (error) {
      setError(`Failed to fetch dividend data  ${error.message}`);
      setSnackbarMessage(`Failed to fetch dividend data  ${error.message}`)
    }
    setLoading(false);
  };


  const fetchFinalizeDividen = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/Admin/Account/FinalizeDividen`,{
        Action: 'Finalize',
        PostingDate: values.SelectPostingDate
      },{
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      setSnackbarOpen(true);
      setSnackbarMessage(`Success to Finalze  Data ${response.message}`)
    } catch (error) {
      setError(`Failed to fetch dividend data  ${error.code}`);
      setSnackbarMessage(`Failed to fetch dividend data  ${error.code}`)
    }
    setLoading(false);
  };


const handleSubmit =(values)=>{
  fetchFinalizeDividen(values)
}

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleYearChange =(e) =>{
    const selectedYear = e.target.value;
    setSelectedYear(selectedYear)
    setCurrentPage(1);
  }

  return (
    <LayoutContainer  > 
         <Paper elevation={5} style={{padding:'16px', marginBottom:'16px', display: 'flex', flexDirection: 'row', justifyContent:'space-between',}} >    
          <DropDownContainer item xs={12}>
            
                <FormControl fullWidth>
                  <InputLabel id="select-year">Select Financial Year</InputLabel>
                  <Select
                    labelId="select-year"
                    name="FinancialYear"
                    label="Select Financial Year"
                    value={selectedYear}
                    size='small'
                    onChange={(e) => {
                      // handleChange(e);
                      handleYearChange(e);
                    }}
                  >
                    {FinacYear.map((yearObject, index) => (
                      <MenuItem value={yearObject.Year} key={index}>{yearObject.Year}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
           
              </DropDownContainer>
          <Grid item sx={{ display: 'flex', flex:1,flexDirection: 'row',alignItems:'center',justifyContent:'space-evenly' ,marginBottom: '1rem' }}>
         <Formik initialValues={{ SelectPostingDate:'' }} onSubmit={handleSubmit}>
          {({ values, handleChange ,errors, touched}) => (
            <Form >
              <Grid  sx={{display: 'flex', flex:1,flexDirection: 'row', alignItems:'center', justifyContent:'space-evenly', marginRight:'8px', gap:'16px'}}> 
                  <Grid item sx={6} >
                      <Field
                        name="SelectPostingDate"
                        as={TextField}
                        label="Select Posting Date"
                        type='date'
                        // value={values.SelectPostingDate}
                        fullWidth
                        size='small'
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required        
                        error={touched.SelectPostingDate && Boolean(errors.SelectPostingDate)}
                        helperText={touched.SelectPostingDate&& errors.SelectPostingDate}
                      />
                </Grid>
              <Grid item sx={6}>
                <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                  >Finalize</Button>
              </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        </Grid>
        </Paper>   

    <Paper elevation={5} style={{padding:'16px', marginBottom:'16px'}} >
            {
                loading ? (
                    <LoadingStyle >
                      <CircularProgress />
                    </LoadingStyle>
                ):(
                    <TableStyle>
                    <Box>
                        <ReusableTable data={data} columns={columns} />
                    </Box>
                    <Pagenation 
                        currentPage={currentPage} 
                        setCurrentPage={setCurrentPage} 
                        totalRecords={totalRecords} 
                        pageSize={pageSize}
                      />
                    </TableStyle>
                )
            }
    </Paper>
    {error && (
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={()=>setError(null)}
        message={error}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => setError(null)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    )}
    <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton size="small" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
    />
</LayoutContainer>
  )
}
export default FinalizeDividend
