import React from 'react';
import * as Yup from 'yup';

import { Box, Button, Card, CardContent, Grid, Typography, Container } from '@mui/material';
import { Form, Formik, Field } from 'formik';
import NormalLoanSection from './NormalLoanSection';
import EmergencyLoanSection from './EmergencyLoanSection';
import LoanSuretySection from './LoanSuretySection';
import NewLoanVoucherSection from './NewLoanVoucherSection';
import OtherDeductionsSection from './OtherDeductionsSection';
import PaymentDetailsSection from './PaymentDetailsSection';
import styled from '@emotion/styled';
import { useState } from 'react';

const LayoutContainer = styled(Container)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: start;
//   max-width: 1600px;
  width: 100%;
//   min-height: 100vh;
//   height: 100vh;
  padding: 16px;
 top: 12%;
  left:18%;
//   background-color: #ccffff;
`;


const validationSchema = Yup.object().shape({
      normalLoanBalance: Yup.number().required('Required'),
      normalLoanMonthlyRecovery: Yup.number().required('Required'),
      normalLoanDate: Yup.date().required('Required'),
      normalAmountSanctioned: Yup.number().required('Required'),
      emergencyLoanBalance: Yup.number().required('Required'),
      emergencyLoanMonthlyRecovery: Yup.number().required('Required'),
      emergencyLoanDate: Yup.date().required('Required'),
      witnessID: Yup.string().required('Required'),  
      EmergencyamountSanctioned: Yup.number().required('Required'),
      amountSanctioned:Yup.number().required('Required'),
      shareMoney: Yup.number().required('Required'),
      reasonForLoan: Yup.string().required('Required'),
      newLoanMonthlyRecovery: Yup.number().required('Required'),
      newLoanDate: Yup.date().required('Required'),
      admissionFees: Yup.number().required('Required'),
      paymentMode: Yup.string().required('Required'),
      chequeNumber: Yup.string().required('Required'),
      bankName: Yup.string().required('Required'),
})


const LoanForm = () => {
    const [loanType, setLoanType] = useState([])
    const initialValues = {
        normalLoanBalance: '',
        normalLoanMonthlyRecovery: '',
        normalLoanDate: '',
        normalAmountSanctioned:'',
        emergencyLoanBalance: '',
        emergencyLoanMonthlyRecovery: '',
        emergencyLoanDate: '',
        witnessID: '',
        loanType: ['Normal', 'Emergency'] ,
        amountSanctioned:'',
        emergencyAmountSanctioned: '',
        shareMoney: '',
        reasonForLoan: '',
        newLoanMonthlyRecovery: '',
        newLoanDate: '',
        admissionFees: '',
        deductions: [],
        paymentMode: '',
        chequeNumber: '',
        bankName: '',
        debitHeads:[{id:'', account:'', amount:''}],
        amount:'',
        loanTable:[{sno:'', id:'', name:'', unit:''}],
  }

  
  return (
    <LayoutContainer  >
        <Card >
        <CardContent>
            <Typography variant='h5' component="h2" gutterBottom>
                New Loan to Member
            </Typography>
            <Box>
                <Formik
                     initialValues={initialValues}
                     validationSchema={validationSchema}
                     onSubmit={(values)=>{console.log(values)}}
                >
                   {({errors, touched, handleChange,values,onChange})=>(
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                            <CardContent>
                                <Typography variant='h6'>Normal Loan</Typography>
                            </CardContent>
                                <NormalLoanSection errors={errors}  values={values}  touched={touched} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CardContent>
                                    <Typography variant='h6'>Emergency Loan</Typography>
                                </CardContent>
                                <EmergencyLoanSection errors={errors}  touched={touched}  values={values} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LoanSuretySection  errors={errors}  touched={touched} values={values} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant='h6'> New Loan Voucher</Typography>
                                <NewLoanVoucherSection  
                                    errors={errors}  
                                    touched={touched } 
                                    values={values} 
                                    loan={loanType}
                                    handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <OtherDeductionsSection  errors={errors} touched={touched} values={values} handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} md={6} >
                                <PaymentDetailsSection  errors={errors} touched={touched} values={values} handleChange={onChange} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >       
                            <Button 
                                type='submit'
                                variant='contained'
                                color="primary"
                            >
                                Save
                            </Button>
                            </Grid>
                    </Form>
                   )} 
                </Formik>
            </Box>
        </CardContent>
        </Card>
    </LayoutContainer>
  )
}

export default LoanForm;
