import React from 'react'
import { Box,Grid ,TextField, Typography, Paper } from '@mui/material';
import { Field, ErrorMessage } from 'formik';
import styled from '@emotion/styled';

const BoxStyle = styled(Box)`
padding:16px;
border: 1px solid #6b7280;
borderRadius: theme.shape.borderRadius,
backgroundColor: 'white',
margin: '8px'

`

const Address = ({values, handleChange}) => {
  return (
    <Box  sx={{margin: '8px', padding:'8px'}} component={Paper} elevation={3} >
      <Typography  variant="h6" component="h3" sx={{  display:'flex', justifyContent:'start', color:'#0b0e30', }}  >
        Address
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
              <Field
                    as={TextField}
                    id="addressLine1"
                    type="text"
                    value={values.addressLine1}
                    label="Address Line1"
                    variant="outlined"
                    onChange={handleChange}
                    helperText={<ErrorMessage name="addressLine1"/>}
                    required
                    margin="normal"           
                />
                <Field
                    as={TextField}
                    id="addressLine2"
                    type="text"
                    value={values.addressLine2}
                    label="Address Line2"
                    variant="outlined"
                    onChange={handleChange}    
                    helperText={<ErrorMessage name="addressLine2"/>}
                    required
                    margin="normal"         
                />
                 <Field
                    as={TextField}
                    id="addressLine3"
                    type="text"
                    value={values.addressLine3}
                    label="Address Line3"
                    variant="outlined"
                    onChange={handleChange}
                  
                    helperText={<ErrorMessage name="addressLine3"/>}
                    required
                    margin="normal"     
                />
        </Grid>
        <Grid item xs={6}>  
         <Field
            as={TextField}
            id="city"
            type="text"
            value={values.city}
            label="City"
            variant="outlined"
            onChange={handleChange}
           
            helperText={<ErrorMessage name="city"/>}
            required
            margin="normal"           
        />
         <Field
            as={TextField}
            id="state"
            type="text"
            value={values.state}
            label="state"
            variant="outlined"
            onChange={handleChange}
           
            helperText={<ErrorMessage name="state"/>}
            required
            margin="normal"    
        />
         <Field
            as={TextField}
            id="pin"
            type="text"
            value={values.pin}
            label="Pin"
            variant="outlined"
            onChange={handleChange}
           
            helperText={<ErrorMessage name="Pin"/>}
            required
            margin="normal"         
        />
        </Grid>
        </Grid>
    </Box>
  )
}

export default Address
