import React, { useEffect, useState } from 'react';
import { Grid, Box, TextField, Button, Stack, Paper, CircularProgress } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ReusableTable from './../../reuseable/ReusableTable';
import SelectDropdown from '../../reuseable/CustomDropDown';
import axios from 'axios';
import { LayoutContainer, LoadingStyle, TableStyle } from '../../styles/globalStyle';
import Pagenation from '../../reuseable/pagenation';

const columns = [
    { field: 'RowNumber', headerName: 'R No', width: '100px' },
    { field: 'SNO', headerName: 'S No', width: '100px' },
    { field: 'AppFormNo', headerName: 'App Form No', width: '100px' },
    { field: 'FirstName', headerName: 'FirstName', width: '150px' },
    { field: 'LastName', headerName: 'LastName', width: '150px' },
    { field: 'HRMSNo', headerName: 'HRMS No', width: '100px' },
    { field: 'MemberID', headerName: 'Member ID', width: '100px' },
    { field: 'Unit', headerName: 'Unit', width: '100px' },
    { field: 'SubUnit', headerName: 'Sub Unit', width: '100px' },
    { field: 'HRMSNo', headerName: 'HRMS No', width: '100px' },
    { field: 'Total', headerName: 'Total', width: '100px' },
    { field: 'ChequeNo', headerName: 'Cheque/DD No', width: '100px' },  
];

const API_URL = 'https://tecslkoupe.com';
const token = localStorage.getItem('token');

const ActiveMember = () => {
  const [data, setData] = useState([]);
  const [actionType, setActionType] = useState('GetActiveMembers');
  const [members, setMembers] = useState([]);
  const [unit, setUnit] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [appFormNo, setAppFormNo] = useState([]);
  const [selectedAppFormNo, setSelectedAppFormNo] = useState(null);
  const [minAmount, setMinAmount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize] = useState(10);

  // Fetch initial data for dropdowns
  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      try {
        const [unitResponse, appFormResponse] = await Promise.all([
          axios.post(`${API_URL}/Admin/Payments/GroupDividendAndTFInt`, { Action: 'GetUnit' }, { headers: { Authorization: `Bearer ${token}` } }),
          axios.post(`${API_URL}/Admin/Payments/GroupDividendAndTFInt`, { Action: 'GetAppFormNo' }, { headers: { Authorization: `Bearer ${token}` } }),
        ]);
        setUnit(unitResponse.data.List || []);
        setAppFormNo(appFormResponse.data.List || []);
      } catch (error) {
        console.error('Failed to fetch initial data', error);
      }
      setLoading(false);
    };
    fetchInitialData();
  }, []);

  // Fetch members based on selected filters
  const fetchMembers = async (action, page = 1, values = {}) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${API_URL}/Admin/Payments/GroupDividendAndTFInt`,
        {
          Action: action,
          Year: 1617,
          Unit: values.selectedUnit,
          AppFormNo: values.selectedAppFormNo,
          MinimumAmount: values.MinmumAmount || 100,
          PageIndex: page,
          pageSize,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMembers(response.data.List || []);
      setTotalRecords(response.data.Footer[0]?.TotalRecord || 0);
    } catch (error) {
      console.error(`Failed to fetch users ${error.message}`);
    }
    setLoading(false);
  };

  // Call fetchMembers when filters or pagination changes
  useEffect(() => {
    if (selectedUnit) {
      fetchMembers(actionType, currentPage, { selectedUnit, selectedAppFormNo, MinmumAmount: minAmount });
    }
  }, [selectedUnit, selectedAppFormNo, actionType, currentPage]);

  // Handle form submission
  const handleSubmit = (values) => {
    setMinAmount(values.MinmumAmount);
    setCurrentPage(1); // Reset to the first page when submitting new filters
  };

  // Handle Active and Resigned button clicks
  const handleActive = () => {
    setActionType('GetActiveMembers');
    setCurrentPage(1); // Reset to the first page when changing action type
  };

  const handleResigned = () => {
    setActionType('GetResignMembers');
    setCurrentPage(1);
  };

  // Handlers for dropdowns
  const unithandler = (e) => {
    const selectedUnit = e.target.value;
    setSelectedUnit(selectedUnit);
    setCurrentPage(1);
  };

  const appFormhandler = (e) => {
    const selectedAppForm = e.target.value;
    setSelectedAppFormNo(selectedAppForm);
    setCurrentPage(1);
  };

  return (
    <LayoutContainer>
      {/* Active and Resigned Buttons */}
      <Paper elevation={5} style={{ padding: '16px', marginBottom: '16px' }}>
        <Stack spacing={2} direction="row">
          <Button variant="outlined" onClick={handleActive}>
            Active Members
          </Button>
          <Button variant="outlined" onClick={handleResigned}>
            Resigned Members
          </Button>
        </Stack>
      </Paper>

      {/* Filters */}
      <Paper elevation={5} style={{ padding: '16px', marginBottom: '16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <SelectDropdown label="Select Unit" name="SelectUnit" value={selectedUnit} options={unit.map((u) => u.Unit)} onChange={unithandler} />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectDropdown
              label="Select App Form No"
              name="SelectAppFormNo"
              value={selectedAppFormNo}
              options={appFormNo.map((a) => a.AppFormNo)}
              onChange={appFormhandler}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Formik  initialValues={{ MinmumAmount: '' }} validationSchema={Yup.object().shape({ MinmumAmount: Yup.number().min(100, 'Amount must be greater than hundrad') })} onSubmit={handleSubmit}>
              {({ errors, touched, handleChange, values, submitForm }) => (
                <Form>
                  <Field
                    as={TextField}
                    name="MinmumAmount"
                    label="Minimum Amount"
                    type="number"
                    value={values.MinmumAmount}
                    size="small"
                    onBlur={submitForm}
                    onChange={handleChange}
                    fullWidth
                    error={touched.MinmumAmount && Boolean(errors.MinmumAmount)}
                    helperText={touched.MinmumAmount && errors.MinmumAmount}
                  />
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Paper>

      {/* Members Table */}
      <Paper elevation={5} style={{ padding: '16px', marginBottom: '16px' }}>
        <Box>
          {loading ? (
            <LoadingStyle>
              <CircularProgress />
            </LoadingStyle>
          ) : (
            <TableStyle>
              <ReusableTable data={members} columns={columns} />
              <Pagenation currentPage={currentPage} setCurrentPage={setCurrentPage} totalRecords={totalRecords} pageSize={pageSize} />
            </TableStyle>
          )}
        </Box>
      </Paper>
    </LayoutContainer>
  );
};

export default ActiveMember ;
