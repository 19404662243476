import React, { useState, useEffect } from "react";
import { Button, Paper, Grid, Box, Stack, TextField } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import SelectDropdown from "../../reuseable/CustomDropDown"; // Assuming you have a reusable dropdown component
import { LayoutContainer } from "../../styles/globalStyle";

const API_URL = "https://tecslkoupe.com";
const token = localStorage.getItem("token");

const GroupDividendTfInterestPaymentAndChequePayment = () => {
  // Group related states into objects
  const [filters, setFilters] = useState({
    actionType: "GetActiveMembers",
    selectedUnit: "",
    selectedAppFormNo: "",
    selectedMember: "",
    selectedYear: "",
    minAmount: null,
  });

  const [data, setData] = useState({
    unit: [],
    appFormNo: [],
    account: [],
    payMode: [],
    membersId: [],
    finacYear: [],
    chequesList: [],
  });

  const [pageSize] = useState(10); 
  const [totalRecords, setTotalRecords] = useState(0);
  const [membersData, setMembersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Helper function to fetch initial data
  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const [unitResponse, appFormResponse, accountResponse, payModeResponse, memberIdResponse, yearResponse] = await Promise.all([
        axios.post(`${API_URL}/Admin/Payments/GroupDividendAndTFInt`, { Action: "GetUnit" }, { headers: { Authorization: `Bearer ${token}` } }),
        axios.post(`${API_URL}/Admin/Payments/GroupDividendAndTFInt`, { Action: "GetAppFormNo" }, { headers: { Authorization: `Bearer ${token}` } }),
        axios.post(`${API_URL}/Admin/Payments/GroupDividendAndTFInt`, { Action: "GetAccount" }, { headers: { Authorization: `Bearer ${token}` } }),
        axios.post(`${API_URL}/Admin/Payments/GroupDividendAndTFInt`, { Action: "GetPayMode" }, { headers: { Authorization: `Bearer ${token}` } }),
        axios.post(`${API_URL}/Admin/Payments/GroupDividendAndTFInt`, { Action: "GetMemberID" }, { headers: { Authorization: `Bearer ${token}` } }),
        axios.post(`${API_URL}/Admin/Account/FinalizeDividen`, { Action: "GetFinancialYear" }, { headers: { Authorization: `Bearer ${token}` } }),
      ]);

      // Set the response data to state
      setData({
        unit: unitResponse.data.List || [],
        appFormNo: appFormResponse.data.List || [],
        account: accountResponse.data.List || [],
        payMode: payModeResponse.data.List || [],
        membersId: memberIdResponse.data.List || [],
        finacYear: yearResponse.data.List || [],
        chequesList: [],
      });
    } catch (error) {
      console.error("Failed to fetch initial data", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchMembers = async (action, page = 1, values = {}) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${API_URL}/Admin/Payments/GroupDividendAndTFInt`,
        {
          Action: action,
          Year: 2223,
          Unit: values.selectedUnit,
          AppFormNo: values.selectedAppFormNo,
          MinimumAmount: values.MinmumAmount || 100,
          PageIndex: page,
          pageSize,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMembersData(response.data.List || []);
      setTotalRecords(response.data.Footer[0]?.TotalRecord || 0);
    } catch (error) {
      console.error(`Failed to fetch users ${error.message}`);
    }
    setLoading(false);
  };

  // Fetch members whenever the filters or page changes
  useEffect(() => {
    if (filters.selectedUnit) {
      fetchMembers(filters.actionType, currentPage, {
        selectedUnit: filters.selectedUnit,
        selectedAppFormNo: filters.selectedAppFormNo,
        MinmumAmount: filters.minAmount,
      });
    }
  }, [filters.selectedUnit, filters.selectedAppFormNo, filters.actionType, currentPage]);

  const fetchGenerateCheque = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${API_URL}/Admin/Payments/GroupDividendAndTFInt`,
        {
          Action: "GenerateCheque",
          Year: filters.selectedYear || "1617", // default year
          Unit: values.selectedUnit || "Allahabad",
          AppFormNo: values.selectedAppFormNo || 0,
          SelectMembers: values.SelectMembers || "ResignedMembers",
          MinimumAmount: values.minAmount || 100,
          ChequeDate: values.SelectChequeDate || new Date().toISOString().split("T")[0],
          MemberID: 0,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setData((prevState) => ({ ...prevState, chequesList: response.data.List || [] }));
      setSnackbarMessage("Cheques generated successfully.");
    } catch (error) {
      console.error("Failed to generate cheques:", error);
    }
    setLoading(false);
  };

  const handleFiltersChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
    setCurrentPage(1); // Reset pagination when filters change
  };

  const handleSubmit = (values) => {
    handleFiltersChange("minAmount", values.MinmumAmount); // Set minimum amount from form submission
  };

  const handleActive = () => handleFiltersChange("actionType", "GetActiveMembers");
  const handleResigned = () => handleFiltersChange("actionType", "GetResignMembers");

  return (
    <LayoutContainer>
      {/* Active and Resigned Buttons */}
      <Paper elevation={5} style={{ padding: "16px", marginBottom: "16px" }}>
        <Stack spacing={2} direction="row">
          <Button variant="outlined" onClick={handleActive}>
            Active Members
          </Button>
          <Button variant="outlined" onClick={handleResigned}>
            Resigned Members
          </Button>
        </Stack>
      </Paper>

      {/* Filters */}
      <Paper elevation={5} style={{ padding: "16px", marginBottom: "16px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <SelectDropdown
              label="Select Unit"
              name="SelectUnit"
              value={filters.selectedUnit}
              options={data.unit.map((u) => u.Unit)}
              onChange={(e) => handleFiltersChange("selectedUnit", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectDropdown
              label="Select App Form No"
              name="SelectAppFormNo"
              value={filters.selectedAppFormNo}
              options={data.appFormNo.map((a) => a.AppFormNo)}
              onChange={(e) => handleFiltersChange("selectedAppFormNo", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Formik
              initialValues={{ MinmumAmount: "" }}
              validationSchema={Yup.object().shape({ MinmumAmount: Yup.number().min(0) })}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, handleChange, values, submitForm }) => (
                <Form>
                  <Field
                    as={TextField}
                    name="MinmumAmount"
                    label="Minmum Amount"
                    type="number"
                    value={values.MinmumAmount}
                    size="small"
                    onChange={handleChange}
                    onBlur={submitForm}
                    fullWidth
                    error={touched.MinmumAmount && Boolean(errors.MinmumAmount)}
                    helperText={touched.MinmumAmount && errors.MinmumAmount}
                  />
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Paper>

      {/* Cheque Generation */}
      <Paper elevation={5} style={{ padding: "16px", marginBottom: "16px" }}>
        <Box>
          <Formik
            initialValues={{ SelectChequeDate: "" }}
            validationSchema={Yup.object().shape({
              SelectChequeDate: Yup.date().required("Select Cheque Date is required"),
            })}
            onSubmit={fetchGenerateCheque}
          >
            {({ errors, touched, values }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <Field
                      as={TextField}
                      name="SelectChequeDate"
                      label="Select Cheque Date"
                      type="date"
                      value={values.SelectChequeDate}
                      size="small"
                      fullWidth
                      error={touched.SelectChequeDate && Boolean(errors.SelectChequeDate)}
                      helperText={touched.SelectChequeDate && errors.SelectChequeDate}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Button type="submit" variant="contained" color="primary">
                      Generate Cheques
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Paper>
    </LayoutContainer>
  );
};

export default GroupDividendTfInterestPaymentAndChequePayment;