import React, { useEffect, useState } from 'react';

import { Grid, Box, Select, MenuItem, FormControl, InputLabel, Container, Paper} from '@mui/material';
import { Formik, Form, Field } from 'formik';

import styled from '@emotion/styled';
import axios from 'axios';

const LayoutContainer = styled(Container)`  
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content:start;
  max-width: 600px;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  adding: 16px;
  top: 12%;
  left:18%;
 
  backgroundColor:'#ccffff';
`;

const DropDownContainer = styled(Grid)`
  display: flex;
  flex:1;
  justify-content:start;
  width:100%;
//   margin:8px 0px;

 `
 const token = localStorage.getItem('token')
const API_URL ='https://tecslkoupe.com';

const MemberIdComponent = () => {
    const [MemberId, setMemberId] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);

    const handleMemberId =(e) =>{
            const getMemberId = e.target.value;
            setMemberId(getMemberId);
            
    }

    useEffect(()=>{
        const fetchMemberId = async() =>{
            setLoading(true);
            try{
                const response = await axios.post(`${API_URL}/Admin/Account/RaiseInterestOS`,{
                    "Action":"GetMemberID"
                },{
                    headers:{
                        Authorization:`Bearer ${token}`,
                    },
                });
                setMemberId(response.data.List)
                setTotalRecords(response.data.Footer[0].TotalRecord);
                setLoading(false);
            }catch(error){
                setError(`Failed to fetch users ${error.message} `)
                setLoading(false);
            }
        }
        fetchMemberId();
    },[])


  return (
            <Formik
                initialValues={{
                    MemberId: '',
                  }}

                onSubmit={(values) => {
                    // Handle form submission
                 }}
            
            >{(values,handleChange)=>(
                <Form>
                    <DropDownContainer item xs={12} >
                    <FormControl fullWidth size="small">
                    <InputLabel field="member-id">Member Id</InputLabel>
                    <Select
                        labelId="member-id"
                        name="memberId"
                        label="Member Id"
                        value={values.MemberId}
                        onChange={(e) => {
                            handleChange(e);
                            handleMemberId(e);
                        }}
                    > 
                        {
                            MemberId.map((memberObj, index) =>(
                                <MenuItem value={memberObj.MemberID} key={index}>{memberObj.MemberID}</MenuItem>
                            ))
                        }
                        
                    </Select>
                    </FormControl>
                    </DropDownContainer>
                </Form>
            )}
            
            </Formik>
           

  )
}

export default MemberIdComponent


