import React from 'react';
import { Box, Grid, Paper, TextField } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';

const validationSchema = Yup.object().shape({
  VoucherDate: Yup.string().required('Voucher Date is required'),
  Id: Yup.number().required('Id is required'),
  Name: Yup.string().required('Name is required'),
});

const FormContainer = styled(Grid)`
  width: 100%;
  padding: 24px;
  border-radius: 10px;
  margin-bottom: 8px;
`;

const RowContainer = styled(Grid)`
  display:flex;
  justify-content:space-between;
  width: 100%;
`;

const MemberHeader = ({ values, handleChange, touched, errors}) => {
  
  return (
    <FormContainer elevation={5} component={Paper} >     
          <Form>
            <Grid container  spacing={4} alignItems="center">
              {[
                { name: "VoucherDate", label: "Voucher Date", type: "date" },
                { name: "Id", label: "Id", type: "number" },
                { name: "Unit", label: "Unit" },
              ].map(({ name, label, type }) => (
                <Grid item xs={12} md={4} key={name}>
                  <Field
                    as={TextField}
                    name={name}
                    label={label}
                    type={type}
                    value={values[name]}
                    fullWidth
                    margin="normal"
                    size="small"
                    onChange={handleChange}
                    InputLabelProps={type === "date" ? { shrink: true } : {}}
                    error={touched.AccountName && Boolean(errors.AccountName)}
                    helperText={touched.AccountName && errors.AccountName}
                  />     
                </Grid>
              ))}
            </Grid>
          </Form>     
    </FormContainer>
  );
};

export default MemberHeader;
