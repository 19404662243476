import React, { useState } from 'react';
import * as Yup from 'yup';
import { Box, Button, CircularProgress, Container, Grid, Paper, TextField, Pagination, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import axios from 'axios';
import ReusableTable from '../../reuseable/ReusableTable';
import styled from '@emotion/styled';

const LayoutContainer = styled(Container)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: start;
//   max-width: 1600px;
  width: 100%;
//   min-height: 100vh;
//   height: 100vh;
  padding: 16px;
  top: 12%;
  left:18%;
//   background-color: #ccffff;
`;

const PageingStyle = styled(Box)`
    display:flex;
    flex-direction:row;
    justify-content:end;  
    align-items:center;
    margin:16px 0;
`


const columns = [
  { field: 'RowNumber', headerName: 'RowNumber', width: '150px' },
  { field: 'SNO', headerName: 'SNO', width: '150px' },
  { field: 'Trans_ID', headerName: 'Trans_ID', width: '150px' },
  { field: 'TDate', headerName: 'TDate', width: '250px' },
  { field: 'Receipt_No', headerName: 'Receipt No', width: '150px' },
  { field: 'VrNo', headerName: 'Vr No', width: '150px' },
  { field: 'Transaction_Type', headerName: 'Transaction_Type', width: '150px' },
  { field: 'Rec_From', headerName: 'Rec From', width: '150px' },
  { field: 'Total_Amount', headerName: 'Total_Amount', width: '150px' },
  { field: 'Cash_Cheque_Amount', headerName: 'Cash Cheque Amount', width: '150px' },
  { field: 'Cheque_No', headerName: 'Cheque_No', width: '150px' },
  { field: 'Finalized', headerName: 'Finalized', width: '150px' },
  { field: 'Member_ID', headerName: 'Member_ID', width: '150px' },
  { field: 'Staff_No', headerName: 'Staff_No', width: '150px' },
  { field: 'Remarks', headerName: 'Remarks', width: '150px' },
  { field: 'Income_Code', headerName: 'Income Code', width: '150px' },
  { field: 'Cash_Cheque', headerName: 'Cash_Cheque', width: '150px' },
];

const validationSchema = Yup.object({
  id: Yup.number().required('Required'),
  fromDate: Yup.date().required('Required'),
  toDate: Yup.date().required('Required')
});

const initialValues = {
  id: '',
  fromDate: '',
  toDate: '',
};

const base_url = 'https://tecslkoupe.com';

const AllReport = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  
  const fetchData = async (url, values, token) => {
    try {
      const response = await axios.post(url, values, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  const handleSubmit = async (url, values) => {
    console.log(url, values)
    setLoading(true);
    setError('');
    try {
      const data = await fetchData(url, values, localStorage.getItem('token'));
      console.log(data.List)
      setTableData(data.List);
      setTotalRecords(data.Footer[0].TotalRecord);
    } catch (error) {
      setError('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, newPageIndex,currentUrl, currentValues) => {
    setPageIndex(newPageIndex);
    handleSubmit(currentUrl, { ...currentValues, PageIndex: newPageIndex, pageSize });
  };

  return (
    <LayoutContainer maxWidth="lg"  sx={{ maxWidth: '70%' }}>
      <Grid item xs={12} container spacing={2} sm={9} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, errors, touched, values, setFieldValue }) => (
            <Form>
              <Grid sx={{ marginTop: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Paper elevation={3} style={{ padding: '16px' }}>
                  <Grid item sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '1rem' }}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        as={TextField}
                        id="Id"
                        type="number"
                        value={values.id}
                        label="Id"
                        variant="outlined"
                        onChange={(e) => setFieldValue('id', e.target.value)}
                        error={touched.date && !!errors.date}
                        helperText={touched.date && errors.date}
                        required
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        as={TextField}
                        name="fromDate"
                        label="From Date"
                        type="date"
                        value={values.fromDate}
                        onChange={(e) => setFieldValue('fromDate', e.target.value)}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={touched.fromDate && !!errors.fromDate}
                        helperText={touched.fromDate && errors.fromDate}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        as={TextField}
                        name="toDate"
                        label="To Date"
                        type="date"
                        value={values.toDate}
                        onChange={(e) => setFieldValue('toDate', e.target.value)}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={touched.toDate && !!errors.toDate}
                        helperText={touched.toDate && errors.toDate}
                      />
                    </Grid>
                  </Grid>
                  <Grid item sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '1rem' }}>
                    <Grid tem xs={12} sm={6}>
                      <Button
                        type='button'
                        variant='contained'
                        color="primary"
                        disabled={isSubmitting}
                        margin="normal"
                        onClick={() => {
                          handleSubmit(`${base_url}/Admin/Report/ReportMaster`, {
                            "Action": "GetReceipts",
                            id: values.id,
                            fromDate: values.fromDate,
                            toDate: values.toDate,
                            "PageIndex": pageIndex,
                             pageSize
                          });
                        }}
                      >
                        Receipts
                      </Button>
                    </Grid>
                    <Grid tem xs={12} sm={6}>
                      <Button
                        type='button'
                        variant='contained'
                        color="primary"
                        disabled={isSubmitting}
                        margin="normal"
                        onClick={() => handleSubmit(`${base_url}/Admin/Report/ReportMaster`, {
                          "Action": "GetPayments",
                          id: values.id,
                          fromDate: values.fromDate,
                          toDate: values.toDate,
                          "PageIndex": pageIndex,
                           pageSize
                        })}
                      >
                        Payments
                      </Button>
                    </Grid>
                    <Grid tem xs={12} sm={6}>
                      <Button
                        type='button'
                        variant='contained'
                        color="primary"
                        disabled={isSubmitting}
                        margin="normal"
                        onClick={() => handleSubmit(`${base_url}/Admin/Report/ReportMaster`, {
                          "Action": "GetJournals",
                          id: values.id,
                          fromDate: values.fromDate,
                          toDate: values.toDate,
                          "PageIndex": pageIndex,
                           pageSize
                        })}
                      >
                        Journal
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Form>
          )}
        </Formik>
        <Grid sx={{ marginTop: '1rem', maxWidth: '140%' }}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                <CircularProgress />
              </Box>
            ) : error ? (
              <Box color="error.main">{error}</Box>
            ) : (
              <ReusableTable data={tableData} columns={columns} />
            )}
            <PageingStyle  >
              <Button
                variant="contained"
                onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
            <Typography variant="h6" style={{ margin: '0 16px' }}>
              Page {currentPage} of {Math.ceil(totalRecords / pageSize)}
            </Typography>
            <Button
              variant="contained"
              onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
              disabled={currentPage === Math.ceil(totalRecords / pageSize)}
            >
              Next
            </Button>
            </PageingStyle>
          </Paper>
        </Grid>
      </Grid>
    </LayoutContainer>
  );
};

export default AllReport;
