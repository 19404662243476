import React from 'react'
import { Card, CardContent, TextField, Typography, Grid } from '@mui/material'
import { Field } from 'formik'

const EmergencyLoanSection = ({values, styles, touched, errors}) => {
    console.log(values.emergencyLoanBalance)

  return (
    <Grid container spacing={2}>
        
          <Grid > 
          {[
                            { name: "emergencyLoanBalance", label: "Balance" },
                            { name: "emergencyLoanMonthlyRecovery", label: "Monthly Recovery" },
                            { name: "emergencyLoanDate", label: "Emergency Loan Date", type: "date" },
                            { name: "EmergencyamountSanctioned", label: "Amount Sanctioned" },
                           ].map(({ name, label, type = "text", component }) => (
                              <Grid item key={name} xs={12}>
                                  {component ? component : (
                                      <Field
                                          as={TextField}
                                          name={name}
                                          label={label}
                                          type={type}
                                          value={values[name]}
                                          size="small"
                                          fullWidth
                                          margin="normal"
                                          error={touched.name && Boolean(errors.name)}
                                          helperText={touched.name && errors.name}
                                          InputLabelProps={type === "date" ? { shrink: true } : {}}
                                      />
              )}
               </Grid>
           ))}
          </Grid>            
  </Grid>
  )
}

export default EmergencyLoanSection

{/* <Field
                name="emergencyLoanBalance"
                as={TextField}
                label="Balance"
                value={values.emergencyLoanBalance}
                fullWidth
                required
                margin="normal"
                error={touched.emergencyLoanBalance && Boolean(errors.emergencyLoanBalance)}
                helperText={touched.emergencyLoanBalance && errors.emergencyLoanBalance}
            />
            <Field
                name="emergencyLoanMonthlyRecovery"
                as={TextField}
                label="Monthly Recovery"
                value={values.emergencyLoanMonthlyRecovery}
                fullWidth
                required
                margin="normal"
                error={touched.emergencyLoanMonthlyRecovery && Boolean(errors.emergencyLoanMonthlyRecovery)}
                helperText={touched.emergencyLoanMonthlyRecovery && errors.emergencyLoanMonthlyRecovery}
            />
            <Field
                name="emergencyLoanDate"
                as={TextField}
                margin="normal"
                value={values.emergencyLoanDate}
                type="date"
                fullWidth
                required
                error={touched.emergencyLoanDate && Boolean(errors.emergencyLoanDate)}
                helperText={touched.emergencyLoanDate && errors.emergencyLoanDate}
            />
            <Field
                name="EmergencyamountSanctioned"
                as={TextField}
                label="Amount Sanctioned"
                value={values.EmergencyamountSanctioned}
                fullWidth
                required
               margin="normal"
                error={touched.EmergencyamountSanctioned && Boolean(errors.EmergencyamountSanctioned)}
                helperText={touched.EmergencyamountSanctioned && errors.EmergencyamountSanctioned}
            />
    */}