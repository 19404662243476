import React from 'react';
import { Card, CardContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography,Grid } from '@mui/material'
import { Field } from 'formik'
import styled from '@emotion/styled';

const DropDownContainer = styled(Grid)`
  display: flex;
  flex:1;
  justify-content:start;
  width:30%;
  margin:16px 8px;
 `


const NewLoanVoucherSection = ({values, touched, errors, handleChange, loan}) => {
   
  return (
    <Grid container spacing={2} direction="column">
          <DropDownContainer item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="select-year">Select Financial Year</InputLabel>
                  <Select
                    labelId="select-year"
                    name="FinancialYear"
                    label="Select Financial Year"
                    value={values.FinancialYear}
                    // onChange={(e) => {
                    //   handleChange(e);
                    //   handleYearChange(e);
                    // }}
                  >
                    {loan.map((yearObject, index) => (
                      <MenuItem value={yearObject.Year} key={index}>{yearObject.Year}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </DropDownContainer>
          <Grid > 
          {[
                            { name: "emergencyLoanBalance", label: "Balance" },
                            { name: "emergencyLoanMonthlyRecovery", label: "Monthly Recovery" },
                            { name: "emergencyLoanDate", label: "Emergency Loan Date", type: "date" },
                            { name: "EmergencyamountSanctioned", label: "Amount Sanctioned" },
                           ].map(({ name, label, type = "text", component }) => (
                              <Grid item key={name} xs={6}>
                                  {component ? component : (
                                      <Field
                                          as={TextField}
                                          name={name}
                                          label={label}
                                          type={type}
                                          value={values[name]}
                                          size="small"
                                          fullWidth
                                          margin="normal"
                                          error={touched.name && Boolean(errors.name)}
                                          helperText={touched.name && errors.name}
                                          InputLabelProps={type === "date" ? { shrink: true } : {}}
                                      />
              )}
               </Grid>
           ))}
          </Grid>            
  </Grid>
  )
}

export default NewLoanVoucherSection
