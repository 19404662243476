import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField, Button, Typography, Container, Box, Grid,IconButton, CircularProgress, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, Snackbar, 
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ReusableTable from '../../reuseable/ReusableTable';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

const LayoutContainer = styled(Container)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  padding: 16px;
  top: 12%;
  left:18%;

`;

const ADDBtn = styled(Button)`
  display:flex; 
  justify-content: center; 
  margin-bottom: 16px; 
  width: 15%;
  align-items:center; 
  padding:8px;
`
const PageingStyle = styled(Box)`
    display:flex;
    flex-direction:row;
    justify-content:end;  
    align-items:center;
    margin:16px 0;
`

const API_URL = 'https://tecslkoupe.com'
const token = localStorage.getItem('token');

console.log(token)

// 

const columns = [
  { field: 'RowNumber', headerName: 'RowNumber', width: '150px' },
  { field: 'SNO', headerName: 'SNO', width: '150px' },
  { field: 'Trans_ID', headerName: 'Trans_ID', width: '150px' },
  { field: 'TDate', headerName: 'TDate', width: '200px' },
  { field: 'Receipt_No', headerName: 'Receipt No', width: '150px' },
  { field: 'VrNo', headerName: 'Vr No', width: '200px' },
  { field: 'Transaction_Type', headerName: 'Transaction_Type', width: '200px' },
  { field: 'Paid_To', headerName: 'Cash/Cheque', width: '150px' },
  { field: 'Total_Amount', headerName: 'Total_Amount', width: '200px' },
  { field: 'Cash_Cheque_Amount', headerName: 'Cash_Cheque_Amount', width: '200px' },
  { field: 'Cheque_No', headerName: 'Cheque_No', width: '150px' },
  { field: 'Finalized', headerName: 'Finalized', width: '150px' },
  { field: 'Member_ID', headerName: 'Member_ID', width: '150px' },
  { field: 'Staff_No', headerName: 'Staff_No', width: '150px' },
  { field: 'Remarks', headerName: 'Remarks', width: '200px' },
  { field: 'Exp_Code', headerName: 'Exp_Code', width: '200px' },
  { field: 'Cash_Cheque', headerName: 'Cash_Cheque', width: '200px' },
];

const validationSchema = Yup.object({
  id:Yup.number().required('Required'),
  fromDate: Yup.date().required('Required'),
  toDate: Yup.date().required('Required')
});

const initialValues = {
  id:'',
  fromDate: '',
  toDate: '',
};


const Journal = () => {
  const [journal, setJournal] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  // const [pageIndex, setPageIndex] = useState(1);
  const [pageSize] = useState(10); 
  const navigate = useNavigate();


  const fetchJournal = async(values, pageIndex) => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/Admin/Journal/Journal`,{
       "Action": "JournalVoucher",
        id:values.id,
        fromDate:values.fromDate,
        toDate:values.toDate,
        pageIndex: pageIndex  || currentPage,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if(response.data && response.data.List){
        console.log('API response', response.data)
        setJournal(response.data.List);
        setTotalRecords(response.data.Footer[0].TotalRecord || 0);
        setSnackbarMessage('Data fetched Successfully');
        
      }else{
        throw new Error("Unexpected response structure");
        
      }
    } catch (error) {
      setError(`Failed to fetch users ${error} `);
      setSnackbarMessage(error.message)
      setLoading(false);
    }finally{
      setLoading(false);
      setSnackbarOpen(true)
    }
  };

 
  // useEffect(() => {
  //   fetchJournal(currentPage+1);
  // }, [currentPage]);

  const handleSubmit = (values) =>{
    fetchJournal(values,currentPage);
  }


  // const handleSubmit = async (values) => {
  //   setLoading(true);
  //   setError('');
  //   try {
  //     const response = await axios.post(`${API_URL}/Admin/Journal/Journal`, {
  //       "Action": "JournalVoucher",
  //       id:values.id,
  //       fromDate:values.fromDate,
  //       toDate:values.toDate,
  //       pageIndex:currentPage,
  //       pageSize,
  //     },
  //     {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     console.log(response.data)
  //     if(response.data?.List)
  //       {
  //         setJournal(response.List);
  //         setTotalRecords(response.data.Footer[0]?.TotalRecord || 0 );
  //         setSnackbarMessage('Data fetched successfully');
  //         setSnackbarOpen(true);
  //       }else{
  //         throw new Error('Unexpected response structure');
  //       }  
  //   } catch (error) {
  //     console.error('Error fetching data:', error);  // Log for debugging
  //      setError('Failed to fetch data: ' + error.message);
  //      setSnackbarMessage('Error: ' + error.message);  // More detailed error
  //      setSnackbarOpen(true);
  //   } finally {
  //     setLoading(false);
  //     setSnackbarOpen(true)
  //   }
  // };


  return (
    <LayoutContainer>
      <Grid item xs={12} container spacing={2} sm={9} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched, values, setFieldValue }) => (
            <Form>
              <Grid sx={{ marginTop: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Paper elevation={3} style={{ padding: '16px' }}>
                  <Grid item sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '1rem' }}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        as={TextField}
                        id="Id"
                        type="number"
                        value={values.id}
                        label="Id"
                        variant="outlined"
                        onChange={(e) => setFieldValue('id', e.target.value)}
                        error={touched.id && !!errors.id}
                        helperText={touched.id && errors.id}
                        required
                        margin="normal"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        as={TextField}
                        name="fromDate"
                        label="From Date"
                        type="date"
                        value={values.fromDate}
                        onChange={(e) => setFieldValue('fromDate', e.target.value)}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                         size="small"
                        error={touched.fromDate && !!errors.fromDate}
                        helperText={touched.fromDate && errors.fromDate}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        as={TextField}
                        name="toDate"
                        label="To Date"
                        type="date"
                        value={values.toDate}
                        onChange={(e) => setFieldValue('toDate', e.target.value)}
                        margin="normal"
                         size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={touched.toDate && !!errors.toDate}
                        helperText={touched.toDate && errors.toDate}
                      />
                    </Grid>
                  </Grid>
                  <Grid item sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '1rem' }}>
                    <Grid tem xs={12} sm={6}>
                      <Button
                        type='submit'
                        variant='contained'
                        color="primary"
                        // disabled={isSubmitting}
                        margin="normal"
                      >
                        Journal
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Form>
          )}
        </Formik>
        <Grid sx={{ marginTop: '1rem', maxWidth: '140%' }}>
        <Box style={{ marginTop: '24px' }}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <CircularProgress />
          </Box>
        ) : (
          journal.length > 0 ? (
            <Paper elevation={3} style={{ padding: '16px' }}>
              <ReusableTable data={journal} columns={columns} />
              <PageingStyle>
                <Button
                  variant="contained"
                  onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                >
                  Previous
                </Button>
                <Typography variant="h6" style={{ margin: '0 16px' }}>
                  Page {currentPage} of {Math.ceil(totalRecords / pageSize)}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => setCurrentPage((prev) => prev + 1)}
                  disabled={currentPage === Math.ceil(totalRecords / pageSize)}
                >
                  Next
                </Button>
              </PageingStyle>
            </Paper>
          ) : (
            <Typography variant="h6" color="textSecondary">
              No records found.
            </Typography>
          )
        )}
      </Box>

          {/* <Paper elevation={3} style={{ padding: '16px' }}>
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                <CircularProgress />
              </Box>
            ) : error ? (
              <Box color="error.main">{error}</Box>
            ) : (
              <ReusableTable data={journal} columns={columns} />
            )}
            <PageingStyle  >
              <Button
                variant="contained"
                onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
            <Typography variant="h6" style={{ margin: '0 16px' }}>
              Page {currentPage} of {Math.ceil(totalRecords / pageSize)}
            </Typography>
            <Button
              variant="contained"
              onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
              disabled={currentPage === Math.ceil(totalRecords / pageSize)}
            >
              Next
            </Button>
            </PageingStyle>
          </Paper> */}
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />

    </LayoutContainer>
   
  )
}

export default Journal;
