import React, {useState, useEffect} from 'react';
import {
  TextField, Button, Typography, Container, Box, IconButton, CircularProgress, Checkbox
, Paper, Dialog, DialogActions, Grid,DialogContent, DialogContentText, DialogTitle, Snackbar, FormControlLabel
} from '@mui/material';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AccountForm from './AccountForm';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ReusableTable from '../../reuseable/ReusableTable';
import styled from '@emotion/styled';



const LayoutContainer = styled(Container)`  
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content:start;
  max-width: 600px;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  adding: 16px;
  top: 12%;
  left:18%;
  marginTop: theme.spacing(2);
  marginBottom: theme.spacing(2);
  
`;

const CenterStyle = styled(Box)`
    display: flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    width:100%;
    // margin: 8px;
    // padding:8px; 

`;
const Left = styled(Box)`
    display: flex;
    flex-direction:row;
    align-items:center;
    justify-content:start;
    // margin:8px;
`;

const Right = styled(Box)`
  display: flex; 
  justify-content:flex-end;
  align-items:center;
  margin-bottom:0px; 
`;

const LoadingStyle = styled(Box)`
    display:flex ;
    justify-content:center; 
    align-items:center;
     height: 100vh;
`;

const TableStyle = styled(Box)`
  display:flex;
  // height: 100vh;
  flex-direction:column;
  justify-content:start; 
  margin-bottom:0px;
  padding:8px; 
  background-color:'black'
`


const PageingStyle = styled(Box)`
    display:flex;
    flex-direction:row;
    justify-content:end;  
    align-items:center;
    margin:16px 0;
`

const validationSchema = Yup.object().shape({
  SNO:Yup.number().required('SNO ID is required'),
  AccountID: Yup.number().required('Account ID is required'),
  AccountName: Yup.string().required('Account Name is required'),
  NickName: Yup.string().required('Nick Name is required'),
  Category1: Yup.string().required('Category one No is required'),
  Category2: Yup.string().required('Category Two No is required'),
  Opening: Yup.number().required('Opening No is required'),
  DrCr:Yup.string().required('DrCr Form No is required'),
  Vaild: Yup.string().required('First Name is required'),
});


const  columns = [
    { field: 'RowNumber', headerName: 'RowNumber', width: '150px' },
    { field: 'SNO', headerName: 'SNO', width: '150px' },
    { field: 'ID', headerName: 'Account ID', width: '150px' },
    { field: 'Name', headerName: 'Account Name', width: '250px' },
    // { field: 'NickName', headerName: 'Nick Name', width: '150px' },
    { field: 'Category', headerName: 'Category', width: '200px' },
    // { field: 'Category2', headerName: 'Category2', width: '150px' },
    { field: 'Opening', headerName: 'Opening', width: '150px' },
    // { field: 'DrCr', headerName: 'Debit Crdit', width: '150px' },
    // { field: 'Valid', headerName: 'Vaild', width: '150px' },

    // { field: 'Action', headerName: 'Action', width: '150px' },
]

const token = localStorage.getItem('token')
const API_BASE_URL ='https://tecslkoupe.com';

const CreateAccount  = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editingAccount, setEditingAccount] = useState(null);
  const [account, setAccount] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [accountToDelete, setAccountToDelete] = useState(null);
  const [SNO, setSNO] = useState('');
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  const [pageSize] = useState(10); 

  useEffect(() => { 

    fetchAccount(currentPage);

  }, [currentPage]);

  const handleOpen = (SNO = null) => {
    setSelectedAccount(SNO);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setSelectedAccount(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  const fetchAccount = async (page) => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/Admin/Account/AccountHeads`,{
        Action: 'GetAll',
        PageIndex: page,
        pageSize
      },{
        headers: {
          Authorization: `Bearer ${token}`,
         'Content-Type': 'application/json'
        }
      });
      console.log(response.data.List)
      setTotalRecords(response.data.Footer[0].TotalRecord);
      // setPageSize(response.data.Footer[0].pageSize)
      setAccount(response.data.List|| []);
    } catch (error) {
      setError(`Failed to fetch users  ${error.message}`);
    }
    setLoading(false);
  };
  
  const handleShowAll = () => {
    account(currentPage);
  };

  const handleEditClick = (SNO) => {
    setAccount(SNO);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditingAccount(null);
  };

  const handleDeleteDialogOpen = (SNO) => {
    setAccountToDelete(SNO);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setAccountToDelete(null);
  };

  const handleAddUpdateUser = async (values, { setSubmitting, resetForm }) => {
    try {
      if (selectedAccount) {
        await axios.post(`${API_BASE_URL}/Admin/Master/BankMaster`, 
            { ...values,"Action": "Update" , UserID: selectedAccount.UserID },{
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
        );
        setSnackbarMessage('Bank updated successfully');
      } else {
        await axios.post(`${API_BASE_URL}/Admin/Master/BankMaster`, {...values, "Action": "Insert"},{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        setSnackbarMessage('User added successfully');
      }
      fetchAccount(currentPage);
      setSnackbarOpen(true);
      setSubmitting(false);
      handleClose();
      resetForm();
    } catch (error) {
      setError(`Failed to add/update user `);
      setSubmitting(false);
      snackbarMessage(error.message)
    }
  };

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    try {
      if (editingAccount) {
        await axios.post(`${API_BASE_URL}/Admin/Account/AccountHeads`,{    
        ...values,
          Action: 'Update'
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        });
        setSnackbarMessage('member updated successfully');
      } else {
        await axios.post(`${API_BASE_URL}/Admin/Account/AccountHeads`,{
          ...values,
          Action: 'Insert'
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        });
        setSnackbarMessage('User added successfully');
      }
      fetchAccount(currentPage);
      setSnackbarOpen(true);
      setSubmitting(false);
      handleCloseModal();
      resetForm();
    } catch (error) {
      setError('Failed to add/update Account');
      setSubmitting(false);
    }
    };

    const handleDeleteUser = async (accountID) => {
      try {
        await axios.post(`${API_BASE_URL}/Admin/Account/AccountHeads`, 
          { accountID: accountID, "Action": "Delete" },
          {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            } 
      );
        setSnackbarMessage(`Bank  with ID ${accountToDelete}  deleted successfully`);
        fetchAccount(currentPage);
        setSnackbarOpen(true);
        handleDeleteDialogClose();
      } catch (error) {
        setError(`Failed to delete Bank: ${error}`);
        setSnackbarMessage(error.message)
        handleDeleteDialogClose();
      }
    };
  
  return (
    <LayoutContainer >
      <Grid component={Paper} elevation={3} spacing={2} style={{padding:'16px', marginBottom:'16px'}} >
          <AccountForm editingMember={editingAccount} />
      </Grid>   
      <Paper elevation={3} style={{padding:'16px'}}>
        {
          loading ? (
            <LoadingStyle >
              <CircularProgress />
            </LoadingStyle>
          ) :(
            <TableStyle>
              <Box>
                <ReusableTable  
                  data={account} 
                  columns={columns} 
                  onEdit={handleOpen} 
                  onDelete={handleDeleteDialogOpen}
                />
              </Box>
            <PageingStyle  >
              <Button
                variant="contained"
                onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
            <Typography variant="h6" style={{ margin: '0 16px' }}>
              Page {currentPage} of {Math.ceil(totalRecords / pageSize)}
            </Typography>
            <Button
              variant="contained"
              onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
              disabled={currentPage === Math.ceil(totalRecords / pageSize)}
            >
              Next
            </Button>
            </PageingStyle>
            </TableStyle>
          )
        }
      </Paper>
      <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{editingAccount ? 'Edit Member' : 'Add Member'}</DialogTitle>
        <DialogContent  
        >
          <DialogContentText>
            {editingAccount ? 'Update the user information below' : 'Fill in the information below to add a new user'}
          </DialogContentText>
          <Formik
            initialValues={editingAccount || {
                SNO:'',
                AccountID:'',
                AccountName:'',
                NickName:'',
                Category1:'',
                Category2:'',
                Opening:'',
                DrCr:'',
                Valid:'',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, values, handleChange, setFieldValue, handleBlur, errors, touched }) => 
              <AccountForm 
                  isSubmitting={isSubmitting} 
                  values={values} 
                  handleChange={handleChange}  
                  handleBlur={handleBlur} 
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue} /> 
            }
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseModal}
            color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the Bank with ID {SNO}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteUser} color="warning">
            Delete
          </Button>
        </DialogActions>
      </Dialog>


    {error && (
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={()=>setError(null)}
        message={error}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => setError(null)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    )}
    <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton size="small" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </LayoutContainer>
  )
}

export default CreateAccount
