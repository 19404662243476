import React,{useState, useEffect} from 'react';
import { Container,Grid, TextField, Checkbox,CircularProgress ,Typography, Paper, Button, FormControl, RadioGroup,Radio, FormControlLabel } from '@mui/material';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import styled from '@emotion/styled';
import PrintIcon from '@mui/icons-material/Print';
import SelectDropdown from '../../reuseable/CustomDropDown';

const LayoutContainer = styled(Container)`  
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 105vh;
  height: 100vh;
  padding:16px;
  top: 12%;
  left:18%;
`;

const validationSchema = Yup.object().shape({
      ThriftPercetage:Yup.string().required('thrift is required'),
      DividenPersentage:Yup.string().required('Dividen is required'),
      ShareOpening:Yup.number().required('Share opening is required'),
      ShareCollection:Yup.number(),
      SharePaidBefore:Yup.number(),
      SharePaidAfter:Yup.number(),
      Dividen:Yup.number(),
      TFOpening:Yup.number(),
      TFCollection:Yup.number(),
      TFRefunded:Yup.number(),
      TFInterest:Yup.number()
});

const initialValues = {
  FinancialYear: '',
  ThriftPercentage: '',
  DividendPercentage: '',
  ShareOpening: '',
  ShareCollection: '',
  SharePaidBefore: '',
  SharePaidAfter: '',
  TFOpening: '',
  TFCollection: '',
  TFRefunded: '',
  TFInterest: '',  
}

const token = localStorage.getItem('token')
const API_URL ='https://tecslkoupe.com';

const ThriftInterestDividendForm = () => {
  const [unit, setUnit] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState('');

  const [payMode, setPayMode] = useState([]);
  const [selectedPayMode, setSelectedPaymode] = useState('');

  const [financialYear, setFinancialYear] = useState([]);
  const [selectedFinancialYear, setSelectedFinancialYear] = useState('');

  const [purpose] = useState(['Receiving', 'Binding']);
  const [selectedPurpose, setSelectedPurpose] = useState('');

  const [getData, setGetData] = useState([]);
  const [selectData, setSelectData] = useState('');
  const [formValues, setFormValues] = useState(initialValues);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize] = useState(10); 

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      setSnackbarOpen(true)
      try {
        const [unitResponse, finacialYearResponse, dataResponse, payModeResponse] = await Promise.all([
          axios.post(`${API_URL}/Admin/Account/Divide`, { Action: 'GetUnits' }, { headers: { Authorization: `Bearer ${token}` } }),
          axios.post(`${API_URL}/Admin/Account/Divide`, { Action: 'GetFinancialYear'}, { headers: { Authorization: `Bearer ${token}` } }),
          axios.post(`${API_URL}/Admin/Account/Divide`, { Action: 'GetPayMode'}, { headers: { Authorization: `Bearer ${token}` } }),
        ]);
        setUnit(unitResponse.data.List || []);
        setFinancialYear(finacialYearResponse.data.List || []);
        setPayMode(payModeResponse.data.List || []);
        setTotalRecords(unitResponse.data.Footer[0]?.TotalRecord || 0);
        setSnackbarOpen('Successful to fetch Data ')
      } catch (error) {
        console.error('Failed to fetch initial data', error);
        setSnackbarOpen('Failed to fetch initial data')
      }
      setLoading(false);
    };
    fetchInitialData();
  }, []);

  const unithandler =(e)=>{
    const selectedUnit = e.target.value;
    setSelectedUnit(selectedUnit)
    setCurrentPage(1);
}

const financialYearhandler =(e)=>{
  const selectedYear = e.target.value;
  setSelectedFinancialYear(selectedYear)
  setCurrentPage(1);
}

const fetchData = async(setFieldValue) => {
  setLoading(true);
  setSnackbarOpen(true)
  try {
    const response = axios.post(`${API_URL}/Admin/Account/Divide`, 
      { Action: 'GetData' }, 
      { headers: { Authorization: `Bearer ${token}` } 
    });
    setGetData(response.data || []);
    const data = response.data
      // setFormValues((prevValues) =>({
      //   ...prevValues,
      //   ShareOpening: data.ShareOpening || '',
      //   ShareCollection: data.ShareCollection || '',
      //   SharePaidBefore: data.SharePaidBefore30Sept || '',
      //   SharePaidAfter: data.SharePaidAfter30Sept || '',
      //   Dividend: data.Dividend || '',
      //   TFOpening: data.TFOpening || '',
      //   TFInterest: data.TFInterest || '',
      //   TFRefunded: data.TFRefundded || '',
      // }))
      setFieldValue('ShareOpening', data.ShareOpening);
      setFieldValue('ShareCollection', data.ShareCollection);
      setFieldValue('SharePaidBefore30Sept', data.SharePaidBefore);
      setFieldValue('SharePaidAfter30Sept', data.SharePaidAfter);
      setFieldValue('Dividend', data.Dividend);
      setFieldValue('TFOpening', data.TFOpening);
      setFieldValue('TFInterest', data.TFInterest);
      setFieldValue('TFRefundded', data.TFRefundded);
    setSnackbarMessage('Data fetched successfully') 
  } catch (error) {
    setError(`Failed to fetch users: ${error}`);
    setSnackbarMessage(`Failed to fetch users: ${error}`)
  }
  finally {
    setSnackbarMessage(true);
    setLoading(false);
  }
};

const purposehandler =(e)=>{
  const selectedPurpose = e.target.value;
  setSelectedPurpose(selectedPurpose);
  setCurrentPage(1);   
}

// const getHandleData =()=>{
//     setSnackbarOpen(true)
//     fetchData(setFieldValue)
// }

const payModeHandler =(e)=>{
  const selectedPayMode = e.target.value;
  setSelectedUnit(selectedPayMode)
 
}


  return (
    <LayoutContainer   >
        <Grid  container spacing={2} component={Paper} elevation={5} style={{padding:'8px 0px',marginBottom:'16px'}}>
                <Grid item xs={12} md={4}>
                    <SelectDropdown 
                            label="Select Financial Year"
                            name="SelectFinacialYear"
                            value={selectedFinancialYear}
                            options={financialYear.map(f => f.FinancialYear)}
                            onChange={financialYearhandler}          
                    />
                 </Grid>                 
          </Grid>
          <Grid  container spacing={2} component={Paper} elevation={5} style={{padding:'0px 0px',marginTop:'16px' }}>
           </Grid>
               <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(value) => console.log(value)}       
               >
               {({values, handleChange ,touched, errors ,setFieldValue}) => (
           <Form>   
                 <Grid item xs={12} md={6}>
                      <Button 
                        variant="contained" 
                        color="primary"  
                        onClick={()=>fetchData(setFieldValue)}
                      >
                      {loading ? <CircularProgress size={24} /> : 'Get Data'}
                      </Button>
                 </Grid>
              <Grid container spacing={2} component={Paper} elevation={5} style={{padding:'8px 0px',marginTop:'16px' }}>
                  {[ { name: "ShareOpening", label: "Share Opening" },
                     { name: "ShareCollection", label: "Share Collection" },
                     { name: "SharePaidBefore", label: "Share Paid befor 30 Sep" },
                     { name: "SharePaidAfter", label: "Share Paid befor 30 Sep" },
                     { name: "Dividen", label: "Dividen" },
                ].map(({ name, label, type = "text", component }) => (
                  <Grid item key={name} xs={3}>
                      {component ? component : (
                          <Field
                              as={TextField}
                              name={name}
                              label={label}
                              type={type}
                              value={values[name]}
                              margin="normal"
                              onChange={handleChange}
                              size="small"
                              InputLabelProps={type === "date" ? { shrink: true } : {}}
                              error={touched[name] && Boolean(errors[name])}
                              helperText={touched[name] && errors[name]}
                          />  )}     
                   </Grid>)) }
             </Grid> 
              <Grid container spacing={2} component={Paper} elevation={5} style={{padding:'0px 0px',marginTop:'16px ' }}>
               {[ { name: "TFOpening", label: "TF Opening" },
                  { name: "TFCollection", label: "TF Collection" },
                  { name: "TFRefunded", label: "TF Refunded" },
                 { name: "TFInterset", label: "TF Interset" },    
              ].map(({ name, label, type = "text", component }) => (
                 <Grid item key={name} xs={3}>
                      {component ? component : (
                        <Field
                            as={TextField}
                            name={name}
                            label={label}
                            type={type}
                            value={values[name]}
                            margin="normal"
                            size="small"
                            InputLabelProps={type === "date" ? { shrink: true } : {}}
                            error={touched[name] && Boolean(errors[name])}
                            helperText={touched[name] && errors[name]}
                        />
                    )}  
                 </Grid>))
             }
           </Grid> 
         </Form>
          )}
      </Formik>
    <Grid  container spacing={2} component={Paper} elevation={5} style={{padding:'0px 0px',marginTop:'16px' }}>
        <Grid item xs={4}>
            <FormControl>
              <RadioGroup 
                name="memberType" 
                value="memberType" 
                // onChange={handleChange} 
                >
                <FormControlLabel value="memberID" control={<Radio />} label="Member ID" />
                <FormControlLabel value="location" control={<Radio />} label="Location" />
                <FormControlLabel value="unit" control={<Radio />} label="Unit" />
              </RadioGroup>
            </FormControl>
         </Grid>
         <Grid item xs={12} md={3}>             
         </Grid>
         <Grid item xs={12} md={3}>
                    <SelectDropdown 
                            label="Select Unit"
                            name="SelectUnit"
                            value={selectedUnit}
                            options={unit.map(u => u.Unit)}
                            onChange={unithandler}          
                    />
          </Grid>
          <Grid item xs={12} md={4}>
                            <SelectDropdown 
                                    label={`Select Pay Mode`}
                                    name={`SelectPayMode`}
                                    value={selectedPayMode}
                                    options={payMode.map(p =>p.PayMode)}
                                    onChange={payModeHandler} />
          </Grid>
          <Grid item xs={12} md={4}>
                            <SelectDropdown 
                                    label={`Select Purpose`}
                                    name={`SelectPoupose`}
                                    value={selectedPurpose}
                                    options={payMode.map(p =>p.PayMode)}
                                    onChange={purposehandler} />
          </Grid>

          <Grid item xs={4}>
            <FormControlLabel
                control={<Checkbox />}
                label="Resigned Members"
                name="resignedMembers"
                // value={values.resignedMembers}
                // onChange={handleChange}
            />
         </Grid>
          <Grid item xs={4}>
                      <Button 
                                type="submit" 
                                variant="contained" 
                                color='secondary'
                                sx={{marginRight:'8px'}}
                                // onClick={handleGeneratedPDF}
                                >
                                 Print
                                <PrintIcon margin='normal' />
                            </Button>
      </Grid>
    </Grid>
    </LayoutContainer>
  );
};

export default ThriftInterestDividendForm;
