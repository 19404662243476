import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const SelectDropdown = ({ label, value, options, onChange, labelId }) => {
  return (
    <FormControl fullWidth>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          labelId={labelId}
          value={value}
          label={label}
          onChange={onChange}
          size='small'
        >
          {options.map((option, index) => (
            <MenuItem value={option} key={index}>{option}</MenuItem>
          ))}
        </Select>
      </FormControl>
  );
};

export default SelectDropdown;
