import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


const ReusableTable = ({ data, columns, onEdit,onDelete }) => {
  return(
    <TableContainer >
      <Table>
        <TableHead>
          <TableRow 
           sx={{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#1565c0',
            color: 'white',
            minWidth: '200px',
            justifyContent: 'start',
          }}
            >
            {columns.map((column) => (
              <TableCell 
              key={column.field}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                color: 'white',
                minWidth: `${column.width}`,
                justifyContent: 'start',
              }} >
                  {column.headerName}
              </TableCell>              
            ))}
             {(onEdit || onDelete) && <TableCell   sx={{
                display: 'flex',
                flexDirection: 'column',
                color: 'white',
                justifyContent: 'start',
              }}>Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow 
            key={row.RowNumber}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              minWidth: '200px',
              justifyContent: 'start',
              // overflowWrap: 'break-word'
            }}   
            >
              {columns.map((column) => (
                <TableCell 
                key={column.field}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  minWidth: `${column.width}`,
                  justifyContent: 'start',
                  alignItems: 'start',
                  // overflow:'auto',
                  // overflowWrap: 'break-word'
                }}>
                  {/* Handle null, boolean, and normal values */}
                  {row[column.field] === null
                    ? 'N/A' // Show 'N/A' for null values
                    : typeof row[column.field] === 'boolean'
                    ? row[column.field] ? 'True' : 'False' // Show 'True' or 'False' for boolean
                    : row[column.field]} {/* Show the value directly otherwise */}
                </TableCell>
              ))}
              {(onEdit || onDelete) && (
                <TableCell  sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                  {onEdit && (
                    <IconButton onClick={() => onEdit(row)} color="primary" ariant="contained"  size="small"
                   >
                      <EditIcon />
                    </IconButton>
                  )}
                  {onDelete && (
                    <IconButton onClick={() => onDelete(row)} color="warning"  ariant="contained"  size="small"
                    // sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center' }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}                
              </TableCell>
               )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    )
}
  
 

export default ReusableTable;
  


