import React from 'react';
import { Box, Grid, Paper, TextField, Select, MenuItem, FormControlLabel, Checkbox, FormControl, InputLabel, Typography } from '@mui/material';
import { Formik, Field , Form } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import DebitCreditTable from '../../../reuseable/DebitCreditTable';



const DropDownContainer = styled(Grid)`
  display: flex;
  flex-direction:row;
  align-items:center;
 `
 const FormContainer = styled(Paper)`
  padding: 16px;
  border-radius: 10px;
  margin-bottom: 16px;
`;
 const TableName = styled(Typography)`
  margin: 1px;
  padding: 1px;
  display: flex;
  justify-content: start;
`;

const columns = [
    { id: 'sno', label: 'SNO' ,name: 'SNO' },
    { id: 'id', label: 'ID', name: 'ID' },
    { id: 'account', label: 'Account', name: 'Account' },
    { id: 'amount', label: 'Amount',  name: 'Amount' },
    { id: 'id', label: 'ID', name:'ID' },
  ];
  

const DeductionSection = ({values, handleChange, touched, errors}) => {
  return (
    <Form>
     <FormContainer elevation={3}>
        
        <DropDownContainer container spacing={2} direction="row" >  
            <Grid item xs={12} md={4}>
            <FormControl fullWidth>
                        <InputLabel field="select-year">Credit</InputLabel>
                        <Select
                            labelId=" Credit"
                            name=" Credit"
                            label="Credit"
                            value={values.Credit}
                            onChange={handleChange}
                        >
                            <MenuItem value={values.Debit}>{values.Debit}</MenuItem>
                        </Select>
            </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <Field
                        as={TextField}
                        name="AccountName"
                        value={values.AccountName}
                        label="Account Name"
                        variant="outlined"
                        onChange={handleChange}
                        required
                        fullWidth
                        // margin="normal"  
                        error={touched.AccountName && Boolean(errors.AccountName)}
                        helperText={touched.AccountName && errors.AccountName}
                            
                    />
            </Grid>
            <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                        <InputLabel field="select-year">Id/Balance</InputLabel>
                        <Select
                            labelId="select-year"
                            name=" MemberId"
                            label=" MemberId"
                            value={values.MemberId}
                            onChange={handleChange}
                        >
                            <MenuItem value={values.MemberId}>{values.MemberId}</MenuItem>
                        </Select>
                    </FormControl>
            </Grid>
        </DropDownContainer>
        <DropDownContainer container spacing={2} direction="row" >
                <Grid item xs={12} md={4}>
                    <FormControlLabel
                        control={<Checkbox name="chequeReversal" onChange={handleChange} />}
                        label="Cheque Reversal"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Field
                                as={TextField}
                                id=""
                                type="text"
                                value={values.AccountName}
                                label="Account Name"
                                variant="outlined"
                                onChange={handleChange}
                                required
                                margin="normal"  
                                error={touched.AccountName && Boolean(errors.AccountName)}
                                helperText={touched.AccountName && errors.AccountName}
                                fullWidth        
                            />
                        </Grid>
                        <Grid item   xs={12} md={4}>
                            {/* <TableName component="h4">Credit Heads</TableName>
                            <DebitCreditTable name="creditHeads" title="Credit Heads" columns={columns} /> */}
                        </Grid>
        </DropDownContainer>
        </FormContainer>   
    

    </Form>
  )
  
}

export default DeductionSection
