import React from 'react';
import {
    TextField, Paper, Grid,Typography
  } from '@mui/material';
  
import { Formik, Form, Field, ErrorMessage } from 'formik';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import ReusableTable from '../../../reuseable/ReusableTable';
import { useState } from 'react';


const columns = [
    { field: 'SNO', headerName: 'SNO', width: '130px' },
    { field: 'Month', headerName: 'Month', width: '130px' },
    { field: 'Year', headerName: 'Year', width: '150px' },
    { field: 'Principal', headerName: 'Principal', width: '150px' },
    { field: 'Interest', headerName: 'Interest', width: '150px' },
    { field: 'Thrift', headerName: 'Thrift', width: '150px' },
    { field: 'Surety', headerName: 'Surety', width: '150px' },
    { field: 'Total', headerName: 'Total', width: '150px' },
    { field: 'ListNo', headerName: 'List No', width:'150px'},
    { field: 'ListName', headerName: 'ListName', width:'150px' },
]


const RecoveryList = ({ values, handleChange, errors, touched }) => {
    const [data, setData] = useState([])
    return(      
    <>
      <Typography variant='h6' >Recovery List</Typography>
      {/* <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Field
            as={TextField}
            name="difOutstanding"
            label="DIF Outstanding"
            variant="outlined"
            fullWidth
             size="small"
             sx={{margin:'8px'}}
            error={touched.difOutstanding && Boolean(errors.difOutstanding)}
            helperText={touched.difOutstanding && errors.difOutstanding}
          />
        </Grid>
      </Grid> */}
      <ReusableTable
            columns={columns}
            data ={data}
      />
    </>
    )
};

export default RecoveryList;