import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Category } from '@mui/icons-material';
import { TextField,Grid, Button} from '@mui/material';

const validationSchema = Yup.object().shape({
    AccountName: Yup.string().required('Account Name is required'),
    Category: Yup.string().required('Category is required'),
    Opening: Yup.number().required('Opening is required').min(0, 'Opening must be greater than or equal to 0'),
  });


  const initialValues = {
    AccountName:'',
    Category:'',
    Opening:''
  }


const AccountForm = () => {
    const [editingMember, setEditingMember] = useState(null);


  return (
    <Formik
        initialValues={editingMember || {initialValues}}
        validationSchema={validationSchema}
        onSubmit={console.log()}
    >{
        ({ isSubmitting, values, handleChange, setFieldValue, touched, errors, helperText})=>
        <Form>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                <Field
                    as={TextField}
                    id="AccountName"
                    type="text"
                    value={values.AccountName}
                    label="Account Name"
                    variant="outlined"
                    onChange={handleChange}
                    required
                    margin="normal"  
                    error={touched.AccountName && Boolean(errors.AccountName)}
                    helperText={touched.AccountName && errors.AccountName}
                    sx={{ mb: 2 }}         
                />
                </Grid>
                <Grid item xs={4}>
                <Field
                    as={TextField}
                    id="Category"
                    type="text"
                    value={values.Category}
                    label="Category"
                    variant="outlined"
                    onChange={handleChange}
                    required
                    margin="normal"  
                    error={touched.Category && Boolean(errors.Category)}
                    helperText={touched.Category && errors.Category}
                    sx={{ mb: 2 }}         
                />
                </Grid>
                <Grid item xs={4}>
                <Field
                    as={TextField}
                    id="Opening"
                    type="text"
                    value={values.Category}
                    label="Opening"
                    variant="outlined"
                    onChange={handleChange}
                    required
                    margin="normal"  
                    error={touched.Opening && Boolean(errors.Opening)}
                    helperText={touched.Opening && errors.Opening}
                    sx={{ mb: 2 }}         
                />
                </Grid>
            </Grid>
            <Grid  item xs={6}>
                <Button 
                     variant="contained" color="primary"  ml={2}   sx={{margin:'8px'}}
                >
                    Add Bank
                </Button>
            </Grid>
        </Form>
    }
    </Formik>
  )
}

export default AccountForm
