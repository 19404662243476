import { Card, CardContent, TextField, Typography,Grid } from '@mui/material'
import { Field } from 'formik'
import React from 'react'

const NormalLoanSection = ({values, touched, errors}) => {
  return (
    <Grid container spacing={2}>
    
      <Grid > 
      {[
                        { name: "normalLoanBalance", label: "Balance" },
                        { name: "normalLoanMonthlyRecovery", label: "Monthly Recovery" },
                        { name: "normalLoanDate", label: "Normal Loan Date", type: "date" },
                        { name: "normalAmountSanctioned", label: "Amount Sanctioned" },
                       ].map(({ name, label, type = "text", component }) => (
                          <Grid item key={name} xs={12}>
                              {component ? component : (
                                  <Field
                                      as={TextField}
                                      name={name}
                                      label={label}
                                      type={type}
                                      value={values[name]}
                                      size="small"
                                      fullWidth
                                      margin="normal"
                                      error={touched.name && Boolean(errors.name)}
                                      helperText={touched.name && errors.name}
                                      InputLabelProps={type === "date" ? { shrink: true } : {}}
                                  />
          )}
           </Grid>
       ))}

      </Grid>            
</Grid>
  )
}

export default NormalLoanSection;
