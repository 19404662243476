import React from 'react'
import { PageingStyle } from '../styles/globalStyle'
import {Button,Typography } from '@mui/material'

const Pagenation = ({currentPage, setCurrentPage, totalRecords, pageSize}) => {
  return (
    <PageingStyle  >
    <Button
        variant="contained"
        onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
        disabled={currentPage === 1}
    >
        Previous
    </Button>
    <Typography variant="h6" style={{ margin: '0 16px' }}>
    Page {currentPage} of {Math.ceil(totalRecords / pageSize)}
    </Typography>
    <Button
    variant="contained"
    onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
    disabled={currentPage === Math.ceil(totalRecords / pageSize)}
    >
    Next
    </Button>
     </PageingStyle>
  )
}

export default Pagenation
