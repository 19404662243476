import React from 'react'
import { Box, RadioGroup, FormControlLabel, Radio, TextField, Button, Paper, Container, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import MemberHeader from './MemberHeader';
import MemberSection from './MemberSection';
import DeductionSection from './DeductionSection';

const LayoutContainer = styled(Container)`  
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content:start;
  max-width: 600px;
  width: 100%;
  top: 12%;
  left:18%;
  margin-top: 16px;
  padding: 16px;
`;


const validationSchema = Yup.object().shape({
    VoucherDate: Yup.string().required('Voucher Date is required'),
    Id: Yup.number().required('Id is required'),
    Name: Yup.string().required('Name is required'),
    Debit:Yup.number(),
    MemberId:Yup.number(),
    MemberAmount:Yup.number(),
    MemberChequeReversal:Yup.boolean(),
    TotalDr:Yup.number(),
    Credit:Yup.number(),
    DeductionsId:Yup.number(),
    DeductionsAmount:Yup.number(),
    TotalCr:Yup.number(),
    DeductionsChequeReversal:Yup.boolean(),
  });




const MemberTransferEntries = () => {
    const handleSubmit = (values) => {
        console.log(values);
      };
    

  return (
    <LayoutContainer maxWidth="lg" >
         <Formik
                initialValues={{
                VoucherDate: '',
                Id: '',
                Name: '',
                Debit:'',
                MemberId:'',
                    MemberAmount:'',
                    MemberChequeReversal:'',
                    TotalDr:'',
                    Credit:'',
                    DeductionsId:'',
                    DeductionsAmount:'',
                    TotalCr:'',
                    DeductionsChequeReversal:'',
                    Remarks: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
        >
        {({ values, handleChange, touched,errors }) => (
            <Grid container direction="column" gap={0.5}>
               <Grid item xs={12} >
                    <MemberHeader
                          values={values}
                          handleChange={handleChange}
                          touched={touched}
                          errors={errors}
                     />
               </Grid>    
               <Grid item xs={12} >
                  <MemberSection
                    values={values}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    />
               </Grid>
               <Grid item xs={12}>
                  <DeductionSection
                      values={values}
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                   />
               </Grid>
            </Grid>
    )}
      </Formik>
    </LayoutContainer>
  )
}

export default MemberTransferEntries
