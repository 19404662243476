import React from 'react';
import { TextField } from '@mui/material';
import { Field } from 'formik';

const CustomTextField = ({ label, ...props }) => (
  <Field
      as={TextField}
      label={label}
      fullWidth
      margin="normal"
      size="small"
      {...props}
  />
);


export default CustomTextField;